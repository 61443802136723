import React from 'react';
import { useDidUpdateEffect } from '@odin-labs/components';
import { Form, FormOnSubmit } from 'components/form';
import { SelfOnboardingStepKey, useUpsertJobsiteWorkerDocumentMutation } from 'apollo/generated/client-operations';
import { getGraphQLError } from 'utils/error';
import { DocumentKey } from 'containers/worker/utils';
import { AlertInstance } from 'components/alertNotification';
import { useGetIsSelfOnboardingClosed } from 'containers/selfOnboarding/helpers/useGetIsSelfOnboardingClosed';
import {
  SelfOnboardingStepProps,
  SelfOnboardingDocumentFormData,
  AcknowledgmentStatus,
} from 'containers/selfOnboarding/steps/types';
import { useSelfOnboardingFormCommonProps } from 'containers/selfOnboarding/helpers/forms';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { StepInfo, StepActions, StepInfoTitle } from 'containers/selfOnboarding/steps/components';
import {
  getFormInputsHook,
  getDefaultValues,
  getUpdateInput,
  resolveDocumentKey,
  getDocumentInputsConfig,
  isAcknowledgmentDocument,
  getValuesFromAdditionalFields,
  useDocument,
} from './DocumentStep.forms';

export function DocumentStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, updateState, user, navigation, stepConfig, jobsiteInvitation, localize, language } = props;
  const { jobsiteWorkerId } = state;
  const { currentStep, goToStep, goToNextStep, getStepNumberInfo, state: navigationState } = navigation;
  const { isReview, autoFocusField } = navigationState;
  const stepNumberInfo = getStepNumberInfo();

  const documentKey = resolveDocumentKey(currentStep);
  const { titleCopy, subtitleCopy } = getDocumentInputsConfig(documentKey);

  const [isSaving, setIsSaving] = React.useState(false);
  const [upsertJobsiteWorkerDocument] = useUpsertJobsiteWorkerDocumentMutation();
  const { isSelfOnboardingClosed } = useGetIsSelfOnboardingClosed();

  const { document, updateDocument } = useDocument({ documentKey, state, updateState });

  const isAcknowledgmentStatusConfirmed = !!document.acknowledgmentStatus?.includes(AcknowledgmentStatus.Confirmed);
  const isAcknowledgmentProgressCompleted = document.acknowledgmentProgress?.fraction === 1;
  const initialIsContinueActionEnabled =
    !isAcknowledgmentDocument(documentKey) ||
    (isAcknowledgmentProgressCompleted && isAcknowledgmentStatusConfirmed && !document.acknowledgmentDate);

  const [isContinueActionEnabled, setIsContinueActionEnabled] = React.useState(initialIsContinueActionEnabled);

  useDidUpdateEffect(() => {
    setIsContinueActionEnabled(initialIsContinueActionEnabled);
  }, [initialIsContinueActionEnabled, documentKey]);

  const inputs = getFormInputsHook({
    state,
    jobsiteInvitation,
    document,
    localize,
    updateDocument,
    setIsContinueActionEnabled,
  });
  const defaultValues = React.useMemo(() => getDefaultValues(document), [document]);

  const { fieldsConfig, localization } = useSelfOnboardingFormCommonProps(stepConfig, localize);

  const onSubmit: FormOnSubmit<SelfOnboardingDocumentFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    try {
      if (await isSelfOnboardingClosed()) {
        goToStep(SelfOnboardingStepKey.Closed);
        return;
      }

      const input = await getUpdateInput({
        user,
        document,
        jobsiteInvitation,
        jobsiteWorkerId,
        data,
        dirtyFields,
      });

      if (input) {
        const { data: upsertResult } = await upsertJobsiteWorkerDocument({ variables: { input } });
        const { files, additionalFieldValues } = upsertResult.upsertJobsiteWorkerDocument;
        // if new backFile has been uploaded, update the document with the server file info
        const backFileUpdate = dirtyFields.backFile && data.backFile ? { backFile: files[1] } : undefined;
        const frontFileUpdate = documentKey === DocumentKey.JobsiteSafetyDocument ? { frontFile: files[0] } : undefined;

        const valuesFromAdditionalFields = getValuesFromAdditionalFields(documentKey, additionalFieldValues);

        updateDocument({ ...document, ...data, ...frontFileUpdate, ...backFileUpdate, ...valuesFromAdditionalFields });
      }
      setIsSaving(false);
      goToNextStep();
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  const onSkipHandler = React.useCallback((): void => {
    if (document.frontFile) {
      updateDocument({ ...document });
    }
    goToNextStep();
  }, [document, updateDocument, goToNextStep]);

  const areStepActionsVisible = documentKey !== DocumentKey.WorkerConsentDocument || isReview;

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle title={localize(titleCopy)} subtitle={localize(subtitleCopy)} stepNumberInfo={stepNumberInfo} />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        autoFocus={(autoFocusField as keyof SelfOnboardingDocumentFormData) ?? true}
        renderBelow={
          areStepActionsVisible && (
            <StepActions
              localize={localize}
              isReview={isReview}
              continueActionWithSpinner={isSaving}
              continueActionEnabled={isContinueActionEnabled}
              onSkip={onSkipHandler}
            />
          )
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        fieldsConfig={fieldsConfig}
        localization={localization}
      />
    </div>
  );
}
