import React from 'react';
import { Form, FormOnSubmit } from 'components/form';
import { AppErrorCode, useStartPhoneNumberVerificationMutation } from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError, getGraphQLErrorByCode } from 'utils/error';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import {
  SelfOnboardingStepKey,
  SelfOnboardingStepProps,
  SelfOnboardingVerificationPhoneNumberFormData,
} from 'containers/selfOnboarding/steps/types';
import { StepInfo, StepActions, StepInfoTitle } from 'containers/selfOnboarding/steps/components';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { AlreadyExistingItem } from 'components/alreadyExistingItem';
import { useSelfOnboardingFormCommonProps } from 'containers/selfOnboarding/helpers/forms';
import { getFeaturesModule } from 'containers/selfOnboarding/helpers/utils';
import { getPhoneNumberAsE164 } from 'utils';
import { getDefaultValues, getFormInputs } from './VerifyMobileDeviceStartStep.forms';

export function VerifyMobileDeviceStartStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, updateState, navigation, stepConfig, localize, language, jobsiteInvitation } = props;
  const { worker, verificationPhoneNumber, basicInfo } = state;
  const { workerId } = worker ?? {};
  const phoneNumber = verificationPhoneNumber ?? basicInfo?.phoneNumber;
  const { goToNextStep, goToStep, getStepNumberInfo, state: navigationState } = navigation;
  const { isReview, autoFocusField } = navigationState;
  const stepNumberInfo = getStepNumberInfo();
  const { jobsite } = jobsiteInvitation?.jobsiteContractor ?? {};
  const isWorkerAppEnabled = !!getFeaturesModule(jobsite?.modules)?.workerAppEnabled;

  const [isSaving, setIsSaving] = React.useState(false);
  const [startPhoneNumberVerification] = useStartPhoneNumberVerificationMutation();

  const onSubmit: FormOnSubmit<SelfOnboardingVerificationPhoneNumberFormData> = async (
    data,
    event,
    dirtyFields,
    formApi,
  ): Promise<void> => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    try {
      const result = await startPhoneNumberVerification({
        variables: { input: { workerId, phoneNumber: getPhoneNumberAsE164(data.verificationPhoneNumber) } },
      });
      updateState({ ...data });

      setIsSaving(false);
      if (result.data.startPhoneNumberVerification.success) {
        goToStep(SelfOnboardingStepKey.ConfirmMobileDevice);
      }
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);

      const phoneAlreadyExistsError = getGraphQLErrorByCode(error, AppErrorCode.PhoneAlreadyExists);
      if (phoneAlreadyExistsError) {
        formApi.setError('verificationPhoneNumber', {
          message: <AlreadyExistingItem itemType="Phone" />,
          shouldFocus: true,
        });
        return;
      }

      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  const inputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getDefaultValues({ verificationPhoneNumber: phoneNumber }), [phoneNumber]);
  const { fieldsConfig, localization } = useSelfOnboardingFormCommonProps(stepConfig, localize);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={localize(Copy.self_onboarding_verify_phone_header)}
          subtitle={localize(
            isWorkerAppEnabled
              ? Copy.self_onboarding_verify_phone_instructions
              : Copy.self_onboarding_verify_phone_instructions_no_mobile_app,
          )}
          stepNumberInfo={stepNumberInfo}
        />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        autoFocus={(autoFocusField as keyof SelfOnboardingVerificationPhoneNumberFormData) ?? true}
        renderBelow={
          <StepActions
            localize={localize}
            isReview={isReview}
            continueActionWithSpinner={isSaving}
            onSkip={goToNextStep}
          />
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        fieldsConfig={fieldsConfig}
        localization={localization}
      />
    </div>
  );
}
