import {
  Column,
  BadgeCell,
  BadgeCellComponentProps,
  BadgeColor,
  AvatarCellComponentProps,
  AvatarCell,
  AvatarSkeleton,
} from '@odin-labs/components';

import { JobsiteWorkerDocument } from 'apollo/generated/client-operations';
import { getInitialsForUser } from 'utils';
import { getLatestWorkerDocument, getWorkerDocumentFieldValue } from 'containers/worker/utils';
import { DateTimeCell, JobsiteCell, TestTypeCell } from './cells';

export type TestResult = 'Pass' | 'No pass';
export const resultText: Record<TestResult, string> = {
  Pass: 'Pass',
  'No pass': 'Fail',
};
export const resultColor: Record<TestResult, BadgeColor> = {
  Pass: 'green',
  'No pass': 'red',
};

export type JobsiteWorkerDocumentColumn = Column<JobsiteWorkerDocument>;

export const getColumns = (): JobsiteWorkerDocumentColumn[] => [
  {
    Header: 'Jobsite',
    accessor: (document: JobsiteWorkerDocument): string => document.jobsiteWorkerDocumentType?.jobsite.name,
    Cell: JobsiteCell,
  },
  {
    Header: 'Test Type',
    accessor: (document: JobsiteWorkerDocument): JobsiteWorkerDocument => document,
    Cell: TestTypeCell,
  },
  {
    Header: 'Date & Time',
    accessor: (document: JobsiteWorkerDocument): JobsiteWorkerDocument => document,
    Cell: DateTimeCell,
  },
  {
    Header: 'Result',
    Cell: BadgeCell,
    componentProps: (document: JobsiteWorkerDocument): BadgeCellComponentProps => {
      const latestDocument = getLatestWorkerDocument(document);
      const result: TestResult = getWorkerDocumentFieldValue(latestDocument, 'result');
      return {
        text: resultText[result],
        color: resultColor[result],
        withDot: true,
        background: 'transparent',
        size: 'lg',
      };
    },
  },
  {
    Header: 'Uploaded by',
    accessor: (document: JobsiteWorkerDocument): string => {
      const latestDocument = getLatestWorkerDocument(document);
      const { createdBy, updatedBy } = latestDocument.objectHistory ?? {};
      const { firstName, lastName } = (updatedBy ?? createdBy)?.identity ?? {};
      return [firstName, lastName].join(' ');
    },
    Cell: AvatarCell,
    CellLoading: AvatarSkeleton,
    componentProps: (document: JobsiteWorkerDocument): AvatarCellComponentProps => {
      const latestDocument = getLatestWorkerDocument(document);
      const { createdBy, updatedBy } = latestDocument.objectHistory ?? {};
      return {
        // src: document.reporter?.profilePicture?.downloadUrl,
        placeholder: getInitialsForUser(updatedBy ?? createdBy),
        // detail: getUserRole(document),
      };
    },
  },
];
