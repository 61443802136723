import React from 'react';
import cn from 'classnames';
import { Table, TableContainer } from '@odin-labs/components';
import {
  JobsiteFormSubmissionWorker,
  JobsiteFormSubmissionWorkerContractor,
} from 'containers/jobsiteFormSubmission/print/types';
import { ConstructionIcon, WrenchIcon } from 'components/icons';
import { getColumns } from './FormSubmissionReportContractorWorkers.tables';

const classes = {
  container: cn('print:odin-break-inside-avoid'),
};

export type FormSubmissionReportContractorWorkersProps = {
  contractor?: JobsiteFormSubmissionWorkerContractor;
  workers: JobsiteFormSubmissionWorker[];
  loading: boolean;
};

export function FormSubmissionReportContractorWorkers(
  props: FormSubmissionReportContractorWorkersProps,
): React.ReactElement {
  const { workers, loading, contractor } = props;

  const columns = React.useMemo(() => getColumns(), []);

  return (
    <div>
      {contractor && (
        <div className="odin-flex odin-mb-3 odin-gap-x-6">
          <div>
            <WrenchIcon className="odin-text-odin-primary odin-mr-2" />
            <span className="odin-text-sm odin-font-medium odin-text-gray-900 odin-mt-3 odin-mb-1">
              {contractor.organization.name}
            </span>
          </div>
          <div>
            <ConstructionIcon className="odin-text-odin-primary odin-mr-2" />
            <span className="odin-text-sm odin-font-medium odin-text-gray-900 odin-mt-3 odin-mb-1">
              {workers.length}
            </span>
          </div>
        </div>
      )}
      <TableContainer className={classes.container}>
        <Table loading={loading} columns={columns} data={workers} remote disableGlobalFilter disableSortBy />
      </TableContainer>
    </div>
  );
}
