import { BadgeColor } from '@odin-labs/components';

import { JobsiteAccessEvent, JobsiteAccessEventStatus } from 'apollo/generated/client-operations';
import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import { momentFormatter } from 'utils';

export const statusText: Record<JobsiteAccessEventStatus, string> = {
  Admit: 'Admitted',
  Reject: 'Rejected',
  archived: 'Archived',
};
export const statusColor: Record<JobsiteAccessEventStatus, BadgeColor> = {
  Admit: 'green',
  Reject: 'red',
  archived: 'gray',
};

type Direction = 'inbound' | 'outbound';
const directionText: Record<Direction, string> = {
  inbound: 'Inbound',
  outbound: 'Outbound',
};
const directionIcon: Record<Direction, string> = {
  inbound: cn('fal fa-arrow-to-right odin-text-odin-primary'),
  outbound: cn('fal fa-arrow-to-left odin-text-gray-600'),
};

const methodIcon: Record<string, string> = {
  odin_mobile_checkin: cn('fal fa-mobile'),
  ccure_swipe_event: cn('fal fa-door-open'),
  qrcode: cn('fal fa-qrcode'),
  magic: cn('fal fa-magic'),
};

const classes = {
  defaultText: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal'),
  jobsite: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-medium'),
  date: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal'),
  time: cn('odin-text-gray-600 odin-text-sm odin-leading-5 odin-font-normal'),
  directionText: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal odin-ml-1.5'),
  directionIcon: (direction: Direction): string => cn(directionIcon[direction], 'odin-text-base odin-font-light'),
  methodText: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal odin-ml-1.5'),
  methodIcon: (type: string): string => cn(methodIcon[type], 'odin-text-odin-primary odin-text-base odin-font-light'),
};

export function JobsiteCell({ value }: { value: string }): React.ReactElement {
  return <div className={classes.jobsite}>{value}</div>;
}

export function DateTimeCell({
  value: { localizedDate, localizedTime },
}: {
  value: { localizedDate?: string; localizedTime?: string };
}): React.ReactElement {
  return (
    <>
      <div className={classes.date}>{moment.utc(localizedDate).format(momentFormatter)}</div>
      <div className={classes.time}>{localizedTime}</div>
    </>
  );
}

export function DirectionCell({ value }: { value: Direction }): React.ReactElement {
  return (
    <div>
      <i className={classes.directionIcon(value)} />
      <span className={classes.directionText}>{directionText[value]}</span>
    </div>
  );
}

export function MethodCell({ value }: { value: JobsiteAccessEvent }): React.ReactElement {
  return (
    <div>
      <i className={classes.methodIcon(value.eventType)} />
      <span className={classes.directionText}>{value.gateway}</span>
    </div>
  );
}

export function JobsiteAccessDefaultCell({ value }: { value: string }): React.ReactElement {
  return <span className={classes.defaultText}>{value}</span>;
}
