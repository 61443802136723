import React from 'react';
import cn from 'classnames';
import { useQuery } from '@apollo/client';
import {
  JobsiteWorkerDocument,
  QueryGetWorkerDocumentsArgs,
  WorkerDocumentLevel,
} from 'apollo/generated/client-operations';
import { GET_WORKER_DOCUMENTS } from 'containers/worker/helpers/queries';
import { GetWorkerDocumentsResponse, Worker } from 'containers/worker/types';
import { LoadingError } from 'components/loadingError';
import { useUpdateQueryStatus } from 'containers/jobsiteSafetyIncident/print/helpers/useUpdateQueryStatus';
import { ReportSectionTitle } from 'containers/jobsiteSafetyIncident/print/components/reportSectionTitle';
import { WorkerReportDocument } from './WorkerReportDocument';

const classes = {
  container: '',
  documentsHeader: cn('odin-mt-9'),
  documentsText: cn('odin-text-lg odin-leading-6 odin-font-medium odin-text-gray-900'),
  documentsCount: cn('odin-ml-3'),
  documentsContainer: cn('odin-h-fit odin-border-separate odin-border-spacing-6 -odin-mx-6'),
};

export type WorkerReportDocumentsProps = {
  worker: Worker;
  developerIds: string[];
  jobsiteIds: string[];
  singleItemPerRow?: boolean;
};

export function WorkerReportDocuments(props: WorkerReportDocumentsProps): React.ReactElement {
  const { worker, developerIds, jobsiteIds, singleItemPerRow } = props;

  const { data, error, loading } = useQuery<GetWorkerDocumentsResponse, QueryGetWorkerDocumentsArgs>(
    GET_WORKER_DOCUMENTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        workerId: worker.workerId,
        includePhi: false,
        includeArchived: worker.isArchived,
      },
    },
  );

  useUpdateQueryStatus(`workerDocuments-${worker.workerId}`, { loading, error });

  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }

  const workerDocuments =
    data?.getWorkerDocuments?.workerDocuments?.filter((jobsiteDocument: JobsiteWorkerDocument) => {
      const { jobsiteWorkerDocumentType } = jobsiteDocument ?? {};
      const { workerDocumentType, jobsite } = jobsiteWorkerDocumentType ?? {};
      const { key: documentKey, documentLevel, isGlobal } = workerDocumentType ?? {};

      if (!documentKey?.includes('medical') && !documentKey.includes('post-employment')) {
        if (documentLevel === WorkerDocumentLevel.Organization) {
          return developerIds.includes(jobsite?.developer?.developerId);
        }
        return isGlobal || jobsiteIds.includes(jobsite?.jobsiteId);
      }
      return false;
    }) ?? [];

  const workerDocumentsByRows: Array<JobsiteWorkerDocument[]> = workerDocuments.reduce(
    (result, item, index): JobsiteWorkerDocument[] => {
      if (singleItemPerRow || index % 2 === 0) {
        result.push([item]);
      } else {
        result[result.length - 1].push(item);
      }
      return result;
    },
    [],
  );

  const documentRows = workerDocumentsByRows.map((rowDocs) => (
    <tr key={rowDocs[0].jobsiteWorkerDocumentId}>
      {rowDocs.map(
        (workerDocument): React.ReactElement => (
          <td key={workerDocument.jobsiteWorkerDocumentId}>
            <WorkerReportDocument
              key={workerDocument.jobsiteWorkerDocumentId}
              jobsiteDocument={workerDocument}
              singleItemPerRow={singleItemPerRow}
            />
          </td>
        ),
      )}
    </tr>
  ));

  return (
    <div className={classes.container}>
      <ReportSectionTitle title="Documents" count={workerDocuments?.length} />
      {/* use table here as fragmentation is not supported for grid */}
      <table className={classes.documentsContainer}>
        <tbody>{documentRows}</tbody>
      </table>
    </div>
  );
}
