import React from 'react';
import cn from 'classnames';
import { Table, TableContainer } from '@odin-labs/components';
import { FormSubmissionContractorWorkersCountData } from 'containers/jobsiteFormSubmission/types';
import { useContractorsOptions } from 'containers/worker/modals/addWorkerModal/AddWorkerModal.forms';
import { getColumns } from './FormSubmissionReportContractorWorkersCount.tables';

const classes = {
  container: cn('print:odin-break-inside-avoid'),
};

export type FormSubmissionReportContractorWorkersCountProps = {
  jobsiteId: string;
  contractorWorkersCount: FormSubmissionContractorWorkersCountData;
  loading: boolean;
};

export function FormSubmissionReportContractorWorkersCount(
  props: FormSubmissionReportContractorWorkersCountProps,
): React.ReactElement {
  const { contractorWorkersCount, loading, jobsiteId } = props;

  const contractorsOptions = useContractorsOptions(jobsiteId);

  const contractors = React.useMemo(() => {
    return (
      contractorsOptions
        .map(({ value: contractorId, label: contractorName }) => ({
          contractorId,
          contractorName,
          workersCount: contractorWorkersCount?.[contractorId],
        }))
        .filter((contractor) => contractor.workersCount) ?? []
    );
  }, [contractorsOptions]);

  const columns = React.useMemo(() => getColumns(), []);

  return (
    <div>
      <TableContainer className={classes.container}>
        <Table loading={loading} columns={columns} data={contractors} remote disableGlobalFilter disableSortBy />
      </TableContainer>
    </div>
  );
}
