import React from 'react';
import { PrintFormWorkersByContractorType } from 'containers/jobsiteFormSubmission/types';
import {
  JobsiteFormSubmission,
  JobsiteFormSubmissionWorker,
  JobsiteFormSubmissionWorkerContractor,
} from 'containers/jobsiteFormSubmission/print/types';
import { FormSubmissionReportContractorWorkers as ReportContractorWorkers } from './FormSubmissionReportContractorWorkers';
import { FormSubmissionReportContractorWorkersCount as ReportContractorWorkersCount } from './FormSubmissionReportContractorWorkersCount';

type ContractorWithWorkers = {
  contractor: JobsiteFormSubmissionWorkerContractor;
  workers: JobsiteFormSubmissionWorker[];
};

export type FormSubmissionReportWorkersProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  workersByContractorType?: PrintFormWorkersByContractorType;
  loading: boolean;
};

export function FormSubmissionReportWorkers(props: FormSubmissionReportWorkersProps): React.ReactElement {
  const { jobsiteFormSubmission, loading, workersByContractorType } = props;

  switch (workersByContractorType) {
    case 'detailed': {
      const workersByContractor = jobsiteFormSubmission?.jobsiteWorkers.edges.reduce((result, { node: worker }) => {
        const { contractor } = worker.jobsiteWorker.contractorWorker;
        const { contractorId } = contractor;
        if (!result[contractorId]) {
          Object.assign(result, { [contractorId]: { contractor, workers: [] } });
        }
        result[contractorId].workers.push(worker);
        return result;
      }, {} as Record<string, ContractorWithWorkers>);

      return (
        <>
          {Object.values(workersByContractor).map(({ contractor, workers }) => (
            <ReportContractorWorkers
              key={contractor.contractorId}
              contractor={contractor}
              workers={workers}
              loading={loading}
            />
          ))}
        </>
      );
    }

    case 'summary': {
      const { contractorWorkersCount } = jobsiteFormSubmission.extraData ?? {};
      const { jobsiteId } = jobsiteFormSubmission.jobsiteForm.jobsite ?? {};
      return (
        <ReportContractorWorkersCount
          jobsiteId={jobsiteId}
          contractorWorkersCount={contractorWorkersCount}
          loading={loading}
        />
      );
    }

    default: {
      const workers = jobsiteFormSubmission?.jobsiteWorkers.edges.map((w) => w.node) ?? [];
      return <ReportContractorWorkers workers={workers} loading={loading} />;
    }
  }
}
