import classNames from 'classnames/bind';
import { MedicalDocumentCardProps } from 'components/cards/types';
import { ObjectHistory } from 'components/objectHistory';
import { Status } from 'components/status';
import { Thumbnail } from 'components/thumbnail';
import React, { ReactElement } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { DocumentKey } from 'containers/worker/utils';

import styles from './scss/medical-document-card.scss';

const cx = classNames.bind(styles);

export function MedicalDocumentCard({
  medicalDocumentVersion,
  documentTypeKey,
}: MedicalDocumentCardProps): ReactElement {
  const getTestResult = (result: string, expirationDate: string): ReactElement => {
    if (
      documentTypeKey === DocumentKey.MedicalDrugTestingConsentForm ||
      documentTypeKey === DocumentKey.MedicalCovid19OrientationAndProcedures
    ) {
      return null;
    }

    if (result === 'Pass' || result === 'No pass') {
      return <Status label={result} status={result === 'Pass' ? 'success' : 'danger'} />;
    }

    const label = (
      <div>
        <div className="mb-1 font-size-base">Conditional Pass</div>
        <div className="font-size-sm">{getISODateTimeToFormattedDate(expirationDate)}</div>
      </div>
    );

    return <Status label={label} status="warning" />;
  };

  return (
    <Card className={cx('medical-document-card', 'mb-1')}>
      <CardBody>
        <div>
          {getTestResult(
            medicalDocumentVersion?.additionalFieldValues?.filter(
              (fieldValue: { key: string }) => fieldValue?.key === 'result',
            )[0]?.value,
            medicalDocumentVersion?.additionalFieldValues?.filter(
              (fieldValue: { key: string }) => fieldValue?.key === 'conditional-access-expiration-date',
            )[0]?.value,
          )}
        </div>
        <div>
          <Thumbnail file={medicalDocumentVersion?.files[0]} />
        </div>
        <Button
          size="sm"
          color="primary"
          className={cx('mt-2 w-100')}
          onClick={(): void => {
            window.open(medicalDocumentVersion?.files[0]?.downloadUrl);
          }}
        >
          <i className="fe fe-download mr-sm-2" />
          <span className="d-none d-sm-inline-block">Download</span>
        </Button>
        <div className={cx('medical-document-card__uploaded-at', 'mt-2')}>
          <ObjectHistory object={medicalDocumentVersion} />
        </div>
      </CardBody>
    </Card>
  );
}
