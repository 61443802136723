import { Alert } from '@odin-labs/components';
import classNames from 'classnames/bind';
import { setRefFactory } from 'components/utils';
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FormGroup, Label } from 'reactstrap';
import styles from './scss/select.scss';
import { FancySelectProps, FancySelectRefType, SelectOptionElement } from './types';

const cx = classNames.bind(styles);

export const FancySelect = React.forwardRef(
  (props: FancySelectProps, ref: React.ForwardedRef<FancySelectRefType>): React.ReactElement => {
    const {
      label,
      name,
      options,
      value,
      defaultValue,
      error,
      onChange,
      placeholder,
      isMulti = false,
      isDisabled = false,
      isCreateable = false,
      isClearable,
      className = '',
      menuPlacement = 'bottom',
    } = props;

    const setRef = setRefFactory<FancySelectRefType>({ outerRef: ref });

    const validValue = value && (options.includes(value) ? value : options.find((opt) => opt.value === value.value));

    const alert = isMulti ? undefined : (validValue as SelectOptionElement)?.alert;

    return (
      <FormGroup className={cx('odin-select', { 'odin-select--error': !!error }, className)}>
        {label ? <Label for={name}>{label}</Label> : null}
        {isCreateable ? (
          <CreatableSelect
            ref={setRef}
            value={validValue}
            defaultValue={defaultValue}
            onChange={onChange}
            options={options}
            isMulti={isMulti}
            placeholder={placeholder}
            isSearchable
            styles={{
              indicatorSeparator: (): any => {
                return { border: '0px' };
              },
            }}
            className="box-shadow-none"
            isDisabled={isDisabled}
            menuPlacement={menuPlacement}
          />
        ) : (
          <Select
            ref={setRef}
            value={validValue}
            defaultValue={defaultValue}
            onChange={onChange}
            options={options}
            isMulti={isMulti}
            placeholder={placeholder}
            isSearchable
            isClearable={isClearable}
            hideSelectedOptions={false}
            styles={{
              indicatorSeparator: (): any => {
                return { border: '0px' };
              },
            }}
            isDisabled={isDisabled}
            menuPlacement={menuPlacement}
          />
        )}

        {error ? <div className={cx('mt-2', { 'invalid-feedback': error, 'd-block': error })}>{error}</div> : null}
        {alert && (
          <div className="odin-mt-2">
            <Alert type="info" {...(typeof alert === 'string' ? { text: alert } : alert)} />
          </div>
        )}
      </FormGroup>
    );
  },
);
