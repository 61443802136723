import React from 'react';
import {
  DocumentKey,
  useCompletePhoneNumberVerificationAndSignInMutation,
  useSelfOnboardingWorkerUpdateMutation,
  useStartPhoneNumberVerificationMutation,
  useUpsertJobsiteWorkerDocumentMutation,
} from 'apollo/generated/client-operations';
import { getGraphQLError, hasGraphQLErrorMessage } from 'utils/error';
import { AuthContext } from 'auth';
import { AlertInstance } from 'components/alertNotification';
import { Timer } from 'components/timer';
import { CodeInput } from 'components/codeInput';
import { isDocumentVisible } from 'containers/selfOnboarding/helpers/utils';
import { SelfOnboardingStepProps, SelfOnboardingStepKey } from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { getWorkerConsentDocumentInput } from 'containers/selfOnboarding/steps/basicInfoStep/BasicInfoStep.forms';

export function VerifyMobileDeviceToSignInCompleteStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, navigation, localize, language, jobsiteInvitation, refetchData } = props;
  const { basicInfo, matchedWorker, verificationPhoneNumber } = state;
  const { workerId: matchedWorkerId } = matchedWorker ?? {};
  const { jobsiteId } = jobsiteInvitation?.jobsiteContractor.jobsite ?? {};
  const { contractorId } = jobsiteInvitation?.jobsiteContractor.contractor ?? {};
  const { goToStep } = navigation;

  const { authState } = React.useContext(AuthContext);
  const [isSaving, setIsSaving] = React.useState(false);
  const [timerResetKey, setTimerResetKey] = React.useState(0);
  const [codeResetKey, setCodeResetKey] = React.useState(0);

  const resetTimer = (): void => setTimerResetKey((key) => key + 1);
  const resetCode = (): void => setCodeResetKey((key) => key + 1);

  const [completePhoneNumberVerificationAndSignIn] = useCompletePhoneNumberVerificationAndSignInMutation();
  const [startPhoneNumberVerification] = useStartPhoneNumberVerificationMutation();
  const [selfOnboardingWorkerUpdate] = useSelfOnboardingWorkerUpdateMutation();
  const [upsertJobsiteWorkerDocument] = useUpsertJobsiteWorkerDocumentMutation();

  const { modules } = jobsiteInvitation?.jobsiteContractor.jobsite ?? {};
  const isWorkerConsentAvailable = isDocumentVisible(DocumentKey.WorkerConsentDocument, modules);

  const onSubmit = async (verificationCode: string): Promise<void> => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    try {
      const { data: resultData } = await completePhoneNumberVerificationAndSignIn({
        variables: { input: { workerId: matchedWorkerId, jobsiteId, contractorId, verificationCode } },
      });
      const { success, auth, jobsiteWorker } = resultData.completePhoneNumberVerificationAndSignIn ?? {};

      setIsSaving(false);

      if (success) {
        const accessToken = auth.session.sessionToken;
        authState.signIn(accessToken, null);

        if (isWorkerConsentAvailable) {
          const { user } = auth;
          const { jobsiteWorkerId } = jobsiteWorker;
          const documentInput = await getWorkerConsentDocumentInput({
            user,
            jobsiteInvitation,
            state,
            jobsiteWorkerId,
          });
          if (documentInput) {
            await upsertJobsiteWorkerDocument({ variables: { input: documentInput } });
          }
        }

        await selfOnboardingWorkerUpdate({
          variables: {
            input: {
              workerId: matchedWorkerId,
              userInput: {
                email: basicInfo.email,
                phoneNumber: basicInfo.phoneNumber,
              },
              workerInput: {
                middleInitial: basicInfo.middleInitial,
                suffix: basicInfo.suffix,
                workerBaseInfoInput: {
                  ssnLastFour: basicInfo.ssnLastFour,
                },
              },
            },
          },
        });
        // `selfOnboardingStepKey` will be reset:
        //   * to SelfOnboardingStepKey.Profile in `completePhoneNumberVerificationAndSignIn` mutation
        //   * to SelfOnboardingStepKey.BasicInfo in `selfOnboardingWorkerUpdate` mutation, if `email`
        //     or `phoneNumber` already exists for a different worker
        // When refetching data, the worker will be redirected to selfOnboardingStepKey
        await refetchData();
      }
    } catch (error) {
      setIsSaving(false);
      if (hasGraphQLErrorMessage(error, 'Unexpected error value: { statusCode: 404 }')) {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', 'Invalid code');
      } else {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      }
    }
  };

  const requestAnotherCode = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): Promise<void> => {
    e.preventDefault();

    try {
      const result = await startPhoneNumberVerification({
        variables: { input: { workerId: matchedWorkerId, phoneNumber: verificationPhoneNumber } },
      });

      if (result.data.startPhoneNumberVerification.success) {
        resetTimer();
        resetCode();
      }
    } catch (error) {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  const codeWasNotReceivedHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault();
    goToStep(SelfOnboardingStepKey.UnableToVerifyAccount);
  };

  return (
    <div>
      <h2 className="odin-text-1.5xl odin-text-odin-black odin-mt-7.5 odin-text-center">
        {localize(Copy.self_onboarding_confirm_phone_title)}
      </h2>
      <CodeInput
        onCodeFilled={onSubmit}
        onInvalidCodeFormat={(code: string): void => {
          AlertInstance.alert('tc', 'danger', 'Invalid code format!', `"${code}"`);
        }}
        resetKey={codeResetKey}
      />
      <div className="odin-pt-10">
        <Timer
          language={language}
          textCopy={Copy.self_onboarding_confirm_phone_time_to_complete}
          onTimeout={(): void => goToStep(SelfOnboardingStepKey.VerifyMobileDeviceToSignIn)}
          resetKey={timerResetKey}
        />
      </div>
      <div className="odin-pt-6.5 odin-px-5 odin-text-sm odin-text-center">
        {localize(Copy.self_onboarding_confirm_phone_instruction)}{' '}
        <a href="" onClick={requestAnotherCode}>
          {localize(Copy.self_onboarding_confirm_phone_request_another_code)}
        </a>
      </div>
      <div className="odin-pt-15 odin-px-5 odin-text-base odin-font-medium odin-text-center">
        <a href="" onClick={codeWasNotReceivedHandler}>
          {localize(Copy.self_onboarding_confirm_phone_code_not_received)}
        </a>
      </div>
    </div>
  );
}
