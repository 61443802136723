import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { LoadingError } from 'components/loadingError';
import { OnboardingStepProps } from 'containers/workerOnboarding/types';
import { Route, Switch, useParams } from 'react-router';
import { Container, Row } from 'reactstrap';
import { getJobsiteWorkerDocumentsData } from './helpers/forms';
import { useJobsiteWorkerOnboardingData } from './helpers/useJobsiteWorkerOnboardingData';

import { BadgingStep } from './steps/BadgingStep';
import { MedicalTestingStep } from './steps/MedicalTestingStep';
import { OnboardingTrainingStep } from './steps/OnboardingTrainingStep';
import { PersonalInfoOverviewStep } from './steps/PersonalInfoOverviewStep';
import { SignatureStep } from './steps/SignatureStep';
import { SiteSpecificTrainingStep } from './steps/SiteSpecificTrainingStep';
import { WorkDocumentsStep } from './steps/WorkDocumentsStep';

import styles from './scss/worker-onboarding.scss';

const cx = classNames.bind(styles);

export function WorkerOnboardingContainer(): ReactElement {
  const { jobsiteWorkerId } = useParams<{ jobsiteWorkerId: string }>();
  const baseUrl = `/onboarding/${jobsiteWorkerId}`;

  const {
    loading,
    error,
    jobsiteWorker,
    jobsiteWorkerDocuments,
    filteredDocumentTypeIds,
    workerContractors,
    refetchJobsiteWorker: refetchJobsiteWorkers,
    refetchJobsiteWorkerDocuments,
  } = useJobsiteWorkerOnboardingData({ jobsiteWorkerId });

  if (error) {
    return <LoadingError error={error} />;
  }

  const { defaultFormValues, documents } = getJobsiteWorkerDocumentsData(jobsiteWorkerDocuments);

  const commonProps: OnboardingStepProps = {
    loading,
    jobsiteWorkerId,
    jobsiteWorker,
    filteredDocumentTypeIds,
    defaultFormValues,
    documents,
    workerContractors,
    refetchJobsiteWorkerDocuments,
    refetchJobsiteWorker: refetchJobsiteWorkers,
  };

  return (
    <Container fluid className={cx('jobsite-worker-orientation-container')}>
      <Row className="justify-content-center md:!odin-px-3 xl:!odin-px-5">
        <Switch>
          <Route path={`${baseUrl}/personal-information`} key="personal-information" exact>
            <PersonalInfoOverviewStep {...commonProps} />
          </Route>
          <Route path={`${baseUrl}/work-documents`} key="work-documents" exact>
            <WorkDocumentsStep {...commonProps} />
          </Route>
          <Route path={`${baseUrl}/onboarding-training`} key="onboarding-training" exact>
            <OnboardingTrainingStep {...commonProps} />
          </Route>
          <Route path={`${baseUrl}/medical-testing`} key="medical-testing" exact>
            <MedicalTestingStep {...commonProps} />
          </Route>
          <Route path={`${baseUrl}/signature`} key="signature" exact>
            <SignatureStep {...commonProps} />
          </Route>
          <Route path={`${baseUrl}/badging`} key="badging" exact>
            <BadgingStep {...commonProps} />
          </Route>
          <Route path={`${baseUrl}/site-specific-orientation`} key="site-specific-orientation" exact>
            <SiteSpecificTrainingStep {...commonProps} />
          </Route>
        </Switch>
      </Row>
    </Container>
  );
}
