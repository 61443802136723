import React from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Badge, TabConfig, getFaIcon } from '@odin-labs/components';
import { ScrollSpyTabsPages, useScrollSpyTabs } from 'components/tabs';
import { NewHeader, classes as headerClasses } from 'components/header/NewHeader';
import { NewHeaderActionsProps } from 'components/header/types';
import { RouterPrompt } from 'components/routerPrompt';
import { incidentTypeColor } from 'containers/jobsiteSafety/helpers/utils';
import { camelToTitleCase, useDeferredFormSubmission, useIsMounted } from 'utils';
import { Container } from 'components/container';
import { useGetJobsiteIncidentQuery } from 'apollo/generated/client-operations';
import {
  JobsiteSafetyIncidentTabName,
  JobsiteSafetyIncidentTabProps,
  JobsiteSafetyIncidentTabWithRefProps,
} from './types';
import { JobsiteSafetyIncidentEdit } from './tabs/JobsiteSafetyIncidentEdit';

const CheckIcon = getFaIcon({ icon: faCheck });

export const classes = {
  container: 'odin-h-full',
  tabPage: cn('odin-overflow-y-scroll odin-bg-white'),
  headerTitleContainer: cn('odin-flex odin-space-x-5 odin-items-center'),
  headerTitleBadgesContainer: cn('odin-space-x-2'),
};

export const tabsConfig: TabConfig<JobsiteSafetyIncidentTabProps>[] = [
  { name: 'summary', text: 'Summary', relativePath: '', component: JobsiteSafetyIncidentEdit },
  { name: 'workers', text: 'Workers', relativePath: '#workers' },
  { name: 'documents', text: 'Attachments', relativePath: '#documents' },
];

export function JobsiteSafetyIncidentContainer(): React.ReactElement {
  const { incidentId } = useParams<{ incidentId: string }>();

  const { data, loading, refetch } = useGetJobsiteIncidentQuery({
    variables: { id: incidentId },
    fetchPolicy: 'no-cache',
    skip: !incidentId,
  });

  const jobsiteIncident = data?.getJobsiteIncident;
  const tabsCount: Record<JobsiteSafetyIncidentTabName, string> = {
    summary: undefined,
    workers: jobsiteIncident?.participants.count.toString(),
    documents: jobsiteIncident?.documents.count.toString(),
  };

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission();

  const baseRoute = `/site-safety/incident/${incidentId}`;
  const tabsConfigWithBadges = tabsConfig.map((tab) => ({
    ...tab,
    badge: tabsCount[tab.name as JobsiteSafetyIncidentTabName],
  }));
  const { tabs, currentTab } = useScrollSpyTabs({ tabsConfig: tabsConfigWithBadges, baseRoute });

  const headerActionsProps = React.useMemo<NewHeaderActionsProps>(
    () => ({
      menuItems: [
        {
          onClick: submitForm,
          text: 'Save Changes',
          hideTextOnMobile: false,
          withSpinner: isSaving,
          icon: CheckIcon,
          disabled: !isFormDirty,
        },
      ],
      baseRoute,
      headerActions: ['back', 'print'],
    }),
    [submitForm, isFormDirty, isSaving],
  );

  const tabsPageProps: JobsiteSafetyIncidentTabWithRefProps = {
    loading,
    jobsiteIncident,
    onIsDirtyChange: (isDirty) => setIsFormDirty(isDirty),
    ref: formRef,
    deferredSubmission,
    refetchJobsiteIncident: refetch,
    isSaving,
    setIsSaving: (value: boolean): void => {
      if (isMounted()) {
        setIsSaving(value);
        if (!value) setIsFormDirty(false);
      }
    },
  };

  const headerTitle = (
    <div className={classes.headerTitleContainer}>
      <h1 className={headerClasses.title}>{jobsiteIncident?.name}</h1>
      <div className={classes.headerTitleBadgesContainer}>
        {jobsiteIncident?.incidentType?.map((incidentType) => (
          <Badge key={incidentType} text={camelToTitleCase(incidentType)} color={incidentTypeColor[incidentType]} />
        ))}
      </div>
    </div>
  );

  return (
    <Container className={classes.container}>
      <RouterPrompt when={isFormDirty} onConfirm={submitForm} />
      <NewHeader title={headerTitle} tabsProps={{ tabs, currentTab }} actionsProps={headerActionsProps} />
      <ScrollSpyTabsPages
        className={classes.tabPage}
        baseRoute={baseRoute}
        tabs={tabs}
        componentProps={tabsPageProps}
      />
    </Container>
  );
}
