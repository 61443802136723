import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { LoadingError } from 'components/loadingError';
import { JobsiteIncident, JobsiteIncidentParticipant } from 'containers/jobsiteSafetyIncident/print/types';
import { WorkerReportAlert } from 'containers/jobsiteSafetyIncident/print/components/workerReport/WorkerReportAlert';
import { useWorkerData } from 'containers/worker/data/useWorkerData';
import { useUpdateQueryStatus } from 'containers/jobsiteSafetyIncident/print/helpers/useUpdateQueryStatus';
import { WorkerReportHeader } from './WorkerReportHeader';
import { WorkerReportTitle } from './WorkerReportTitle';
import { WorkerReportPersonalInfo } from './workerReportPersonalInfo';
import { WorkerReportAccessEvents } from './workerReportAccessEvents';
import { WorkerReportDocuments } from './WorkerReportDocuments';
import { WorkerReportJobsites } from './WorkerReportJobsites';
import { WorkerReportMedicalDocuments } from './workerReportMedicalDocuments';

export const classes = {
  container: cn('odin-space-y-9'),
  headerContainer: cn('odin-space-y-9 print:odin-break-inside-avoid'),
};

export type WorkerReportProps = {
  jobsiteIncident: JobsiteIncident;
  incidentParticipant: JobsiteIncidentParticipant;
  reportDateTime: moment.Moment;
  reportDateTimeText: string;
};

export function WorkerReport(props: WorkerReportProps): React.ReactElement {
  const { incidentParticipant, jobsiteIncident, reportDateTime, reportDateTimeText } = props;
  const {
    selectedJobsiteWorker: {
      contractorWorker: {
        worker: { workerId },
      },
    },
    participantType,
    jobsiteWorkerAccess: jobsiteWorkerAccesses,
    objectHistory: { createdAt },
  } = incidentParticipant;

  const { loading, error, worker, developerIds, jobsiteIds } = useWorkerData(workerId);

  useUpdateQueryStatus(`worker-${workerId}`, { loading, error });

  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }

  const participantAdditiondDateTimeText = moment(createdAt).format('MM/DD/YYYY - hh:mm a');
  const alertText = `The data below were retrieved on ${participantAdditiondDateTimeText}. 
  Any subsequent changes are not reflected in this report.`;

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <WorkerReportHeader jobsiteIncident={jobsiteIncident} reportDateTimeText={reportDateTimeText} />
        <WorkerReportTitle worker={worker} participantType={participantType} />
        <WorkerReportPersonalInfo worker={worker} />
      </div>
      <WorkerReportAlert text={alertText} />
      <WorkerReportJobsites jobsiteWorkerAccesses={jobsiteWorkerAccesses} />
      <WorkerReportAccessEvents
        reportDateTime={reportDateTime}
        worker={worker}
        jobsiteIds={jobsiteWorkerAccesses.map((jwa) => jwa.jobsiteWorker.jobsiteContractor?.jobsite.jobsiteId)}
      />
      <WorkerReportDocuments worker={worker} developerIds={developerIds} jobsiteIds={jobsiteIds} singleItemPerRow />
      <WorkerReportMedicalDocuments worker={worker} jobsiteIds={jobsiteIds} />
    </div>
  );
}
