import React from 'react';
import cn from 'classnames';
import { SearchWorkerInfo } from 'components/searchBox/searchWorkerInfo/SearchWorkerInfo';
import { SearchWorkerResult } from 'components/searchBox/types';
import { useArrowNavigation } from 'utils';

export const classes = {
  resultsContainer: cn(
    'odin-divide-y odin-divide-gray-200',
    'odin-overflow-y-scroll',
    'odin-max-h-[30vh]',
    // 'sm:odin-max-h-[50vh]',
  ),
};

export type JobsiteIncidentWorkerEditSearchResultsProps = {
  searchWorkerResults: SearchWorkerResult[];
  loading?: boolean;
  onSelected: (searchWorkerResult: SearchWorkerResult) => void;
};

export function JobsiteIncidentWorkerEditSearchResults(
  props: JobsiteIncidentWorkerEditSearchResultsProps,
): React.ReactElement {
  const { searchWorkerResults, onSelected } = props;
  const { getItemProps } = useArrowNavigation<HTMLDivElement>(searchWorkerResults.length);

  return (
    <div className={classes.resultsContainer}>
      {searchWorkerResults.map((searchWorkerResult: SearchWorkerResult, index) => {
        const selectSearchWorker = (): void => onSelected(searchWorkerResult);
        return (
          <div
            key={searchWorkerResult.id}
            {...getItemProps(index, { onEnterKey: selectSearchWorker })}
            onClick={selectSearchWorker}
          >
            <SearchWorkerInfo searchWorkerResult={searchWorkerResult} infoType="full" />
          </div>
        );
      })}
    </div>
  );
}
