import { DeepMap } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import { FormInputTypes, TypedFormInputs } from 'components/form';
import { JobsiteIncident, EditJobsiteIncidentFormData } from 'containers/jobsiteSafetyIncident/types';
import { JobsiteIncidentUpdateInput } from 'apollo/generated/client-operations';
import { ensureNonUndefinedFields } from 'utils';
import {
  getDocumentsSectionDefaultValues,
  getDocumentsSectionInputs,
  getDocumentsSectionUpdateInput,
  getSummarySectionDefaultValues,
  getSummarySectionInputs,
  getSummarySectionUpdateInput,
  getWorkersSectionDefaultValues,
  getWorkersSectionInputs,
  getWorkersSectionUpdateInput,
} from './forms';

export const getFormInputs = (
  jobsiteIncident: JobsiteIncident,
  jobsiteOptions: SelectOptionElement[],
): TypedFormInputs<EditJobsiteIncidentFormData> => {
  const summarySectionInputs = getSummarySectionInputs({ jobsiteOptions });
  const workersSectionInputs = getWorkersSectionInputs({ jobsiteIncident });
  const documentsSectionInputs = getDocumentsSectionInputs({ jobsiteIncident });

  return {
    summary: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Summary',
        details: 'This information will be displayed publicly so be careful what you share.',
      },
      children: summarySectionInputs,
    },
    workers: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Workers',
        details: 'Please add any workers who were involved in this incident.',
      },
      children: workersSectionInputs,
    },
    documents: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Attachments',
        details: 'Please upload any relevant photos or documents.',
      },
      children: documentsSectionInputs,
    },
  };
};

export const getDefaultValues = (
  jobsiteIncident: JobsiteIncident,
  jobsiteOptions: SelectOptionElement[],
): EditJobsiteIncidentFormData => {
  const summary = getSummarySectionDefaultValues(jobsiteIncident, jobsiteOptions);
  const workers = getWorkersSectionDefaultValues(jobsiteIncident);
  const documents = getDocumentsSectionDefaultValues(jobsiteIncident);

  return {
    summary,
    workers,
    documents,
  };
};

export const getJobsiteIncidentUpdateInput = async (
  jobsiteIncident: JobsiteIncident,
  data: EditJobsiteIncidentFormData,
  dirtyFields: DeepMap<EditJobsiteIncidentFormData, true>,
): Promise<JobsiteIncidentUpdateInput> => {
  const documentsSectionUpdateInput = await getDocumentsSectionUpdateInput(data.documents, dirtyFields.documents);
  return ensureNonUndefinedFields<JobsiteIncidentUpdateInput>({
    jobsiteIncidentId: jobsiteIncident.id,
    ...getSummarySectionUpdateInput(data.summary, dirtyFields.summary),
    ...getWorkersSectionUpdateInput(data.workers, dirtyFields.workers),
    ...documentsSectionUpdateInput,
  });
};
