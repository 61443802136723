import React, { useState, ReactElement } from 'react';
import { useJobsiteCreateFormSubmissionMutation } from 'apollo/generated/client-operations';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { getFaIcon } from '@odin-labs/components';
import { AuthContext } from 'auth';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { useAvailableJobsiteWorkerOptions, useDependencies } from 'containers/jobsiteFormSubmission/utils';
import {
  getDefaultValues,
  getFormInputsHook,
  useJobsiteContractorsOptions,
  useJobsiteFormsOptions,
  getJobsiteFormSubmissionCreateInput,
} from './AddFormSubmissionModal.forms';
import { AddFormSubmissionFormData, AddFormSubmissionModalProps } from './types';

const ArrowRightIcon = getFaIcon({ icon: faArrowRight });

export function AddFormSubmissionModal(props: AddFormSubmissionModalProps): ReactElement {
  const { form, isOpen, onCancel, onConfirm, onClosed, refetchQueries } = props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { currentUser: user } = React.useContext(AuthContext);

  const [submitJobsiteForm] = useJobsiteCreateFormSubmissionMutation({ refetchQueries });

  const {
    inputs: inputsExpression,
    defaultValues: defaultValuesExpression,
    dependencies: dependenciesExpressions,
  } = form?.content.modal ?? {};

  const jobsiteFormsOptions = useJobsiteFormsOptions(form?.key);
  const defaultJobsiteId = jobsiteFormsOptions?.length === 1 ? jobsiteFormsOptions[0].jobsiteId : null;

  const contractorsOptions = useJobsiteContractorsOptions({
    jobsiteId: defaultJobsiteId,
    skip:
      !(inputsExpression as string)?.includes('ctx.options.contractors') &&
      !(defaultValuesExpression as string)?.includes('ctx.options.contractors'),
  });

  const { availableJobsiteWorkerOptions } = useAvailableJobsiteWorkerOptions({
    jobsiteFormSubmission: undefined,
    jobsiteId: defaultJobsiteId,
    skip:
      !(inputsExpression as string)?.includes('ctx.options.availableJobsiteWorkers') &&
      !(defaultValuesExpression as string)?.includes('ctx.options.availableJobsiteWorkers'),
  });

  const dependencies = useDependencies(dependenciesExpressions);

  const defaultValues = React.useMemo(
    () =>
      getDefaultValues({
        user,
        dependencies,
        defaultValuesExpression,
        jobsiteFormsOptions,
        contractorsOptions,
        availableJobsiteWorkerOptions,
      }),
    [
      user,
      dependencies,
      defaultValuesExpression,
      jobsiteFormsOptions,
      contractorsOptions,
      availableJobsiteWorkerOptions,
    ],
  );

  const inputs = getFormInputsHook({
    user,
    form,
    dependencies,
    defaultValues,
    jobsiteFormsOptions,
  });

  const onSubmit: FormOnSubmit<AddFormSubmissionFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const input = getJobsiteFormSubmissionCreateInput({
        user,
        dependencies,
        form,
        data,
        dirtyFields,
      });
      if (input) {
        const result = await submitJobsiteForm({ variables: { input } });
        AlertInstance.alert('tc', 'success', 'Success', `${form.name} created`);
        onConfirm(result.data.jobsiteCreateFormSubmission.jobsiteFormSubmission);
      }
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      afterLeave={onClosed}
      title={`Add ${form?.name}`}
      inputs={inputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Continue"
      actionIcon={ArrowRightIcon}
      actionButtonWithSpinner={isSaving}
      loading={!defaultValues}
    />
  );
}
