import React from 'react';
import cn from 'classnames';
import { getDateTimeMoment } from 'utils/dates';
import { FormSubmissionTitleItemProps, JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/types';

export const classes = {
  titleSkeleton: cn(
    'odin-text-transparent odin-text-2xl',
    'odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1',
  ),
};

function FormSubmissionTitleItem({ icon, text }: FormSubmissionTitleItemProps): React.ReactElement {
  if (!text) return null;
  return (
    <span className="odin-text-sm">
      <span className={cn(icon, 'odin-text-base odin-text-odin-primary odin-mr-2')} />
      {text}
    </span>
  );
}

export function FormSubmissionTitle({
  jobsiteFormSubmission,
  items,
}: {
  jobsiteFormSubmission: JobsiteFormSubmission;
  items?: FormSubmissionTitleItemProps[];
}): React.ReactElement {
  const { jobsiteForm, startAt, timeZone } = jobsiteFormSubmission ?? {};
  const { jobsite } = jobsiteForm ?? {};
  const { contractor } = jobsiteFormSubmission?.jobsiteContractors?.edges[0]?.node.jobsiteContractor ?? {};

  const date = getDateTimeMoment({ date: startAt, timeZone, isUTC: true });
  const dateText = date?.format('MMM DD, YYYY');

  const jobsiteName = jobsite?.name;
  const contractorName = contractor?.organization.name;

  return (
    <div className="odin-flex odin-gap-x-8">
      <div className="odin-flex odin-flex-col odin-justify-around odin-gap-y-2">
        <h1 className="odin-text-xl odin-font-bold odin-text-odin-black odin-mb-0 sm:odin-text-2xl sm:odin-truncate">
          {dateText ?? <span className={classes.titleSkeleton}>Loading Log Data</span>}
        </h1>
        <div className="odin-flex odin-gap-x-4">
          {items ? (
            items.map((item) => item.text && <FormSubmissionTitleItem key={item.text} {...item} />)
          ) : (
            <>
              <FormSubmissionTitleItem text={jobsiteName} icon={cn('fal fa-briefcase')} />
              <FormSubmissionTitleItem text={contractorName} icon={cn('fal fa-wrench')} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
