import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Label } from '@odin-labs/components';
import { getIcon } from 'utils/ui';
import { JobsiteIncident } from 'containers/jobsiteSafetyIncident/print/types';
import { useWorkforceStatsData } from 'containers/jobsiteSafetyIncident/print/helpers/useWorkforceStatsData';
import { momentFormatter } from 'utils/dates';

export const classes = {
  title: cn('odin-text-gray-900 odin-text-lg odin-leading-6 odin-font-medium odin-mr-9'),
  container: cn('sm:odin-grid sm:odin-grid-cols-4 odin-gap-x-6'),
  detailLabel: cn('odin-text-sm odin-leading-5 odin-font-normal odin-text-gray-900'),
  detailValue: cn('odin-text-3xl odin-leading-10 odin-font-bold odin-text-odin-black odin-mt-3'),
  detailValueSkeleton: cn('odin-animate-pulse odin-w-4/6 odin-bg-gray-300 odin-h-8 odin-rounded-md -odin-ml-1'),
  icon: (faClasses: string): string => cn(faClasses, 'odin-text-odin-primary odin-w-6 odin-text-2xl'),
};

export type JobsiteReportWorkforceStatsItemProps = {
  label: string;
  value?: number;
  iconClassName: string;
  loading: boolean;
};

function JobsiteReportWorkforceStatsItem(props: JobsiteReportWorkforceStatsItemProps): React.ReactElement {
  const { label, value, iconClassName, loading } = props;
  const valueContent = loading ? <div className={classes.detailValueSkeleton} /> : value?.toLocaleString('en-US');
  return (
    <div>
      <i className={iconClassName} />
      <div className={classes.detailValue}>{valueContent}</div>
      <div className={classes.detailLabel}>{label}</div>
    </div>
  );
}

export type JobsiteReportWorkforceStatsProps = {
  jobsiteIncident: JobsiteIncident;
};

export function JobsiteReportWorkforceStats(props: JobsiteReportWorkforceStatsProps): React.ReactElement {
  const { jobsiteIncident } = props;
  const {
    loading,
    workforceStats: { uniqueWorkers, totalManPower, totalHours, averageHours },
  } = useWorkforceStatsData(jobsiteIncident);

  return (
    <div className="odin-space-y-9">
      <div>
        <span className={classes.title}>Workforce Stats</span>
        <Label
          size="lg"
          text={moment(jobsiteIncident.incidentDateTime).format(momentFormatter)}
          icon={getIcon(cn('fal fa-calendar odin-text-odin-primary odin-mr-1.5'))}
        />
      </div>
      <div className={classes.container}>
        <JobsiteReportWorkforceStatsItem
          loading={loading}
          label="Workers on site at time of incident"
          value={uniqueWorkers}
          iconClassName={classes.icon('fal fa-user-hard-hat')}
        />
        <JobsiteReportWorkforceStatsItem
          loading={loading}
          label="Total Manpower"
          value={totalManPower}
          iconClassName={classes.icon('fal fa-construction')}
        />
        <JobsiteReportWorkforceStatsItem
          loading={loading}
          label="Total Hours"
          value={totalHours}
          iconClassName={classes.icon('fal fa-business-time')}
        />
        <JobsiteReportWorkforceStatsItem
          loading={loading}
          label="Average Time Spent Onsite"
          value={averageHours}
          iconClassName={classes.icon('fal fa-user-clock')}
        />
      </div>
    </div>
  );
}
