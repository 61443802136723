import classNames from 'classnames/bind';
import React, { ReactElement } from 'react';

import styles from './scss/status.scss';
import { StatusProps } from './types';

const cx = classNames.bind(styles);

export function Status({
  size = 'sm',
  status = 'default',
  label,
  active = false,
  className,
}: StatusProps): ReactElement {
  return (
    <div className={cx('status', 'd-flex', 'align-items-center', className)}>
      <div
        className={cx('status__circle', 'align-self-start', {
          'status__circle--default': status === 'default',
          'status__circle--success': status === 'success',
          'status__circle--warning': status === 'warning',
          'status__circle--danger': status === 'danger',
          'status__circle--sm': size === 'sm',
          'status__circle--md': size === 'md',
          'status__circle--lg': size === 'lg',
        })}
      >
        ●
      </div>
      {label ? (
        <div
          className={cx('status__label', 'text-reset', {
            'status__label--active': active,
            'status__label--sm': size === 'sm',
          })}
        >
          {label}
        </div>
      ) : null}
    </div>
  );
}
