import classNames from 'classnames/bind';
import { Logo } from 'components/logo';
import React, { ReactElement } from 'react';
import { Col, Container, Navbar } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import styles from './scss/full-layout-navbar.scss';
import { FullLayoutNavbarProps } from './types';

const cx = classNames.bind(styles);

export function FullLayoutNavbarContainer({
  hideClose,
  className = {},
  fixed = 'top',
}: FullLayoutNavbarProps): ReactElement {
  const history = useHistory();
  return (
    <Navbar light className={cx('d-flex', className)} fixed={fixed}>
      <Container fluid>
        <Col className={cx('full-layout-navbar-container__logo')}>
          <Link to="/">
            <Logo small />
          </Link>
        </Col>
        <Col className="text-right">
          {!hideClose ? (
            <button
              className={cx('close', 'full-layout-navbar-container__close-button', 'no-print')}
              onClick={(): void => {
                history.goBack();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          ) : null}
        </Col>
      </Container>
    </Navbar>
  );
}
