import { SelectOptionElement } from '@odin-labs/components';
import { Contractor, JobsiteContractor } from 'apollo/generated/client-operations';

export type ContractorForOption = Pick<Contractor, 'contractorId' | 'isDefaultContractor'> & {
  organization: Pick<Contractor['organization'], 'name'>;
};

export type ContractorOptionElement = SelectOptionElement; // & Pick<Contractor, 'isDefaultContractor'>;

export const getContractorsOptions = (contractors: ContractorForOption[]): ContractorOptionElement[] =>
  contractors
    ?.filter(({ isDefaultContractor }) => !isDefaultContractor)
    .map(({ contractorId: value, organization: { name: label } }) => ({
      label,
      value,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export type JobsiteContractorForOption = {
  contractor: ContractorForOption;
} & Pick<JobsiteContractor, 'startDate'>;

export type JobsiteContractorOptionElement = ContractorOptionElement & Pick<JobsiteContractor, 'startDate'>;

export const getJobsiteContractorsOptions = (
  jobsiteContractors: JobsiteContractorForOption[],
): JobsiteContractorOptionElement[] =>
  jobsiteContractors
    ?.map(
      ({
        contractor: {
          contractorId: value,
          organization: { name: label },
          isDefaultContractor,
        },
        startDate,
      }) => ({ label, value, startDate, isDefaultContractor }),
    )
    .filter(({ isDefaultContractor }) => !isDefaultContractor)
    .sort((a, b) => a.label.localeCompare(b.label));
