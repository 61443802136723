import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { LoadingError } from 'components/loadingError';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { useGetJobsiteIncidentReportQuery } from 'apollo/generated/client-operations';
import { JobsiteIncidentReportDocuments } from './components/documentsReport';
import { JobsiteReport } from './components/jobsiteReport';
import { WorkerReport } from './components/workerReport';
import { LoadingPrintReport } from './components/loadingPrintReport';
import { JobsiteSafetyIncidentPrintContext } from './JobsiteSafetyIncidentPrintContext';
import type { JobsiteSafetyIncidentPrintContextState, QueryStatus } from './types';

export function JobsiteSafetyIncidentPrintContainer(): React.ReactElement {
  const { incidentId } = useParams<{ incidentId: string }>();
  const [queryStatus, setQueryStatus] = React.useState<Record<string, QueryStatus>>({});

  const { data, error, loading } = useGetJobsiteIncidentReportQuery({
    variables: { id: incidentId },
    fetchPolicy: 'no-cache',
  });

  const jobsiteIncident = data?.getJobsiteIncident;

  const updateQueryStatus = (queryName: string, queryStatusItem: QueryStatus): void =>
    setQueryStatus((currentQueryStatus) => ({
      ...currentQueryStatus,
      [queryName]: queryStatusItem,
    }));

  const jobsiteIncidentPrintContextValue = React.useMemo<JobsiteSafetyIncidentPrintContextState>(
    () => ({
      updateQueryStatus,
    }),
    [setQueryStatus],
  );

  const areQueriesloading = Object.values(queryStatus).some((qs) => qs.loading);

  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }

  const reportDateTime = moment(jobsiteIncident.incidentDateTime);
  const reportDateTimeText = reportDateTime.format('MM/DD/YYYY - hh:mm a');

  return (
    <Container>
      <Row>
        <Col xs="12" className="odin-mb-9">
          <Helmet title={`ODIN - ${jobsiteIncident.name} Jobsite Incident Report`} />
          <LoadingPrintReport loading={areQueriesloading} />
          <div className={cn('odin-space-y-9', areQueriesloading && 'print:odin-hidden')}>
            <JobsiteSafetyIncidentPrintContext.Provider value={jobsiteIncidentPrintContextValue}>
              <JobsiteReport jobsiteIncident={jobsiteIncident} reportDateTimeText={reportDateTimeText} />
              {jobsiteIncident.participants.edges.map(({ node: participant }) => (
                <WorkerReport
                  key={participant.id}
                  jobsiteIncident={jobsiteIncident}
                  incidentParticipant={participant}
                  reportDateTime={reportDateTime}
                  reportDateTimeText={reportDateTimeText}
                />
              ))}
              <JobsiteIncidentReportDocuments jobsiteIncident={jobsiteIncident} singleItemPerRow />
            </JobsiteSafetyIncidentPrintContext.Provider>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
