import React from 'react';
import cn from 'classnames';

const classes = {
  infoContainer: cn('odin-flex odin-rounded-md odin-p-6 odin-bg-odin-highlight'),
  infoIcon: cn('info-icon fal fa-lightbulb-on odin-text-odin-primary odin-text-2xl odin-font-normal'),
};

export function JobsiteIncidentWorkerEditSearchInfo(): React.ReactElement {
  return (
    <div className={classes.infoContainer}>
      <div>
        <i className={classes.infoIcon} aria-hidden="true" />
      </div>
      <div className="odin-text-base odin-mt-6 odin-text-gray-900">
        To find a worker, search using any combination of worker name, contractor, jobsite, badge number, quick code, or
        contact info
      </div>
    </div>
  );
}
