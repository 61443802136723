import classNames from 'classnames';
import React from 'react';
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip } from 'reactstrap';

import styles from './scss/field.scss';
import { FieldProps } from './types';

const cx = classNames.bind(styles);

const ENTER_KEY_CODE = 13;

export const Field = React.forwardRef(
  (props: FieldProps, ref: React.ForwardedRef<HTMLInputElement>): React.ReactElement => {
    const {
      name,
      value,
      type,
      label,
      labelIcon,
      labelIconTooltipText,
      onChange,
      onKeyDown,
      onBlur,
      onFocus,
      onSave,
      placeholder,
      disabled,
      note,
      className,
      error,
      rounded,
      flush,
      inputGroup,
      auto,
      plaintext,
      prependIcon,
      maxLength,
      upperCase,
      inputClassName,
      autoFocus,
    } = props;
    const onSaveHandler = (event: React.FormEvent<HTMLInputElement>): void => {
      if (onSave) {
        onSave(event);
      }
    };

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (onChange) {
        const changedValue = upperCase ? event.target.value.toUpperCase() : event.target.value;
        onChange(changedValue);
      }
    };

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.keyCode === ENTER_KEY_CODE) {
        onSaveHandler(event);
      }
      if (onKeyDown) {
        onKeyDown((event.target as HTMLInputElement).value);
      }
    };

    const onBlurHandler = (event: React.FormEvent<HTMLInputElement>): void => {
      if (onBlur) {
        onBlur(event);
      }
    };

    const onFocusHandler = (event: React.FormEvent<HTMLInputElement>): void => {
      if (onFocus) {
        onFocus(event);
      }
    };

    const labelIconWithTooltip = labelIcon ? (
      <>
        <i id="label-icon" className={labelIcon} />
        <UncontrolledTooltip placement="top" target="label-icon">
          {labelIconTooltipText}
        </UncontrolledTooltip>
      </>
    ) : null;

    if (inputGroup) {
      return (
        <InputGroup className={cx('field', { 'input-group-flush': flush }, className)}>
          {label ? <label htmlFor={name}>{label}</label> : null}
          {labelIconWithTooltip}
          {note ? <small className={cx('form-text', 'text-muted')}>{note}</small> : null}
          {prependIcon ? (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className={prependIcon} />
              </InputGroupText>
            </InputGroupAddon>
          ) : null}
          <Input
            name={name}
            id={name}
            value={value}
            type={type}
            onChange={onChangeHandler}
            onKeyDown={onKeyDownHandler}
            onBlur={onBlurHandler}
            onFocus={onFocusHandler}
            placeholder={placeholder}
            disabled={disabled}
            invalid={!!error}
            plaintext={plaintext}
            maxLength={maxLength}
            innerRef={ref}
            autoFocus={autoFocus}
            className={cx(
              'form-control',
              {
                'form-control-rounded': rounded,
                'form-control-flush': prependIcon ? false : flush || auto,
                'form-control-auto': auto,
              },
              className,
            )}
          />
          {error ? <div className={cx('mt-2', { 'invalid-feedback': error })}>{error}</div> : null}
        </InputGroup>
      );
    }

    return (
      <FormGroup className={cx('field', className)}>
        {label ? <label htmlFor={name}>{label}</label> : null}
        {labelIconWithTooltip}
        {note ? <small className={cx('form-text', 'text-muted')}>{note}</small> : null}
        <Input
          name={name}
          id={name}
          value={value}
          type={type}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          placeholder={placeholder}
          disabled={disabled}
          invalid={!!error}
          plaintext={plaintext}
          maxLength={maxLength}
          innerRef={ref}
          autoFocus={autoFocus}
          className={cx(
            'form-control',
            {
              'form-control-rounded': rounded,
              'form-control-flush': prependIcon ? false : flush || auto,
              'form-control-auto': auto,
            },
            className,
            inputClassName,
          )}
        />
        {error ? <div className={cx('mt-2', { 'invalid-feedback': error })}>{error}</div> : null}
      </FormGroup>
    );
  },
);
