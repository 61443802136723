import { CardStatus, WorkerCard, WorkerCardType } from 'apollo/generated/client-operations';
import { BadgeChecklist } from 'components/badgeChecklist/BadgeChecklist';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { Worker } from 'containers/worker/types';
import { IssueMobileBadgeFormData } from './types';

export const getFormInputs = (): TypedFormInputs<IssueMobileBadgeFormData> => ({
  phoneNumber: {
    element: FormInputTypes.OdinField,
    label: 'Phone Number',
    elementProps: {
      fieldType: 'phone',
      showDefaultIcon: true,
    },
    validation: {
      required: true,
    },
    layout: GridColSpan.SpanFull,
  },
  badges: {
    element: FormInputTypes.CustomInput,
    elementProps: {
      customInput: BadgeChecklist,
      ...{ disableSelection: true },
    },
    layout: GridColSpan.SpanFull,
  },
});

export const getFormDefaultValues = (worker: Worker, workerCards: WorkerCard[]): IssueMobileBadgeFormData => ({
  phoneNumber: worker?.user.identity.phoneNumber,
  badges: workerCards
    ?.filter(
      ({ cardStatus, workerCardFormat }) =>
        cardStatus === CardStatus.Active && workerCardFormat.cardType === WorkerCardType.MobileBadge,
    )
    ?.map((workerCard) => ({ workerCard, selected: true })),
});
