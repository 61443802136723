import React from 'react';
import cn from 'classnames';
import { JobsiteFormSubmission, JobsiteFormSubmissionDocument } from 'containers/jobsiteFormSubmission/print/types';
import { FormSubmissionReportSectionTitle } from 'containers/jobsiteFormSubmission/print/components/reportSectionTitle';
import { FormSubmissionReportDocument } from './FormSubmissionReportDocument';

const classes = {
  container: '',
  documentsHeader: cn('odin-mt-9'),
  documentsText: cn('odin-text-lg odin-leading-6 odin-font-medium odin-text-gray-900'),
  documentsCount: cn('odin-ml-3'),
  documentsContainer: cn('odin-h-fit odin-border-separate odin-border-spacing-6 -odin-mx-6'),
};

export type FormSubmissionReportDocumentsProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  singleItemPerRow?: boolean;
};

export function FormSubmissionReportDocuments(props: FormSubmissionReportDocumentsProps): React.ReactElement {
  const { jobsiteFormSubmission, singleItemPerRow } = props;
  const documents = jobsiteFormSubmission.documents?.edges.map(({ node }) => node);

  const documentsByRows: Array<JobsiteFormSubmissionDocument[]> = documents.reduce(
    (result, item, index): JobsiteFormSubmissionDocument[] => {
      if (singleItemPerRow || index % 2 === 0) {
        result.push([item]);
      } else {
        result[result.length - 1].push(item);
      }
      return result;
    },
    [],
  );

  const documentRows = documentsByRows.map((rowDocs) => (
    <tr key={rowDocs[0].id}>
      {rowDocs.map(
        (document): React.ReactElement => (
          <td key={document.id}>
            <FormSubmissionReportDocument document={document} singleItemPerRow={singleItemPerRow} />
          </td>
        ),
      )}
    </tr>
  ));

  return (
    <div className={classes.container}>
      <FormSubmissionReportSectionTitle title="Attachments" count={documents?.length} />
      {/* use table here as fragmentation is not supported for grid */}
      <table className={classes.documentsContainer}>
        <tbody>{documentRows}</tbody>
      </table>
    </div>
  );
}
