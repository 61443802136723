import React from 'react';
import { AvatarCell, AvatarCellComponentProps, AvatarSkeleton, Column } from '@odin-labs/components';
import { JobsiteFormSubmissionWorker } from 'containers/jobsiteFormSubmission/print/types';
import { getInitialsForUser, getUserFullName } from 'utils';
import { getPrettyFormattedUtcDate } from 'utils/dates';

export type JobsiteFormSubmissionWorkerColumn = Column<JobsiteFormSubmissionWorker>;

export const getColumns = (): JobsiteFormSubmissionWorkerColumn[] => [
  {
    id: 'worker',
    Header: 'Worker',
    accessor: (formSubmissionWorker: JobsiteFormSubmissionWorker): string =>
      getUserFullName(formSubmissionWorker.jobsiteWorker.contractorWorker.worker.user),
    Cell: AvatarCell,
    CellLoading: <AvatarSkeleton size="xs" hideDetails />,
    componentProps: (formSubmissionWorker: JobsiteFormSubmissionWorker): AvatarCellComponentProps => ({
      src: formSubmissionWorker.jobsiteWorker.contractorWorker.worker.profilePictureCropped?.downloadUrl,
      placeholder: getInitialsForUser(formSubmissionWorker.jobsiteWorker.contractorWorker.worker.user),
      objectFit: 'cover',
      size: 'xs',
    }),
  },
  {
    id: 'trade',
    Header: 'Trade',
    accessor: (formSubmissionWorker: JobsiteFormSubmissionWorker): string =>
      formSubmissionWorker.jobsiteWorker.contractorWorker.worker.trade,
  },
  {
    id: 'title',
    Header: 'Title',
    accessor: (formSubmissionWorker: JobsiteFormSubmissionWorker): string =>
      formSubmissionWorker.jobsiteWorker.contractorWorker.worker.jobTitle,
  },
  {
    id: 'quickCode',
    Header: 'Quick Code',
    accessor: (formSubmissionWorker: JobsiteFormSubmissionWorker): string =>
      formSubmissionWorker.jobsiteWorker.contractorWorker.worker.quickCode,
  },
  {
    id: 'dateOfBirth',
    Header: 'Date of Birth',
    accessor: (formSubmissionWorker: JobsiteFormSubmissionWorker): string =>
      getPrettyFormattedUtcDate(formSubmissionWorker.jobsiteWorker.contractorWorker.worker.birthDate),
  },
];
