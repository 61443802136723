import classNames from 'classnames/bind';
import React, { ReactElement } from 'react';

import styles from './scss/avatar.scss';
import { AvatarProps } from './types';

const cx = classNames.bind(styles);

export function Avatar({
  src = '',
  alt = '',
  size = 'lg',
  circle = true,
  online = false,
  wide = false,
  showStatus = false,
  label = '',
  style = {},
  className = {},
}: AvatarProps): ReactElement {
  return (
    <div
      className={cx(
        'avatar',
        `avatar-${size}`,
        {
          'avatar-online': showStatus && online,
          'avatar-offline': showStatus && !online,
          'avatar-4by3': wide,
        },
        className,
        style,
      )}
    >
      {src ? (
        <img
          src={src}
          alt={alt}
          className={cx('avatar-img', {
            rounded: !circle,
            'rounded-circle': circle,
          })}
        />
      ) : (
        <span
          className={cx('avatar-title', {
            rounded: !circle,
            'rounded-circle': circle,
          })}
        >
          {label}
        </span>
      )}
    </div>
  );
}
