import React, { ReactElement } from 'react';
import moment from 'moment';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { Filter, NewMenuItemProps, SortingRule, Table, TableContainer, getFaIcon } from '@odin-labs/components';
import { to } from 'acl';
import { ApolloError } from '@apollo/client';
import {
  GetJobsiteWorkersOrderByField,
  JobsiteWorkerOrientationStatus,
  useGetOnboardingJobsiteWorkersQuery,
  JobsiteWorkerOrientationStatusFilter,
} from 'apollo/generated/client-operations';

import { AuthContext } from 'auth';
import { Container } from 'components/container';
import { NewHeader } from 'components/header/NewHeader';
import { LoadingError } from 'components/loadingError';
import { AddWorkerModal, EditSelfOnboardingWorkerWarningModal } from 'containers/worker/modals';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { useHistory } from 'react-router';
import { Maybe } from 'types';
import {
  ensureNonEmptyItems,
  getCurrentISOFormattedDate,
  isQueryLoading,
  useBoolean,
  useQueryOrderBy,
  useResettableState,
} from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { SortOrder } from 'utils/useQueryOrderBy';
import { getColumns, getFilterItems, orderByFields } from './helpers/tables';
import { JobsiteWorkerOnboardingFilterValue, jobsiteWorkerOnboardingStatusMapping } from './helpers/utils';
import { OnboardingFilters, OnboardingJobsiteWorkerAccess } from './types';

const UserPlus = getFaIcon({ icon: faUserPlus });

export function WorkerOnboardingsContainer(): ReactElement {
  const history = useHistory();
  const { currentUser: user } = React.useContext(AuthContext);
  const isNotContractorUser = !user.isContractor;

  const defaultStartDate = moment(getCurrentISOFormattedDate());
  const defaultEndDate = moment(getCurrentISOFormattedDate());

  const {
    page,
    orderBy: defaultSortField,
    orderByDesc: isDescending,
    startDate,
    endDate,
    jobsiteIds,
    contractorIds,
    onboardingStatus,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams({
    updateJobsiteSelection: true,
    defaultValues: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
  });

  const offset = page * limit;
  const { value: isAddWorkerModalOpen, setTrue: openAddWorkerModal, setFalse: closeAddWorkerModal } = useBoolean(false);
  const {
    value: { then: onEditSelfOnboardingWorkerConfirm },
    setValue: confirmSelfOnboardingWorkerEdit,
    resetValue: closeEditSelfOnboardingWorkerWarningModal,
  } = useResettableState<{ then: () => void }>({ then: null }, { then: null });

  const goToOnboardingPersonalInformation = React.useCallback(
    (jobsiteWorkerId: string): void => {
      history.push(`/onboarding/${jobsiteWorkerId}/personal-information`);
    },
    [history],
  );

  const { tableSortInfo, orderBy, setNewOrderBy } = useQueryOrderBy<string, GetJobsiteWorkersOrderByField>(
    defaultSortField,
    isDescending,
    (tableField: string): GetJobsiteWorkersOrderByField => orderByFields[tableField],
  );

  const tableSortBy: Array<SortingRule<string>> = tableSortInfo?.dataField
    ? [{ id: tableSortInfo.dataField, desc: tableSortInfo.order === 'desc' }]
    : [];

  const filterOnboardingStatus = onboardingStatus as JobsiteWorkerOnboardingFilterValue;
  const orientationStatus: Maybe<JobsiteWorkerOrientationStatusFilter> =
    jobsiteWorkerOnboardingStatusMapping[filterOnboardingStatus] ?? null;

  const { jobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();

  const {
    data: jobsiteWorkerData,
    error: jobsiteWorkerError,
    networkStatus: jobsiteWorkerNetworkStatus,
    refetch: refetchOnboardingJobsiteWorkers,
  } = useGetOnboardingJobsiteWorkersQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
    skip: isUrlLoading,
    variables: {
      jobsiteIds,
      contractorIds,
      orientationStartDate: startDate?.toDate(),
      orientationEndDate: endDate?.toDate(),
      limit,
      offset,
      // timezoneName: moment.tz.guess(),
      orientationStatus,
      orderBy,
    },
  });

  const { user: userData } = jobsiteWorkerData?.getCurrentSession ?? {};
  const contractors = userData?.contractors.edges.map(({ node }) => node);

  const jobsiteWorkerAccesses = jobsiteWorkerData?.getJobsiteWorkers?.edges;
  const jobsiteWorkerAccessesCount = jobsiteWorkerData?.getJobsiteWorkers?.totalCount ?? 0;
  const jobsiteStats = jobsiteWorkerData?.getJobsiteWorkers?.jobsiteStats;

  const pageCount = Math.ceil(jobsiteWorkerAccessesCount / limit);

  const columns = React.useMemo(() => getColumns(), []);
  const filterItems = React.useMemo(
    () =>
      getFilterItems({
        jobsites,
        jobsiteStats,
        contractors,
        jobsiteIds,
        contractorIds,
        startDate,
        endDate,
        onboardingStatus,
      }),
    [jobsites, jobsiteStats, jobsiteIds, contractorIds, startDate, endDate, onboardingStatus],
  );

  const onSortByChangeHandler = (sortBy: Array<SortingRule<OnboardingJobsiteWorkerAccess>>): void => {
    const [firstSortBy] = sortBy ?? [];
    const sortField = firstSortBy?.id ?? null;
    const sortOrder: SortOrder = firstSortBy?.desc ? 'desc' : 'asc';
    const newOrderBy = sortField && { field: sortField, order: sortOrder };
    setNewOrderBy(newOrderBy);
    updateUrl({
      orderBy: sortField,
      orderByDesc: sortField && sortOrder === 'desc' ? true : null,
    });
  };

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({ page: pageIndex ? pageIndex + 1 : null });
  };

  const onFilterChangeHandler = (changedFilters: Partial<OnboardingFilters>): void => {
    const { dateRange: dateRangeFilter, ...restChangedFilters } = changedFilters;
    const dateRange = dateRangeFilter === null ? { startDate: null, endDate: null } : dateRangeFilter;
    updateUrl({ page: null, ...dateRange, ...restChangedFilters });
  };

  const onRowClickHandler = ({ data: jobsiteWorkerAccess }: { data: OnboardingJobsiteWorkerAccess }): void => {
    const { jobsiteWorker } = jobsiteWorkerAccess ?? {};
    const { jobsiteWorkerId } = jobsiteWorker ?? {};
    if (jobsiteWorker.orientationStatus === JobsiteWorkerOrientationStatus.SelfOnboarding) {
      confirmSelfOnboardingWorkerEdit({ then: () => goToOnboardingPersonalInformation(jobsiteWorkerId) });
    } else {
      goToOnboardingPersonalInformation(jobsiteWorkerId);
    }
  };

  const menuItems = ensureNonEmptyItems<NewMenuItemProps>([
    isNotContractorUser &&
      user.isAllowed(to.addWorkers) && {
        text: 'Add worker',
        icon: UserPlus,
        onClick: openAddWorkerModal,
      },
  ]);

  const loading: boolean = isQueryLoading(jobsiteWorkerNetworkStatus) || userJobsitesLoading;
  const error: ApolloError = jobsiteWorkerError || userJobsitesError;

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <Container className="worker-onboardings-container">
      <NewHeader
        title="Jobsite Onboarding"
        titleInfo={jobsiteWorkerAccessesCount}
        actionsProps={{ menuItems, onReloadPressed: refetchOnboardingJobsiteWorkers }}
      />
      <TableContainer>
        <Filter items={filterItems} loading={loading} onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={jobsiteWorkerAccesses}
          initialState={{ sortBy: tableSortBy, pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          onSortByChange={onSortByChangeHandler}
          disableGlobalFilter
        />
      </TableContainer>
      <AddWorkerModal
        isOpen={isAddWorkerModalOpen}
        closeModal={closeAddWorkerModal}
        defaultJobsiteId={jobsiteIds?.[0]}
      />
      <EditSelfOnboardingWorkerWarningModal
        isOpen={!!onEditSelfOnboardingWorkerConfirm}
        closeModal={closeEditSelfOnboardingWorkerWarningModal}
        onConfirm={(): void => {
          onEditSelfOnboardingWorkerConfirm();
          closeEditSelfOnboardingWorkerWarningModal();
        }}
      />
    </Container>
  );
}
