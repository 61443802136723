import React from 'react';
import cn from 'classnames';

import { JobsiteWorkerDocument } from 'apollo/generated/client-operations';
import { getFormattedDate } from 'utils';
import { getLatestWorkerDocument, getMedicalDocType, getWorkerDocumentFieldValue } from 'containers/worker/utils';

const classes = {
  jobsite: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-medium'),
  date: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal'),
  time: cn('odin-text-gray-600 odin-text-sm odin-leading-5 odin-font-normal'),
};

export function JobsiteCell({ value }: { value: string }): React.ReactElement {
  return <div className={classes.jobsite}>{value}</div>;
}

export function DateTimeCell({ value }: { value: JobsiteWorkerDocument }): React.ReactElement {
  const latestDocument = getLatestWorkerDocument(value);
  const testDate = getWorkerDocumentFieldValue(latestDocument, 'test-taken-date');
  const date = getFormattedDate(testDate);

  return (
    <>
      <div className={classes.date}>{date}</div>
      {/* <div className={classes.time}>{'time'}</div> */}
    </>
  );
}

export function TestTypeCell({ value }: { value: JobsiteWorkerDocument }): React.ReactElement {
  const latestDocument = getLatestWorkerDocument(value);
  const testReason = getWorkerDocumentFieldValue(latestDocument, 'test-reason');
  return (
    <>
      <div className={classes.date}>{getMedicalDocType(value)}</div>
      {testReason && <div className={classes.time}>{`${testReason} Test`}</div>}
    </>
  );
}
