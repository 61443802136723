import React from 'react';
import cn from 'classnames';
import { JobsiteIncident } from 'containers/jobsiteSafetyIncident/print/types';
import { JobsiteReportHeader } from './JobsiteReportHeader';
import { JobsiteReportTitle } from './JobsiteReportTitle';
import { JobsiteReportDetails } from './JobsiteReportDetails';
import { JobsiteReportWorkforceStats } from './JobsiteReportWorkforceStats';
import { JobsiteReportWorkerSessions } from './jobsiteReportWorkerSessions';

export const classes = {
  container: cn('odin-flex odin-flex-col odin-space-y-9'),
};

export type JobsiteReportProps = {
  jobsiteIncident: JobsiteIncident;
  reportDateTimeText: string;
};

export function JobsiteReport(props: JobsiteReportProps): React.ReactElement {
  const { jobsiteIncident, reportDateTimeText } = props;

  return (
    <div className={classes.container}>
      <JobsiteReportHeader jobsiteIncident={jobsiteIncident} reportDateTimeText={reportDateTimeText} />
      <JobsiteReportTitle jobsiteIncident={jobsiteIncident} />
      <JobsiteReportDetails jobsiteIncident={jobsiteIncident} />
      <JobsiteReportWorkerSessions jobsiteIncident={jobsiteIncident} />
      <JobsiteReportWorkforceStats jobsiteIncident={jobsiteIncident} />
    </div>
  );
}
