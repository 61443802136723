import { DeepMap } from 'react-hook-form';
import {
  UserUpdateIdentityInput,
  UserPendingChangeType as PendingChangeType,
} from 'apollo/generated/client-operations';
import { FormInputTypes, TypedFormInputs, getUpdateInputValueFunction, GridColSpan } from 'components/form';
import { Worker, UserIdentityPendingChanges } from 'containers/worker/types';
import { ensureNonUndefinedFields, getPhoneNumberAsE164 } from 'utils';
import { emailValidation, phoneNumberValidation } from 'utils/validation';
import { EnvelopeIcon } from 'components/icons';
import { SubmitUserChangeFormData } from './types';

type GetFormInputsArgs = {
  type: PendingChangeType;
  pendingChanges: UserIdentityPendingChanges;
};

export const getFormInputs = (args: GetFormInputsArgs): TypedFormInputs<SubmitUserChangeFormData> => {
  const { type, pendingChanges } = args;
  return {
    currentPhoneNumber: {
      element: FormInputTypes.OdinField,
      label: 'Current phone number',
      elementProps: {
        fieldType: 'phone',
        showDefaultIcon: true,
        disabled: true,
      },
      validation: { pattern: phoneNumberValidation },
      layout: type === PendingChangeType.Phone ? [GridColSpan.SpanFull, GridColSpan.SmSpan6] : 'odin-hidden',
    },
    newPhoneNumber: {
      element: FormInputTypes.OdinField,
      label: 'New phone number',
      elementProps: {
        fieldType: 'phone',
        showDefaultIcon: true,
        disabled: !!pendingChanges?.phoneNumber,
      },
      validation: { pattern: phoneNumberValidation },
      layout: type === PendingChangeType.Phone ? [GridColSpan.SpanFull, GridColSpan.SmSpan6] : 'odin-hidden',
    },
    currentEmail: {
      element: FormInputTypes.OdinField,
      label: 'Current email address',
      elementProps: {
        disabled: true,
        icon: EnvelopeIcon,
      },
      validation: { pattern: emailValidation },
      layout: type === PendingChangeType.Email ? [GridColSpan.SpanFull, GridColSpan.SmSpan6] : 'odin-hidden',
    },
    newEmail: {
      element: FormInputTypes.OdinField,
      label: 'New email address',
      elementProps: {
        icon: EnvelopeIcon,
        disabled: !!pendingChanges?.email,
      },
      validation: { pattern: emailValidation },
      layout: type === PendingChangeType.Email ? [GridColSpan.SpanFull, GridColSpan.SmSpan6] : 'odin-hidden',
    },
  };
};

export const getDefaultValues = (worker: Worker): SubmitUserChangeFormData => {
  const { email, phoneNumber, pendingChanges } = worker?.user.identity ?? {};

  return {
    currentPhoneNumber: phoneNumber ?? '',
    newPhoneNumber: pendingChanges?.phoneNumber?.newValue ?? '',
    currentEmail: email ?? '',
    newEmail: pendingChanges?.email?.newValue ?? '',
  };
};

export type UserIdentityPendingChangeInput = Pick<UserUpdateIdentityInput, 'userAccountId'> & {
  userIdentityInput: Pick<UserUpdateIdentityInput['userIdentityInput'], 'pendingChanges'>;
};

export const getPendingChangeInput = (
  worker: Worker,
  data: SubmitUserChangeFormData,
  dirtyFields: DeepMap<SubmitUserChangeFormData, true>,
): UserIdentityPendingChangeInput => {
  const { user } = worker;
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  return ensureNonUndefinedFields<UserIdentityPendingChangeInput>({
    userAccountId: user.userAccountId,
    userIdentityInput: {
      pendingChanges: {
        phoneNumber: getPhoneNumberAsE164(getUpdateInputValue('newPhoneNumber')),
        email: getUpdateInputValue('newEmail'),
      },
    },
  });
};
