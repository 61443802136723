import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { useBoolean } from 'utils';
import { getIconWithAutoHeight } from 'utils/ui';

import { NewMenuItemProps, TabConfig } from '@odin-labs/components';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { NewHeader } from 'components/header/NewHeader';
import { AddJobsiteIncidentModal } from 'containers/jobsiteSafety/modals/AddJobsiteIncidentModalContainer';
import { Container } from 'components/container';
import { AuthContext } from 'auth';
import { LockedFeatureAlert } from 'components/lockedFeatureAlert';
import { JobsiteSafetyTabData, JobsiteSafetyTabProps } from './types';

import { JobsiteSafetyIncidents } from './tabs/JobsiteSafetyIncidents';
import { useUserJobsitesData } from './data/useUserJobsitesData';
import { AddJobsiteIncidentModalProps } from './modals/types';
import { siteSafetyUrl } from './helpers/utils';

const lockedFeatureMessage = (
  <>
    This feature is part of Site Safety. Site Safety helps you create and manage incident reports. Contact&nbsp;
    <a href="mailto:support@useodin.com" target="blank">
      support@useodin.com
    </a>
    &nbsp;to learn more.
  </>
);

export const tabsConfig: TabConfig<JobsiteSafetyTabProps>[] = [
  { name: 'incidents', text: 'Incidents', relativePath: '', component: JobsiteSafetyIncidents },
];

export function JobsiteSafetyContainer(): ReactElement {
  const [tabApi, setTabApi] = React.useState<JobsiteSafetyTabData>(null);
  const { data: userJobsites, refetch: refetchUserJobsites } = useUserJobsitesData();

  const history = useHistory();
  const { currentUser: user } = React.useContext(AuthContext);
  const isFeatureLocked = !user.hasSiteSafetyEnabled;

  const tabsConfigWithBadges = tabsConfig.map((tab) => ({ ...tab, badge: tabApi?.tabsCount[tab.name] }));
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig: tabsConfigWithBadges, baseRoute: siteSafetyUrl });

  const { value: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false);
  const menuItems: NewMenuItemProps[] = [
    {
      onClick: openModal,
      text: 'Incident',
      icon: getIconWithAutoHeight('fal fa-plus'),
      disabled: isFeatureLocked,
    },
  ];

  const tabsPageProps: JobsiteSafetyTabProps = {
    onTabApiChange: setTabApi,
  };

  const closeModalAndNavigateToJobsiteIncident: AddJobsiteIncidentModalProps['onConfirm'] = React.useCallback(
    (jobsiteIncident): void => {
      closeModal();
      history.push(`${siteSafetyUrl}/incident/${jobsiteIncident.id}`);
    },
    [closeModal, history],
  );

  const refetchData = React.useCallback(() => {
    refetchUserJobsites();
    tabApi?.refetchData();
  }, [tabApi?.refetchData, refetchUserJobsites]);

  if (isFeatureLocked) {
    return (
      <div className="odin-p-8">
        <LockedFeatureAlert message={lockedFeatureMessage} />
      </div>
    );
  }

  return (
    <Container>
      <NewHeader
        title="Site Safety"
        tabsProps={{ tabs, currentTab }}
        actionsProps={{ menuItems, baseRoute: siteSafetyUrl, onReloadPressed: refetchData }}
      />
      <RoutedTabsPages baseRoute={siteSafetyUrl} tabs={tabs} componentProps={tabsPageProps} />
      <AddJobsiteIncidentModal
        isOpen={isModalOpen}
        jobsites={userJobsites}
        onCancel={closeModal}
        onConfirm={closeModalAndNavigateToJobsiteIncident}
      />
    </Container>
  );
}
