import React from 'react';
import { LoadingError } from 'components/loadingError';
import { useIsMounted } from 'utils';
import { useJobsiteIncidentUpdateMutation } from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { EditJobsiteIncidentFormData, JobsiteSafetyIncidentTabProps } from 'containers/jobsiteSafetyIncident/types';
import { Form, FormOnSubmit } from 'components/form';
import { getGraphQLError } from 'utils/error';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { getJobsitesOptions } from 'containers/users/modals/utils';
import { getDefaultValues, getFormInputs, getJobsiteIncidentUpdateInput } from './JobsiteSafetyIncidentEdit.forms';

export const JobsiteSafetyIncidentEdit = React.forwardRef<HTMLFormElement, JobsiteSafetyIncidentTabProps>(
  (props, ref): React.ReactElement => {
    const {
      jobsiteIncident,
      loading: jobsiteIncidentLoading,
      onIsDirtyChange,
      refetchJobsiteIncident,
      isSaving,
      setIsSaving,
      deferredSubmission,
    } = props;
    const isMounted = useIsMounted();
    const { jobsites: userJobsites, loading: userJobsitesLoading } = useAvailableJobsites();

    const [updateJobsiteIncident] = useJobsiteIncidentUpdateMutation({ fetchPolicy: 'no-cache' });

    const onSubmit: FormOnSubmit<EditJobsiteIncidentFormData> = async (data, event, dirtyFields): Promise<void> => {
      if (isSaving) {
        AlertInstance.alert('tc', 'warning', 'Saving in progress!', '');
        return;
      }

      setIsSaving(true);

      const input = await getJobsiteIncidentUpdateInput(jobsiteIncident, data, dirtyFields);
      try {
        await updateJobsiteIncident({ variables: { input } });

        AlertInstance.alert('tc', 'success', 'Success', 'Jobsite incident successfully updated');
        if (isMounted()) {
          refetchJobsiteIncident();
        }

        deferredSubmission.resolve(true);
      } catch (error) {
        event.preventDefault();
        deferredSubmission.reject(error);
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      } finally {
        if (isMounted()) setIsSaving(false);
      }
    };

    const jobsiteOptions = React.useMemo(() => getJobsitesOptions(userJobsites), [userJobsites]);
    const formInputs = React.useMemo(
      () => getFormInputs(jobsiteIncident, jobsiteOptions),
      [jobsiteIncident, jobsiteOptions],
    );
    const defaultValues = React.useMemo(
      () => getDefaultValues(jobsiteIncident, jobsiteOptions),
      [jobsiteIncident, jobsiteOptions],
    );

    if (jobsiteIncidentLoading || userJobsitesLoading) {
      return <LoadingError loading />;
    }

    return (
      <Form
        ref={ref}
        inputs={formInputs}
        onSubmit={onSubmit}
        onIsDirtyChange={onIsDirtyChange}
        defaultValues={defaultValues}
        inputsContainerClassName="odin-space-y-5 sm:odin-space-y-9 odin-divide-y odin-divide-gray-200 odin-pb-9"
      />
    );
  },
);
