import { RadioGroupOption, SelectOptionElement } from '@odin-labs/components';
import type {
  GetJobsiteAnnouncementQuery,
  GetJobsiteAnnouncementsContainerContractorsSessionQuery,
  JobsiteAnnouncementFilterSpecification,
  JobsiteAnnouncementInput,
} from 'apollo/generated/client-operations';
import { AnnouncementType, JobsiteAccessStatusType } from 'apollo/generated/client-operations';
import { Jobsite } from 'graphql/client/useAvailableJobsites';
import { Editable, Maybe } from 'types';

import { PageSearchParams } from 'utils';

export { AnnouncementType, AnnouncementStatus, JobsiteAccessStatusType } from 'apollo/generated/client-operations';

export type { Jobsite };

export type Announcement = Partial<GetJobsiteAnnouncementQuery['getJobsiteAnnouncement']>;

export type EditableAnnouncement = Editable<Announcement>;

type ContractorQuery = GetJobsiteAnnouncementsContainerContractorsSessionQuery;
export type Contractor = ContractorQuery['getCurrentSession']['user']['contractors']['edges'][number]['node'];

export type CreateAnnouncementData = Partial<JobsiteAnnouncementInput> &
  JobsiteAnnouncementFilterSpecification & { timeZone?: string };

export interface JobsiteSelectOptionElement extends SelectOptionElement {
  jobsite: Jobsite;
}

export interface CreateAnnouncementModalContainerProps {
  // isWorkerAccessEnabled?: boolean;
  contractorOptions?: SelectOptionElement[];
  jobsiteOptions?: JobsiteSelectOptionElement[];
  jobsites?: Jobsite[];
  announcement?: CreateAnnouncementData;
  updateAnnouncement?: (upd: Partial<CreateAnnouncementData>) => void;
  onBack?: () => void;
  onCancel?: () => void;
  onAction?: () => void;
  counts?: { totalReachableCount: number; totalCount: number };
}

export interface DeleteAnnouncementModalProps {
  announcement: Announcement;
  isOpen?: boolean;
  onCancel?: () => void;
  onConfirm?: (announcement: EditableAnnouncement) => void;
}

export type MakeAnnouncementFormData = {
  jobsiteIds: JobsiteSelectOptionElement[];
  announcementType: Maybe<RadioGroupOption<AnnouncementType>>;
};

export type FilterRecipientsFormData = {
  contractorIds: SelectOptionElement[];
  trades: SelectOptionElement[];
  languages: SelectOptionElement[];
  jobsiteAccessStatus: Maybe<RadioGroupOption<JobsiteAccessStatusType>>;
  filterAccessWithinDayRange?: number;
  recipientCounts: undefined;
};

export type DraftAnnouncementFormData = {
  subject: string;
  message: string;
};

export enum AnnouncementScheduleType {
  Immediate = 'Immediate',
  Scheduled = 'Scheduled',
}

export type ScheduleAnnouncementFormData = {
  scheduleType: RadioGroupOption<AnnouncementScheduleType>;
  startDate?: string;
  startTime?: string;
  timeZone?: string;
};

export type AnnouncementFilters = Pick<
  PageSearchParams,
  'jobsiteIds' | 'announcementType' | 'announcementStatus' | 'search'
>;
