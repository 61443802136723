import React from 'react';
import { NewButton } from '@odin-labs/components';

import { getIcon } from 'utils/ui';

import { Form } from 'components/form';
import { JobsiteIncidentWorkerEditFormProps, JobsiteIncidentParticipantFormData } from './types';
import { getDefaultValues, getFormInputs, getJobsiteWorkerOptions } from './helpers/forms';

export function JobsiteIncidentEditWorkerForm(props: JobsiteIncidentWorkerEditFormProps): React.ReactElement {
  const { participantEdit, onCancel, onConfirm } = props;
  const formRef = React.useRef<HTMLFormElement>(null);
  const isAddition = participantEdit.editType === 'create';

  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const onSubmit = (data: JobsiteIncidentParticipantFormData): void => {
    onConfirm(
      {
        id: participantEdit.editType === 'create' ? new Date().toLocaleString() : participantEdit.id,
        selectedJobsiteWorker: participantEdit.jobsitesInfo.find(
          (j) => j.jobsiteWorkerId === data.jobsiteWorkerId.value,
        ).jobsiteWorker,
        participantType: data.participantType.value,
        changeType: participantEdit.changeType ?? (participantEdit.editType === 'create' ? 'created' : 'updated'),
        jobsitesInfo: participantEdit.jobsitesInfo,
      },
      participantEdit.editType,
    );
  };

  const submitForm = React.useCallback((): void => {
    formRef.current.requestSubmit();
  }, [formRef.current]);

  const onIsDirtyChange = React.useCallback((isDirty): void => setIsFormDirty(isDirty), [setIsFormDirty]);

  const jobsiteOptions = getJobsiteWorkerOptions(participantEdit);
  const formInputs = getFormInputs(participantEdit, jobsiteOptions);
  const defaultValues = getDefaultValues(participantEdit, jobsiteOptions);

  return (
    <div>
      <Form
        ref={formRef}
        inputs={formInputs}
        onIsDirtyChange={onIsDirtyChange}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      />
      <div className="odin-flex odin-justify-between odin-mt-6">
        <div>
          {isAddition && <NewButton icon={getIcon('fal fa-arrow-left')} text="Back" theme="naked" onClick={onCancel} />}
        </div>
        <div>
          <NewButton
            icon={getIcon(`fal ${isAddition ? 'fa-plus' : 'fa-check'}`)}
            text={isAddition ? 'Add Worker' : 'Update Worker'}
            onClick={submitForm}
            disabled={!isFormDirty}
          />
        </div>
      </div>
    </div>
  );
}
