import React from 'react';
import { FormDialog } from 'dialog';
import { getIcon } from 'utils/ui';
import { EditCCureClearanceFormData } from 'containers/jobsiteConfiguration/types';
import { getDefaultValues } from './AddCCureClearanceModal.forms';
import { getFormInputsHook } from './EditCCureClearanceModal.forms';
import { AddCCureClearanceModalProps } from './types';

export function AddCCureClearanceModal(props: AddCCureClearanceModalProps): React.ReactElement {
  const { isOpen, onCancel, onConfirm, cCureEnvironmentsOptions, cCureClearancesOptions } = props;

  const onSubmit = React.useCallback(
    (data: EditCCureClearanceFormData): void => {
      const { cCureEnvironment, cCureClearance, isDefault, isSelectable } = data;
      onConfirm?.({
        isDefault,
        isSelectable,
        cCureClearanceId: `new-${new Date().valueOf()}`,
        name: cCureClearance?.label,
        cCureClearanceObjectId: cCureClearance?.entity?.objectId,
        cCureEnvironment: {
          cCureEnvironmentId: cCureEnvironment.value,
          namespace: cCureEnvironment.label,
        },
      });
    },
    [onConfirm],
  );

  const formInputs = getFormInputsHook({ cCureEnvironmentsOptions, cCureClearancesOptions });
  const defaultValues = React.useMemo(
    () => getDefaultValues({ cCureClearance: null, cCureEnvironmentsOptions, cCureClearancesOptions }),
    [cCureEnvironmentsOptions, cCureClearancesOptions],
  );

  return (
    <FormDialog
      id="addCCureClearance"
      open={isOpen}
      setOpen={onCancel}
      title="Add Clearance"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Add Clearance"
      actionIcon={getIcon('fal fa-plus')}
    />
  );
}
