import cn from 'classnames';
import { FormInputTypes, getUpdateInputValueFunction, TypedFormInputs } from 'components/form';
import {
  FileChangeInput,
  JobsiteIncidentDocumentUpdateInput,
  JobsiteIncidentUpdateInput,
} from 'apollo/generated/client-operations';
import { DeepMap } from 'react-hook-form';
import {
  JobsiteIncident,
  EditJobsiteIncidentFormData,
  EditableIncidentDocument,
  EditableIncidentDocumentFile,
} from 'containers/jobsiteSafetyIncident/types';
import { JobsiteIncidentDocuments } from 'containers/jobsiteSafetyIncident/components/jobsiteIncidentDocuments';
import { tryFileCompression } from 'utils';

export const getDocumentsSectionInputs = ({
  jobsiteIncident,
}: {
  jobsiteIncident: JobsiteIncident;
}): TypedFormInputs<EditJobsiteIncidentFormData['documents']> => ({
  items: {
    element: FormInputTypes.CustomInput,
    elementProps: {
      customInput: JobsiteIncidentDocuments,
      ...{ jobsiteIncident },
    },
    layout: cn('odin-col-span-6'),
  },
});

export const getDocumentsSectionDefaultValues = (
  jobsiteIncident: JobsiteIncident,
): EditJobsiteIncidentFormData['documents'] => {
  return {
    items: jobsiteIncident?.documents?.edges.map(({ node }) => node) ?? [],
  };
};

const toFileChangeInput = async (file: EditableIncidentDocumentFile): Promise<FileChangeInput> => {
  const fileData = await tryFileCompression(file as File);
  return {
    changeType: file.changeType,
    fileId: file.fileId,
    fileInput: { uploadData: fileData, isPublic: false },
  };
};

export const toUpdateInput = async (
  document: EditableIncidentDocument,
): Promise<JobsiteIncidentDocumentUpdateInput> => {
  switch (document.changeType) {
    case 'created':
      return {
        createInput: {
          description: document.description,
          files: await Promise.all(document.files.filter((f) => f.changeType).map(toFileChangeInput)),
        },
      };
    case 'updated':
      return {
        updateInput: {
          jobsiteIncidentDocumentId: document.jobsiteIncidentDocumentId,
          description: document.description,
          files: await Promise.all(document.files.filter((f) => f.changeType).map(toFileChangeInput)),
        },
      };
    case 'removed':
      return {
        removeInput: {
          jobsiteIncidentDocumentId: document.jobsiteIncidentDocumentId,
        },
      };
    default:
      return null;
  }
};

type DocumentsSectionUpdateInput = Required<Pick<JobsiteIncidentUpdateInput, 'documentInputs'>>;

export const getDocumentsSectionUpdateInput = async (
  documents: EditJobsiteIncidentFormData['documents'],
  dirtyFields: DeepMap<EditJobsiteIncidentFormData['documents'], true>,
): Promise<DocumentsSectionUpdateInput> => {
  const getUpdateInputValue = getUpdateInputValueFunction(documents, dirtyFields);
  const itemsInputValue = getUpdateInputValue('items');
  return {
    documentInputs:
      itemsInputValue && (await Promise.all(itemsInputValue.filter((d) => d.changeType).map(toUpdateInput))),
  };
};
