import { gql } from '@apollo/client';
import { OBJECT_HISTORY_GRAPHQL_PROPS } from 'utils/queries';

export const GET_WORKER = gql`
    query GetWorker($workerId: ID!, $includeArchived: Boolean) {
        getWorker(workerId: $workerId, includeArchived: $includeArchived) {
            workerId
            middleInitial
            suffix
            addressLine1
            addressLine2
            addressCity
            addressState
            addressZipCode
            addressZipCode
            birthDate
            emergencyContactName
            emergencyContactPhone
            emergencyContactRelationship
            gender
            jobTitle
            primaryLanguage
            race
            isSkilled
            ssnLastFour
            trade
            unionAffiliation
            isVeteran
            quickCode
            isArchived
            ${OBJECT_HISTORY_GRAPHQL_PROPS}
            accessHistory {
                changeReason
                changeApprovedBy
                changeDate
                changeToAccess
                updatedBy
                banEndDate
                jobsiteName
            }
            profilePicture {
                downloadUrl
                uploadedAt
            }
            profilePictureCropped {
                downloadUrl
                uploadedAt
                fileId
                fileType
            }
            user {
                roles {
                    key
                    displayName
                    object {
                        objectId
                        objectName
                    }
                }
                userAccountId
                identity {
                    firstName
                    lastName
                    email
                    phoneNumber
                }
                hasAuth
                lastSession {
                    createdAt
                    lastActiveAt
                    sessionType
                }
            }
            workerComments {
                edges {
                    node {
                        workerCommentId
                        comment
                        jobsite {
                            name
                            jobsiteId
                        }
                        ${OBJECT_HISTORY_GRAPHQL_PROPS}
                    }
            }}
        }
    }
`;

export const UPDATE_JOBSITE_WORKER = gql`
  mutation UpdateJobsiteWorker($jobsiteWorkerId: ID!, $jobsiteWorkerInput: JobsiteWorkerInput!) {
    updateJobsiteWorker(jobsiteWorkerId: $jobsiteWorkerId, jobsiteWorkerInput: $jobsiteWorkerInput) {
      orientationDate
    }
  }
`;
