import classNames from 'classnames/bind';
import React, { ChangeEventHandler, ReactElement, forwardRef } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select2 from 'react-select2-wrapper';

import styles from './scss/select.scss';
import { OdinSelectProps } from './types';

const cx = classNames.bind(styles);

export function OdinSelect({
  label,
  name,
  data,
  value,
  defaultValue,
  multiple,
  select2Options,
  error,
  onChange,
  placeholder,
  tags,
  onChangeHook,
}: OdinSelectProps): ReactElement {
  const onChangeHandler: ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChangeHook) {
      return onChangeHook(event.target.value);
    }

    if (onChange) {
      return onChange(event.target.value);
    }

    return null;
  };

  const dataPassed =
    value && tags && data.filter((dv) => dv.id === value).length === 0 ? [...data, { id: value, text: value }] : data;

  return (
    <FormGroup className={cx('odin-select', { 'odin-select--error': !!error })}>
      {label ? <Label for={name}>{label}</Label> : null}
      <Select2
        name={name}
        data={dataPassed}
        defaultValue={defaultValue}
        value={onChangeHandler ? value : null}
        onSelect={onChangeHandler}
        multiple={multiple}
        options={{
          placeholder,
          tags,
          ...select2Options,
        }}
      />
      {error ? <div className={cx('mt-2', { 'invalid-feedback': error, 'd-block': error })}>{error}</div> : null}
    </FormGroup>
  );
}

export const WrappedOdinSelect = forwardRef((props: any, ref: any): ReactElement => {
  return <OdinSelect {...props} forwardedRef={ref} />;
});
