import React from 'react';
import cn from 'classnames';
import QRCode from 'react-qr-code';
import { ParticipantType } from 'apollo/generated/client-operations';
import { Avatar, Badge } from '@odin-labs/components';
import { getCurrentDomain, getInitialsForUser } from 'utils';
import { Worker } from 'containers/worker/types';

export const classes = {
  container: cn('odin-flex odin-items-center odin-space-x-7 print:odin-break-inside-avoid'),
  textAndBadgesHolder: cn('odin-flex odin-flex-col odin-grow'),
  text: cn('odin-mb-2'),
  badgesHolder: cn(''),
};

export type WorkerReportTitleProps = {
  worker: Worker;
  participantType: ParticipantType;
};

export function WorkerReportTitle(props: WorkerReportTitleProps): React.ReactElement {
  const { worker, participantType } = props;
  const { user } = worker;
  const { firstName, lastName } = user.identity;
  const qrUrl = `${getCurrentDomain()}/worker/${worker?.workerId}`;

  return (
    <div className={classes.container}>
      <Avatar
        size="3xl"
        src={worker.profilePicture?.downloadUrl}
        placeholder={getInitialsForUser(worker.user)}
        objectFit="cover"
      />
      <div className={classes.textAndBadgesHolder}>
        <h1 className={classes.text}>
          {firstName} {lastName}
        </h1>
        <div>
          <Badge color="blue" size="lg" text={participantType.toString()} />
        </div>
      </div>
      <QRCode value={qrUrl} size={72} />
    </div>
  );
}
