import moment from 'moment';
import { SelectOptionElement } from '@odin-labs/components';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { AddJobsiteIncidentFormData } from 'containers/jobsiteSafety/types';
import { getIcon } from 'utils/ui';
import { getCurrentISOFormattedDate } from 'utils';
import { timeValidation } from 'utils/validation';
import { incidentTypeOptions } from './utils';

export const getFormInputs = (jobsiteOptions: SelectOptionElement[]): TypedFormInputs<AddJobsiteIncidentFormData> => ({
  name: {
    element: FormInputTypes.OdinField,
    label: 'Incident Name',
    validation: {
      required: true,
    },
    layout: GridColSpan.SmSpan3,
  },
  incidentType: {
    element: FormInputTypes.OdinSelect,
    label: 'Incident Type',
    elementProps: {
      options: incidentTypeOptions,
      multiple: true,
      clearToNull: true,
    },
    validation: {
      required: true,
    },
    layout: GridColSpan.SmSpan3,
  },
  incidentDate: {
    element: FormInputTypes.NewDatePicker,
    label: 'Date of Incident',
    elementProps: {
      maxDate: moment(),
      // minDate: moment().add(-1, 'week'),
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    validation: {
      required: true,
    },
    layout: GridColSpan.SmSpan3,
  },
  incidentTime: {
    element: FormInputTypes.OdinField,
    label: 'Time of Incident',
    elementProps: {
      placeholder: 'hh:mm',
      fieldType: 'time',
      showDefaultIcon: true,
    },
    validation: {
      pattern: timeValidation,
      required: true,
    },
    layout: GridColSpan.SmSpan3,
  },
  jobsiteId: {
    element: FormInputTypes.OdinSelect,
    label: 'Jobsite',
    elementProps: {
      placeholder: 'Select jobsite',
      options: jobsiteOptions,
      icon: getIcon('fal fa-briefcase odin-text-odin-primary'),
      disabled: (jobsiteOptions?.length ?? 0) < 2,
    },
    validation: {
      required: true,
    },
    layout: GridColSpan.SmSpan3,
  },
  exactLocation: {
    element: FormInputTypes.OdinField,
    label: 'Exact Location',
    validation: {
      required: true,
    },
    layout: GridColSpan.SmSpan3,
  },
  summary: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Description',
    layout: GridColSpan.SpanFull,
  },
});

export const getDefaultValues = (jobsiteOptions: SelectOptionElement[]): AddJobsiteIncidentFormData => ({
  name: '',
  incidentType: null,
  summary: '',
  jobsiteId: jobsiteOptions?.length === 1 ? jobsiteOptions[0] : null,
  exactLocation: '',
  incidentDate: getCurrentISOFormattedDate(),
  incidentTime: '',
});
