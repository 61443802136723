import React from 'react';
import cn from 'classnames';
import { ReportSectionTitle } from 'containers/jobsiteSafetyIncident/print/components/reportSectionTitle';
import { JobsiteIncident, JobsiteIncidentDocument } from 'containers/jobsiteSafetyIncident/print/types';
import { JobsiteIncidentReportDocument } from './JobsiteIncidentReportDocument';

const classes = {
  container: '',
  documentsHeader: cn('odin-mt-9'),
  documentsText: cn('odin-text-lg odin-leading-6 odin-font-medium odin-text-gray-900'),
  documentsCount: cn('odin-ml-3'),
  documentsContainer: cn('odin-h-fit odin-border-separate odin-border-spacing-6 -odin-mx-6'),
};

export type JobsiteIncidentReportDocumentsProps = {
  jobsiteIncident: JobsiteIncident;
  singleItemPerRow?: boolean;
};

export function JobsiteIncidentReportDocuments(props: JobsiteIncidentReportDocumentsProps): React.ReactElement {
  const { jobsiteIncident, singleItemPerRow } = props;
  const documents = jobsiteIncident.documents?.edges.map(({ node }) => node);

  const documentsByRows: Array<JobsiteIncidentDocument[]> = documents.reduce(
    (result, item, index): JobsiteIncidentDocument[] => {
      if (singleItemPerRow || index % 2 === 0) {
        result.push([item]);
      } else {
        result[result.length - 1].push(item);
      }
      return result;
    },
    [],
  );

  const documentRows = documentsByRows.map((rowDocs) => (
    <tr key={rowDocs[0].jobsiteIncidentDocumentId}>
      {rowDocs.map(
        (document): React.ReactElement => (
          <td key={document.jobsiteIncidentDocumentId}>
            <JobsiteIncidentReportDocument document={document} singleItemPerRow={singleItemPerRow} />
          </td>
        ),
      )}
    </tr>
  ));

  return (
    <div className={classes.container}>
      <ReportSectionTitle title="Attachments" count={documents?.length} />
      {/* use table here as fragmentation is not supported for grid */}
      <table className={classes.documentsContainer}>
        <tbody>{documentRows}</tbody>
      </table>
    </div>
  );
}
