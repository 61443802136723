import React from 'react';
import { ApolloError, QueryResult, useQuery } from '@apollo/client';
import { Jobsite, QueryGetMemberJobsitesArgs } from 'apollo/generated/client-operations';
import { GET_MEMBER_JOBSITES } from 'graphql/server/queries/jobsite';
import { QueryGetMemberJobsitesResponse } from 'graphql/server/queries/types';
import { AuthContext } from 'auth';

type QResult = QueryResult<QueryGetMemberJobsitesResponse, QueryGetMemberJobsitesArgs>;

export type UserJobsitesData = {
  data: Jobsite[];
  loading: QResult['loading'];
  error: ApolloError;
  refetch: QResult['refetch'];
};

export const useUserJobsitesData = (): UserJobsitesData => {
  const { currentUser: user } = React.useContext(AuthContext);
  const userAccountId = user?.userAccountId;

  const { data, loading, error, refetch } = useQuery<QueryGetMemberJobsitesResponse, QueryGetMemberJobsitesArgs>(
    GET_MEMBER_JOBSITES,
    {
      variables: {
        memberUserAccountId: userAccountId,
      },
      skip: !userAccountId,
      fetchPolicy: 'no-cache',
    },
  );

  const userJobsites = data?.getMemberJobsites?.jobsiteMembers
    ?.map((jobsiteMember) => jobsiteMember.jobsite)
    .filter((j) => j.featuresModule?.safetyIncidentsEnabled);

  return { data: userJobsites, loading, error, refetch };
};
