import { Column, BadgeCell, BadgeCellComponentProps } from '@odin-labs/components';

import {
  DateTimeCell,
  JobsiteAccessDefaultCell,
  DirectionCell,
  JobsiteCell,
  statusColor,
  statusText,
  MethodCell,
} from 'containers/jobsiteSafetyIncident/print/helpers/cells';

import { GetJobsiteSafetyIncidentWorkerAccessEventsQuery } from 'apollo/generated/client-operations';

export type WorkerAccessEvent =
  GetJobsiteSafetyIncidentWorkerAccessEventsQuery['getWorker']['jobsiteAccessEvents']['edges'][0]['node'];
export type WorkerAccessEventColumn = Column<WorkerAccessEvent>;

export const getColumns = (): WorkerAccessEventColumn[] => [
  {
    Header: 'Jobsite',
    accessor: (workerAccessEvent: WorkerAccessEvent): string => workerAccessEvent.jobsite?.name ?? '',
    Cell: JobsiteCell,
  },
  {
    Header: 'Date & Time',
    accessor: (workerAccessEvent: WorkerAccessEvent): WorkerAccessEvent => workerAccessEvent,
    Cell: DateTimeCell,
  },
  {
    Header: 'Direction',
    accessor: 'direction',
    Cell: DirectionCell,
  },
  {
    Header: 'Method',
    accessor: (workerAccessEvent: WorkerAccessEvent): WorkerAccessEvent => workerAccessEvent,
    Cell: MethodCell,
  },
  {
    Header: 'Status',
    Cell: BadgeCell,
    componentProps: ({ status }: WorkerAccessEvent): BadgeCellComponentProps => ({
      text: statusText[status],
      color: statusColor[status],
      withDot: true,
      background: 'transparent',
      size: 'lg',
    }),
  },
  {
    Header: 'Card Number',
    accessor: 'credentialId',
    Cell: JobsiteAccessDefaultCell,
  },
];
