import React from 'react';
import cn from 'classnames';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  Column,
  FilterItemConfig,
  getFaIcon,
} from '@odin-labs/components';
import { Jobsite, JobsiteFiltersOptions, JobsitesFilters } from 'containers/jobsites/types';
import { getPrettyFormattedUtcDate } from 'utils/dates';

export type JobsiteColumn = Column<Jobsite>;

const JobsiteIcon = getFaIcon({ icon: faBriefcase, className: cn('odin-text-base odin-text-odin-primary') });

export const getColumns = (): JobsiteColumn[] => [
  {
    id: 'jobsite',
    Header: 'Name',
    accessor: 'name',
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size="xs" type="rounded" hideDetails />,
    componentProps: (jobsite: Jobsite): AvatarCellComponentProps => ({
      icon: JobsiteIcon,
      type: 'rounded',
      placeholder: (jobsite.name.substring(0, 2) || '??').toUpperCase(),
      objectFit: 'cover',
      size: 'xs',
    }),
  },
  {
    id: 'client',
    Header: 'Client',
    accessor: (jobsite: Jobsite): string => {
      return jobsite?.developer?.organization?.name;
    },
  },
  {
    id: 'location',
    Header: 'Location',
    accessor: (jobsite: Jobsite): string => {
      const { addressCity, addressState } = jobsite ?? {};
      return [addressCity, addressState].filter(Boolean).join(', ');
    },
  },
  {
    id: 'startDate',
    Header: 'Start Date',
    accessor: (jobsite: Jobsite): string => getPrettyFormattedUtcDate(jobsite?.startDate),
  },
  {
    id: 'endDate',
    Header: 'End Date',
    accessor: (jobsite: Jobsite): string => getPrettyFormattedUtcDate(jobsite?.endDate),
  },
];

export const getFilterItems = ({
  filtersOptions,
  developerIds,
  cities,
  states,
  search,
}: {
  filtersOptions: JobsiteFiltersOptions;
  developerIds: string[];
  cities: string[];
  states: string[];
  search: string;
}): Record<keyof JobsitesFilters, FilterItemConfig> => ({
  developerIds: {
    header: 'Client',
    type: 'dropdown',
    defaultValue: developerIds,
    componentProps: {
      options: filtersOptions?.developers ?? [],
      isMulti: true,
      allowSearch: false,
    },
  },
  cities: {
    header: 'City',
    type: 'dropdown',
    defaultValue: cities,
    componentProps: {
      options: filtersOptions?.cities ?? [],
      isMulti: true,
      allowSearch: false,
    },
  },
  states: {
    header: 'State',
    type: 'dropdown',
    defaultValue: states,
    componentProps: {
      options: filtersOptions?.states ?? [],
      isMulti: true,
      allowSearch: false,
    },
  },
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});
