import React from 'react';
import { useHistory } from 'react-router';
import { Filter, SortingRule, Table, TableContainer } from '@odin-labs/components';
import { paginationSizePerPage as limit } from 'utils/constants';
import { JobsiteIncident, JobsiteSafetyIncidentsFilters, JobsiteSafetyTabProps } from 'containers/jobsiteSafety/types';
import {
  getColumns,
  getFilterItems,
  JobsiteIncidentColumn,
  orderByFields,
} from 'containers/jobsiteSafety/helpers/tables';
import { siteSafetyUrl } from 'containers/jobsiteSafety/helpers/utils';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { SortOrder, useQueryOrderBy } from 'utils/useQueryOrderBy';
import { GetJobsiteIncidentsOrderByField } from 'apollo/generated/client-operations';
import { useJobsiteSafetyIncidentsData } from 'containers/jobsiteSafety/data/useJobsiteSafetyIncidentsData';

export function JobsiteSafetyIncidents(props: JobsiteSafetyTabProps): React.ReactElement {
  const { onTabApiChange } = props;
  const history = useHistory();
  const {
    page,
    jobsiteIds,
    orderBy: defaultSortField,
    orderByDesc: isDescending,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams({ updateJobsiteSelection: true });

  const { tableSortInfo, orderBy, setNewOrderBy } = useQueryOrderBy<string, GetJobsiteIncidentsOrderByField>(
    defaultSortField,
    isDescending,
    (tableField: string): GetJobsiteIncidentsOrderByField => orderByFields[tableField],
  );

  const tableSortBy: Array<SortingRule<string>> = tableSortInfo?.dataField
    ? [{ id: tableSortInfo.dataField, desc: tableSortInfo.order === 'desc' }]
    : [];

  const { loading, jobsiteIncidents, jobsiteIncidentsCount, jobsiteIncidentsCountByJobsite, tabsCount, refetchData } =
    useJobsiteSafetyIncidentsData({ page, jobsiteIds, isUrlLoading, orderBy });

  React.useEffect(() => onTabApiChange({ refetchData, tabsCount }), [refetchData, tabsCount]);

  const navigateToIncidentEditPage = React.useCallback(
    (incidentId: string): void => history.push(`${siteSafetyUrl}/incident/${incidentId}`),
    [history],
  );

  const navigateToIncidentPrintPage = React.useCallback(
    (incidentId: string): void => {
      history.push(`${siteSafetyUrl}/incident/${incidentId}/print`);
    },
    [history],
  );

  const columns = React.useMemo<JobsiteIncidentColumn[]>(
    () => getColumns({ navigateToIncidentEditPage, navigateToIncidentPrintPage }),
    [navigateToIncidentEditPage, navigateToIncidentPrintPage],
  );

  const filterItems = React.useMemo(
    () => getFilterItems({ jobsiteIncidentsCountByJobsite, jobsiteIds }),
    [jobsiteIncidentsCountByJobsite, jobsiteIds],
  );

  const onFiltersChangeHandler = (changedFilters: Partial<JobsiteSafetyIncidentsFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = React.useCallback(
    ({ data: jobsiteIncident }: { data: JobsiteIncident }): void => navigateToIncidentPrintPage(jobsiteIncident.id),
    [navigateToIncidentPrintPage],
  );

  const onPageChangeHandler = React.useCallback(
    (_pageSize: number, pageIndex: number): void => {
      updateUrl({
        page: pageIndex ? pageIndex + 1 : null,
      });
    },
    [updateUrl],
  );

  const onSortByChangeHandler = (sortBy: Array<SortingRule<JobsiteIncident>>): void => {
    const [firstSortBy] = sortBy ?? [];
    const sortField = firstSortBy?.id ?? null;
    const sortOrder: SortOrder = firstSortBy?.desc ? 'desc' : 'asc';
    const newOrderBy = sortField && { field: sortField, order: sortOrder };
    setNewOrderBy(newOrderBy);
    updateUrl({
      orderBy: sortField,
      orderByDesc: sortField && sortOrder === 'desc' ? true : null,
    });
  };

  const pageCount = Math.ceil(jobsiteIncidentsCount / limit);

  return (
    <TableContainer>
      <Filter items={filterItems} loading={loading} onChange={onFiltersChangeHandler} />
      <Table
        loading={loading}
        columns={columns}
        data={jobsiteIncidents}
        initialState={{ sortBy: tableSortBy, pageSize: limit }}
        pageCount={pageCount}
        pageIndex={page}
        remote
        onRowClick={onRowClickHandler}
        onPageChange={onPageChangeHandler}
        onSortByChange={onSortByChangeHandler}
        rowClassName="!md:odin-h-20"
      />
    </TableContainer>
  );
}
