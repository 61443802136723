import { Label, Table, TableContainer } from '@odin-labs/components';
import cn from 'classnames';
import { useUpdateQueryStatus } from 'containers/jobsiteSafetyIncident/print/helpers/useUpdateQueryStatus';
import { getJobsiteIncidentAccessEventsInterval } from 'containers/jobsiteSafetyIncident/print/helpers/utils';
import { ReportSectionTitle } from 'containers/jobsiteSafetyIncident/print/components/reportSectionTitle';
import { JobsiteIncident } from 'containers/jobsiteSafetyIncident/print/types';
import React from 'react';
import { momentFormatter } from 'utils/dates';
import { getIcon } from 'utils/ui';
import { useGetJobsiteAccessSessionsQuery } from 'apollo/generated/client-operations';
import { getColumns, JobsiteWorkerSessionColumn } from './helpers/tables';

export type JobsiteReportWorkerSessionsProps = {
  jobsiteIncident: JobsiteIncident;
};

export function JobsiteReportWorkerSessions(props: JobsiteReportWorkerSessionsProps): React.ReactElement {
  const { jobsiteIncident } = props;

  const { startMoment, endMoment } = getJobsiteIncidentAccessEventsInterval(jobsiteIncident.incidentDateTime);

  const jobsiteIds = jobsiteIncident.participants.edges.map(
    ({ node }) => node.selectedJobsiteWorker.jobsiteContractor?.jobsite.jobsiteId,
  );
  const workerIds = jobsiteIncident.participants.edges.map(
    ({ node }) => node.selectedJobsiteWorker.contractorWorker?.worker.workerId,
  );

  const { data, error, loading } = useGetJobsiteAccessSessionsQuery({
    variables: {
      userJobsiteAccessSessionsInput: {
        jobsiteIds,
        workerIds,
        startDate: startMoment?.toDate(),
        endDate: endMoment?.toDate(),
        paginationInput: {
          limit: 100,
          offset: 0,
        },
      },
    },
    fetchPolicy: 'no-cache',
    skip: !workerIds.length || !jobsiteIds.length || !jobsiteIncident?.incidentDateTime,
  });

  useUpdateQueryStatus('jobsiteReportWorkerSessions', { loading, error });

  const workerSessions = data?.getCurrentSession.user.jobsiteAccessSessions.edges.map(({ node }) => node);

  const columns = React.useMemo<JobsiteWorkerSessionColumn[]>(() => getColumns(), []);
  const workerSessionsCount = workerSessions?.length ?? 0;
  const datesText = [startMoment.format(momentFormatter), endMoment.format(momentFormatter)].join(' - ');

  return (
    <TableContainer>
      <ReportSectionTitle title="Sessions" count={workerSessionsCount} dates={datesText}>
        <Label
          size="lg"
          text={`${workerIds.length} Workers`}
          icon={getIcon(cn('fal fa-user-hard-hat odin-text-odin-primary odin-mr-1.5'))}
        />
      </ReportSectionTitle>
      <Table loading={loading} columns={columns} data={workerSessions} remote disableGlobalFilter disableSortBy />
    </TableContainer>
  );
}
