import classNames from 'classnames/bind';
import { HeaderProps } from 'components/header/types';
import React, { ReactElement, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import styles from './scss/header.scss';

const cx = classNames.bind(styles);

export function Header({
  pretitle,
  title,
  subtitle,
  tabs,
  search = '',
  currentPathname,
  leftContent,
  rightContent,
  onBackPressed,
  textAlign = 'left',
}: HeaderProps): ReactElement {
  return (
    <div className={cx('header', 'mt-md-3')}>
      <div className={cx('header-body')}>
        <Row className={cx('align-items-center')}>
          <Col>
            {onBackPressed ? (
              <div onClick={onBackPressed} className={cx('header__back', 'mb-2')}>
                <i className={cx('fe', 'fe-chevron-left')} /> Back
              </div>
            ) : null}
            {pretitle ? (
              <h6
                className={cx('header-pretitle', {
                  'text-left': textAlign === 'left',
                  'text-center': textAlign === 'center',
                  'text-right': textAlign === 'right',
                })}
              >
                {pretitle}
              </h6>
            ) : null}
            <h1
              className={cx('header-title', {
                'text-left': textAlign === 'left',
                'text-center': textAlign === 'center',
                'text-right': textAlign === 'right',
              })}
            >
              {title}
              {leftContent}
            </h1>
            {subtitle ? (
              <h3
                className={cx('header-subtitle', {
                  'text-left': textAlign === 'left',
                  'text-center': textAlign === 'center',
                  'text-right': textAlign === 'right',
                })}
              >
                {subtitle}
              </h3>
            ) : null}
          </Col>
          {rightContent ? <div className="col-auto">{rightContent}</div> : null}
        </Row>
        {tabs ? (
          <Row className={cx('align-items-center')}>
            <Col>
              <ul className={cx('nav nav-tabs nav-overflow header-tabs')}>
                {tabs.map((tab) => {
                  const linkContent = (
                    <>
                      {tab.title}
                      {tab.badge ? (
                        <span
                          className={cx('badge', 'badge-pill', 'badge-soft-secondary', 'ml-2', 'header__badge-pill')}
                        >
                          {tab.badge}
                        </span>
                      ) : null}
                    </>
                  );
                  const link = tab.link ? (
                    <Link
                      to={`${tab.link}${search}`}
                      replace
                      className={cx('nav-link', { active: tab.link === currentPathname })}
                    >
                      {linkContent}
                    </Link>
                  ) : (
                    <a
                      href="#"
                      className={cx('nav-link', { active: tab.selected })}
                      onClick={(e: SyntheticEvent): void => {
                        e.preventDefault();
                        tab.onClick();
                      }}
                    >
                      {linkContent}
                    </a>
                  );
                  return (
                    <li className={cx('nav-item')} key={tab.link || tab.title}>
                      {link}
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        ) : null}
      </div>
    </div>
  );
}
