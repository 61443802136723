import React from 'react';
import { Moment } from 'moment';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import {
  BadgeCell,
  BadgeCellComponentProps,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItems,
  NewMenuItemProps,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import {
  DateTimeCell,
  DateTimeCellData,
  DirectionGatewayCell,
  locationStatusColor,
  locationStatusText,
  statusColor,
  statusText,
} from 'containers/jobsiteAccessActivity/helpers/cells';
import { eventStatusOptions, rejectionReasonDescriptions } from 'containers/jobsiteAccessActivity/helpers/tables';
import { AuthUser } from 'auth/types';
import { to } from 'acl';
import moment from 'moment-timezone';
import { ensureNonEmptyItems } from 'utils';
import { JobsiteWorker } from 'containers/worker/types';
import { JobsiteAccessEvent, WorkerJobsiteAccessEventsFilters, WorkerJobsiteAccessEventsFiltersOptions } from './types';

export type JobsiteAccessEventColumn = Column<JobsiteAccessEvent>;

const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });

export const getColumns = ({
  user,
  openArchiveJobsiteAccessEventsModal,
  jobsiteWorkers,
}: {
  user: AuthUser;
  openArchiveJobsiteAccessEventsModal: (accessEvent: JobsiteAccessEvent) => void;
  jobsiteWorkers: Array<JobsiteWorker>;
}): JobsiteAccessEventColumn[] => {
  const canArchiveJobsiteAccessEvent = user.isAllowed(to.archiveAccessEvents);
  const isActionsButtonVisible = canArchiveJobsiteAccessEvent;

  const isArchivingAvailable = (jobsiteAccessEvent: JobsiteAccessEvent): boolean => {
    const { jobsiteId } = jobsiteAccessEvent.jobsite;
    return (
      jobsiteWorkers
        ?.filter((jobsiteWorker) => jobsiteWorker.jobsiteContractor.jobsite.jobsiteId === jobsiteId)
        ?.flatMap((jobsiteWorker) => jobsiteWorker.jobsiteContractor?.jobsite.modules)
        // eslint-disable-next-line no-underscore-dangle
        ?.some((module) => module.__typename === 'JobsiteFeaturesModule' && module.archivingAccessEventsEnabled)
    );
  };

  return ensureNonEmptyItems<JobsiteAccessEventColumn>([
    {
      id: 'jobsiteContractor',
      Header: 'Jobsite / Contractor',
      accessor: (): string[] => [],
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (jobsiteAccessEvent: JobsiteAccessEvent): ChipsCellComponentProps => ({
        chips: [
          {
            text: jobsiteAccessEvent.jobsite?.name,
            secondaryText: jobsiteAccessEvent.jobsiteWorker?.contractorWorker.contractor.organization.name,
            isActive: jobsiteAccessEvent.jobsiteWorker?.currentAccess.isAllowed,
          },
        ],
      }),
    },
    {
      id: 'dateTime',
      Header: 'Date / Time',
      accessor: (jobsiteAccessEvent: JobsiteAccessEvent): DateTimeCellData => {
        const { eventOccurredAt, timeZone, overnightSession: isOvernight } = jobsiteAccessEvent ?? {};
        const timeZoneName = moment.tz(eventOccurredAt, timeZone).zoneAbbr();
        return eventOccurredAt && { date: new Date(eventOccurredAt), timeZoneName, isOvernight };
      },
      Cell: DateTimeCell,
    },
    {
      id: 'gateway',
      Header: 'Direction / Gateway',
      accessor: (jobsiteAccessEvent: JobsiteAccessEvent): JobsiteAccessEvent => jobsiteAccessEvent,
      Cell: DirectionGatewayCell,
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: BadgeCell,
      componentProps: ({ status, rejectionReason }: JobsiteAccessEvent): BadgeCellComponentProps => ({
        text: statusText[status],
        supportingText: rejectionReasonDescriptions[rejectionReason],
        color: statusColor[status],
        size: 'lg',
      }),
    },
    {
      id: 'locationStatus',
      Header: 'Location',
      Cell: BadgeCell,
      componentProps: ({ locationStatus }: JobsiteAccessEvent): BadgeCellComponentProps => ({
        text: locationStatusText[locationStatus],
        color: locationStatusColor[locationStatus],
        size: 'lg',
      }),
    },
    {
      id: 'cardNumber',
      Header: 'Card Number',
      accessor: (jobsiteAccessEvent: JobsiteAccessEvent & { credentialId?: string }): string =>
        jobsiteAccessEvent?.credentialId,
    },
    isActionsButtonVisible && {
      Header: '',
      id: 'actions',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (jobsiteAccessEvent: JobsiteAccessEvent): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems: ensureNonEmptyItems<NewMenuItemProps>([
          canArchiveJobsiteAccessEvent && {
            icon: TrashIcon,
            text: 'Archive Access Event',
            theme: 'danger',
            onClick: (): void => openArchiveJobsiteAccessEventsModal(jobsiteAccessEvent),
            disabled: !isArchivingAvailable(jobsiteAccessEvent),
          },
        ]),
      }),
    },
  ]);
};

export const getFilterItems = ({
  filtersOptions,
  jobsiteIds,
  contractorIds,
  gatewayIds,
  rejectionReasons,
  locationStatuses,
  eventStatus,
  startDate,
  startTime,
  endDate,
  endTime,
}: {
  filtersOptions: WorkerJobsiteAccessEventsFiltersOptions;
  jobsiteIds: string[];
  contractorIds: string[];
  gatewayIds: string[];
  rejectionReasons: string[];
  locationStatuses: string[];
  eventStatus: string;
  startDate: Moment | string;
  startTime: string;
  endDate: Moment | string;
  endTime: string;
}): FilterItems<WorkerJobsiteAccessEventsFilters> => ({
  startDate: {
    header: '',
    type: 'datePicker',
    defaultValue: startDate,
  },
  startTime: {
    header: '',
    type: 'timePicker',
    defaultValue: startTime,
  },
  endDate: {
    header: '',
    type: 'datePicker',
    defaultValue: endDate,
  },
  endTime: {
    header: '',
    type: 'timePicker',
    defaultValue: endTime,
    isLineBreaker: true,
  },
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options: filtersOptions?.jobsites ?? [],
    },
  },
  contractorIds: {
    header: 'Contractor',
    type: 'dropdown',
    defaultValue: contractorIds,
    componentProps: {
      options: filtersOptions?.contractors ?? [],
      isVirtual: true,
    },
  },
  gatewayIds: {
    header: 'Gateway',
    type: 'dropdown',
    defaultValue: gatewayIds,
    componentProps: {
      options: filtersOptions?.gateways ?? [],
    },
  },
  eventStatus: {
    header: 'Status',
    type: 'dropdown',
    defaultValue: eventStatus,
    componentProps: {
      options: eventStatusOptions,
      isMulti: false,
    },
  },
  rejectionReasons: {
    header: 'Rejection Reason',
    type: 'dropdown',
    defaultValue: rejectionReasons,
    componentProps: {
      options: filtersOptions?.rejectionReasons ?? [],
    },
  },
  locationStatuses: {
    header: 'Location',
    type: 'dropdown',
    defaultValue: locationStatuses,
    componentProps: {
      options: filtersOptions?.locationStatuses ?? [],
    },
  },
});
