import { Filter, SortingRule, Table, TableContainer } from '@odin-labs/components';
import { to } from 'acl';
import { ApolloError } from '@apollo/client';
import {
  GetUsersOrderByField,
  useGetContractorsQuery,
  useGetUsersQuery,
  UserAssignmentType,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { LoadingError } from 'components/loadingError';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { Role } from 'graphql/server/queries/types';
import React from 'react';
import { useHistory } from 'react-router';
import { useQueryOrderBy } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { SortOrder } from 'utils/useQueryOrderBy';
import { getColumns, getFilterItems, orderByFields } from './helpers/tables';
import { User, UsersFilters } from './types';

export type UsersTabApi = {
  refetchData: () => void;
  jobsiteUsersCount: number;
  contractorUsersCount: number;
};

export type UsersTabProps = {
  userAssignmentType: UserAssignmentType;
  onTabApiChange: (api: UsersTabApi) => void;
  userRoles: Role[];
  initialJobsiteIds?: string[];
};

export function UsersTab(props: UsersTabProps): React.ReactElement {
  const { userAssignmentType, userRoles, onTabApiChange, initialJobsiteIds } = props;
  const {
    page,
    jobsiteIds: queryStringJobsiteIds,
    contractorIds,
    roleKeys,
    search,
    orderBy: defaultSortField,
    orderByDesc: isDescending,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams({ updateJobsiteSelection: true, includeAllJobsites: true });
  const jobsiteIds = !queryStringJobsiteIds?.length && initialJobsiteIds ? initialJobsiteIds : queryStringJobsiteIds;

  const offset = page * limit;

  const { tableSortInfo, orderBy, setNewOrderBy } = useQueryOrderBy<string, GetUsersOrderByField>(
    defaultSortField,
    isDescending,
    (tableField: string): GetUsersOrderByField => orderByFields[tableField],
  );

  const tableSortBy: Array<SortingRule<string>> = tableSortInfo?.dataField
    ? [{ id: tableSortInfo.dataField, desc: tableSortInfo.order === 'desc' }]
    : [];

  const history = useHistory();
  const { currentUser: user } = React.useContext(AuthContext);
  const notAllowed = !user.isAllowed(to.queryForUserData);

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
    refetch: refetchUsers,
  } = useGetUsersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      usersInput: {
        paginationInput: { limit, offset },
        userAssignmentType,
        contractorIds,
        jobsiteIds,
        userRoleKeys: roleKeys,
        search,
        orderBy,
      },
    },
    skip: isUrlLoading || notAllowed,
  });

  const {
    data: contractorsData,
    loading: contractorsLoading,
    error: contractorsError,
    refetch: refetchContractors,
  } = useGetContractorsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: isUrlLoading || notAllowed || userAssignmentType === UserAssignmentType.Jobsite,
  });
  const contractors = contractorsData?.getCurrentSession.user.contractors.edges.map(({ node }) => node);

  const {
    jobsites,
    loading: jobsitesLoading,
    error: jobsitesError,
    refetch: refetchJobsites,
  } = useAvailableJobsites({ includeAllJobsites: true });

  const availableJobsites = React.useMemo(() => {
    return initialJobsiteIds?.length ? jobsites?.filter((j) => initialJobsiteIds.includes(j.jobsiteId)) : jobsites;
  }, [jobsites, initialJobsiteIds]);

  const { edges, jobsiteUsersCount, contractorUsersCount } = usersData?.getCurrentSession.user.users ?? {};
  const users = edges?.map(({ node }) => node);
  const totalCount =
    (userAssignmentType === UserAssignmentType.Contractor ? contractorUsersCount : jobsiteUsersCount) ?? 0;
  const pageCount = Math.ceil(totalCount / limit);

  const refetchData = React.useCallback(() => {
    refetchUsers();
    switch (userAssignmentType) {
      case UserAssignmentType.Jobsite:
        refetchJobsites();
        break;
      case UserAssignmentType.Contractor:
        refetchContractors();
        break;
      default:
        break;
    }
  }, [refetchUsers, refetchJobsites, refetchContractors, userAssignmentType]);

  React.useEffect(
    () => onTabApiChange({ refetchData, jobsiteUsersCount, contractorUsersCount }),
    [refetchData, jobsiteUsersCount, contractorUsersCount],
  );

  const columns = React.useMemo(() => getColumns(), []);
  const filterItems = React.useMemo(
    () =>
      getFilterItems({
        userAssignmentType,
        jobsites: availableJobsites,
        contractors,
        roles: userRoles,
        jobsiteIds,
        contractorIds,
        roleKeys,
        search,
      }),
    [userAssignmentType, availableJobsites, contractors, userRoles, jobsiteIds, contractorIds, roleKeys, search],
  );

  const loading: boolean = usersLoading || jobsitesLoading || contractorsLoading;
  const error: ApolloError = usersError || jobsitesError || contractorsError;

  if (error) {
    return <LoadingError error={error} />;
  }

  const onSortByChangeHandler = (sortBy: Array<SortingRule<User>>): void => {
    const [firstSortBy] = sortBy ?? [];
    const sortField = firstSortBy?.id ?? null;
    const sortOrder: SortOrder = firstSortBy?.desc ? 'desc' : 'asc';
    const newOrderBy = sortField && { field: sortField, order: sortOrder };
    setNewOrderBy(newOrderBy);
    updateUrl({
      orderBy: sortField,
      orderByDesc: sortField && sortOrder === 'desc' ? true : null,
    });
  };

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<UsersFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = ({ data: rowUser }: { data: User }): void => {
    history.push(`/worker/${rowUser?.worker.workerId}/user-roles`);
  };

  return (
    <TableContainer>
      <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
      <Table
        loading={loading}
        columns={columns}
        data={users}
        initialState={{ sortBy: tableSortBy, pageSize: limit }}
        pageCount={pageCount}
        pageIndex={page}
        remote
        enablePagination
        onRowClick={onRowClickHandler}
        onPageChange={onPageChangeHandler}
        onSortByChange={onSortByChangeHandler}
      />
    </TableContainer>
  );
}
