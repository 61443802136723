import { BadgeColor, SelectOptionElement } from '@odin-labs/components';
import { JobsiteIncidentType } from 'apollo/generated/client-operations';
import { camelToTitleCase } from 'utils';

export const siteSafetyUrl = '/site-safety';

export const incidentTypeColor: Record<JobsiteIncidentType, BadgeColor> = {
  Injury: 'red',
  GeneralLiability: 'blue',
  Automotive: 'pink',
  Equipment: 'yellow',
  TheftOrVandalism: 'purple',
  Property: 'indigo',
  Utility: 'gray',
  Other: 'gray',
};

export const incidentTypeOptions: SelectOptionElement<JobsiteIncidentType>[] = Object.values(JobsiteIncidentType).map(
  (value) => ({
    value,
    label: camelToTitleCase(value),
    color: incidentTypeColor[value],
  }),
);
