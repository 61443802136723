import classNames from 'classnames/bind';
import React, { ChangeEventHandler, forwardRef, ReactElement } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import styles from './scss/select.scss';
import { SelectOptionsElement, SelectProps } from './types';

const cx = classNames.bind(styles);

export function Select({
  label,
  name,
  error,
  placeholder,
  options,
  onChange,
  onChangeHook,
  forwardedRef,
  className,
  inputClassName,
  value,
  disabled,
  defaultValue = '',
}: SelectProps): ReactElement {
  const onChangeHandler: ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChangeHook) {
      return onChangeHook(event.target.value);
    }

    if (onChange) {
      return onChange(event.target.name, event.target.value);
    }

    return null;
  };

  return (
    <FormGroup className={cx('select', className)}>
      <Label for={name}>{label}</Label>
      <Input
        type="select"
        name={name}
        id={name}
        onChange={onChangeHandler}
        defaultValue={defaultValue ?? undefined}
        value={onChangeHandler ? value : null}
        className={cx('custom-select', inputClassName)}
        data-toggle="select"
        invalid={!!error}
        innerRef={forwardedRef}
        disabled={disabled}
      >
        {placeholder ? (
          <option disabled value="">
            {placeholder}
          </option>
        ) : null}
        {options?.map((el: SelectOptionsElement) => {
          return (
            <option key={el?.value} value={el?.value}>
              {el?.name}
            </option>
          );
        })}
      </Input>
      {error ? <div className={cx('mt-2', { 'invalid-feedback': error, 'd-block': error })}>{error}</div> : null}
    </FormGroup>
  );
}

export const WrappedSelect = forwardRef((props: any, ref: any): ReactElement => {
  return <Select {...props} forwardedRef={ref} />;
});
