import React from 'react';
import { useQuery } from '@apollo/client';
import { Table, TableContainer } from '@odin-labs/components';
import { QueryGetWorkerDocumentsArgs } from 'apollo/generated/client-operations';
import { GET_WORKER_DOCUMENTS } from 'containers/worker/helpers/queries';
import { GetWorkerDocumentsResponse, Worker } from 'containers/worker/types';
import { AuthContext } from 'auth';
import { useUpdateQueryStatus } from 'containers/jobsiteSafetyIncident/print/helpers/useUpdateQueryStatus';
import { ReportSectionTitle } from 'containers/jobsiteSafetyIncident/print/components/reportSectionTitle';
import { to } from 'acl';
import { getColumns, JobsiteWorkerDocumentColumn } from './helpers/tables';

export type WorkerReportMedicalDocumentsProps = {
  worker: Worker;
  jobsiteIds: string[];
};

export function WorkerReportMedicalDocuments(props: WorkerReportMedicalDocumentsProps): React.ReactElement {
  const { worker, jobsiteIds } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  const { data, loading } = useQuery<GetWorkerDocumentsResponse, QueryGetWorkerDocumentsArgs>(GET_WORKER_DOCUMENTS, {
    fetchPolicy: 'no-cache',
    skip: !worker?.workerId || !user.isAllowed(to.accessWorkerReportMedicalDocuments),
    variables: {
      workerId: worker?.workerId,
      includePhi: true,
      includeArchived: worker?.isArchived,
    },
  });

  useUpdateQueryStatus(`workerMedicalDocuments-${worker.workerId}`, { loading });

  const medicalDocuments =
    data?.getWorkerDocuments?.workerDocuments?.filter((jobsiteDocument) => {
      const { jobsiteWorkerDocumentType } = jobsiteDocument;
      const { workerDocumentType } = jobsiteWorkerDocumentType ?? {};
      const { key: workerDocumentTypeKey } = workerDocumentType ?? {};
      return (
        (workerDocumentTypeKey?.includes('medical') || workerDocumentTypeKey?.includes('post-employment')) &&
        jobsiteIds.includes(jobsiteWorkerDocumentType?.jobsite?.jobsiteId)
      );
    }) ?? [];

  const columns = React.useMemo<JobsiteWorkerDocumentColumn[]>(() => getColumns(), []);

  return (
    <TableContainer>
      <ReportSectionTitle title="Medical History" count={medicalDocuments?.length} />
      <Table loading={loading} columns={columns} data={medicalDocuments} remote disableGlobalFilter disableSortBy />
    </TableContainer>
  );
}
