import React from 'react';
import cn from 'classnames';
import { SelectOptionElement } from '@odin-labs/components';
import { getIcon } from 'utils/ui';
import { FormDefaultValue, FormInput, FormInputTypes } from 'components/form';
import { ParticipantType } from 'apollo/generated/client-operations';
import { SearchWorkerInfo } from 'components/searchBox/searchWorkerInfo/SearchWorkerInfo';
import { JobsiteIncidentParticipantFormData } from 'containers/jobsiteSafetyIncident/modals/types';
import { IncidentParticipantEdit } from 'containers/jobsiteSafetyIncident/types';

export const participantTypeOptions: SelectOptionElement<ParticipantType>[] = [
  { value: ParticipantType.Injured, label: 'Injured' },
  { value: ParticipantType.Suspected, label: 'General Suspected' },
  { value: ParticipantType.Responsible, label: 'Responsible' },
  { value: ParticipantType.Witness, label: 'Witness' },
];

export const getJobsiteWorkerOptions = (participantEdit: IncidentParticipantEdit): SelectOptionElement[] =>
  participantEdit.jobsitesInfo?.map((j) => ({
    value: j.jobsiteWorkerId,
    label: j.jobsiteName,
  }));

export const getFormInputs = (
  participantEdit: IncidentParticipantEdit,
  jobsiteWorkerOptions: SelectOptionElement[],
): FormInput<JobsiteIncidentParticipantFormData>[] => [
  {
    name: 'worker',
    element: FormInputTypes.CustomContent,
    elementProps: {
      content: (
        <SearchWorkerInfo
          isClickable={false}
          className="odin-border odin-border-gray-200 odin-shadow-sm odin-rounded-lg"
          searchWorkerResult={participantEdit.searchWorkerResult}
        />
      ),
    },
    layout: cn('odin-col-span-6'),
  },
  {
    name: 'jobsiteWorkerId',
    element: FormInputTypes.OdinSelect,
    elementProps: {
      placeholder: 'Select jobsite',
      label: 'Jobsite',
      options: jobsiteWorkerOptions,
      icon: getIcon('odin-text-odin-primary fal fa-briefcase'),
      disabled: (jobsiteWorkerOptions?.length ?? 0) < 2,
    },
    validation: {
      required: {
        value: true,
        message: 'Jobsite required',
      },
    },
    layout: cn('odin-col-start-1 odin-col-span-3'),
  },
  {
    name: 'participantType',
    element: FormInputTypes.OdinSelect,
    elementProps: {
      label: 'Role(s) in Incident',
      options: participantTypeOptions,
    },
    validation: {
      required: {
        value: true,
        message: 'Incident Type required',
      },
    },
    layout: cn('odin-col-span-3'),
  },
];

export const getDefaultValues = (
  participantEdit: Partial<IncidentParticipantEdit>,
  jobsiteWorkerOptions: SelectOptionElement[],
): FormDefaultValue<JobsiteIncidentParticipantFormData>[] => [
  {
    name: 'participantType',
    value: participantTypeOptions?.find((ptOption) => ptOption.value === participantEdit.participantType),
  },
  {
    name: 'jobsiteWorkerId',
    value: jobsiteWorkerOptions?.find(
      (jwOption) => jwOption.value === participantEdit.selectedJobsiteWorker?.jobsiteWorkerId,
    ),
  },
];
