import React, { ReactElement, useEffect } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { Filter, getFaIcon, NewButton, TabConfig } from '@odin-labs/components';
import { ApolloError } from '@apollo/client';
import {
  GetJobsiteWorkerDashboardQueryVariables,
  useGenerateLookerJobsiteWorkerReportMutation,
  useGetJobsiteWorkerDashboardLazyQuery,
} from 'apollo/generated/client-operations';

import { AuthContext } from 'auth';
import { AlertInstance } from 'components/alertNotification';
import { NewHeader } from 'components/header/NewHeader';
import { LoadingError } from 'components/loadingError';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { LockedFeatureAlert } from 'components/lockedFeatureAlert';

import { getCurrentISOFormattedDate } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { getGraphQLError } from 'utils/error';

import { usePageQueryParams } from 'utils/usePageQueryParams';
import { NewHeaderActionsProps } from 'components/header/types';
import { Container } from 'components/container';
import { ArrowToBottomIcon } from 'components/icons';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { getJobsiteDateTime } from 'utils/dates';
import { getFilterItems, getFiltersOptions } from './helpers/tables';
import { DashboardStatsTab, DashboardTabProps, DashboardWorkersTab } from './tabs';

import { DashboardFilters } from './types';

const StatsIcon = getFaIcon({ icon: ['fal', 'chart-simple'] });
const WorkersIcon = getFaIcon({ icon: ['fal', 'user-hard-hat'] });

const baseRoute = '/dashboard';
const tabsConfig: TabConfig<DashboardTabProps>[] = [
  { name: `Stats`, relativePath: '', component: DashboardStatsTab, leftIcon: StatsIcon },
  { name: `Workers`, relativePath: '/workers', component: DashboardWorkersTab, leftIcon: WorkersIcon },
];

export function DashboardContainer(): ReactElement {
  const location = useLocation();

  const defaultStartDate = moment(getCurrentISOFormattedDate());
  const defaultEndDate = moment(getCurrentISOFormattedDate());

  const {
    page,
    orderBy: sortField,
    orderByDesc: isDescending,
    startDate,
    endDate,
    jobsiteIds,
    contractor,
    currentlyOnsite,
    compliant,
    overnight,
    trade,
    tradeClass,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams({
    updateJobsiteSelection: true,
    defaultValues: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
  });

  const offset = limit * page;

  const isCurrentlyOnsiteVisible = defaultStartDate.isSame(startDate, 'day') && defaultEndDate.isSame(endDate, 'day');

  const { currentUser: user } = React.useContext(AuthContext);
  const isFeatureLocked = !user.hasPaidAccess;

  const { jobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();

  const startJobsiteDateTime = getJobsiteDateTime({ date: startDate });
  const endJobsiteDateTime = getJobsiteDateTime({ date: endDate });

  const getVariables = (
    overrides?: Partial<GetJobsiteWorkerDashboardQueryVariables>,
  ): GetJobsiteWorkerDashboardQueryVariables => ({
    limit,
    offset,
    sortField,
    sortDir: isDescending ? 'desc' : null,
    startDate: startJobsiteDateTime,
    endDate: endJobsiteDateTime,
    jobsiteIds,
    active: currentlyOnsite ? currentlyOnsite.toLowerCase() === 'yes' : null,
    compliant: compliant ? compliant.toLowerCase() === 'yes' : null,
    overnight: overnight ? overnight.toLowerCase() === 'yes' : null,
    contractor,
    trade,
    tradeClass,
    ...overrides,
    // Not used
    // timeBreakdown: GetJobsiteWorkerReportsTimeBreakdown.Day,
    // fitoutTenant,
    // workerIds,
  });

  const [reloadReports, { data: workerReportsData, loading: workerReportsLoading, error: workerReportsError }] =
    useGetJobsiteWorkerDashboardLazyQuery({
      variables: getVariables(),
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (location.search && !isUrlLoading) {
      reloadReports();
    }
  }, [location.search, isUrlLoading]);

  const filtersOptions = React.useMemo(
    () =>
      getFiltersOptions({
        dashboardData: workerReportsData?.getJobsiteWorkerDashboard,
        jobsites,
        overnight,
      }),
    [workerReportsData?.getJobsiteWorkerDashboard, jobsites],
  );

  const filterItems = React.useMemo(
    () =>
      getFilterItems({
        filtersOptions,
        jobsiteIds,
        contractor,
        trade,
        tradeClass,
        compliant,
        overnight,
        currentlyOnsite,
        startDate,
        endDate,
        defaultStartDate,
        defaultEndDate,
      }),
    [
      filtersOptions,
      jobsiteIds,
      contractor,
      trade,
      tradeClass,
      compliant,
      overnight,
      currentlyOnsite,
      startDate,
      endDate,
      defaultStartDate,
      defaultEndDate,
    ],
  );

  const [generateLookerJobsiteWorkerReport, { loading: generateLookerJobsiteWorkerReportLoading }] =
    useGenerateLookerJobsiteWorkerReportMutation({
      onError: (apolloError: ApolloError) => {
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(apolloError));
      },
      onCompleted: (response) => {
        window.open(response?.generateLookerJobsiteWorkerReport?.downloadUrl);
      },
    });

  const loading = workerReportsLoading || generateLookerJobsiteWorkerReportLoading || userJobsitesLoading;
  const error = workerReportsError || userJobsitesError;

  const { tabs, currentTab } = useRoutedTabs({
    tabsConfig,
    baseRoute,
    search: location.search,
  });

  const tabsPageProps: DashboardTabProps = {
    data: workerReportsData?.getJobsiteWorkerDashboard,
    loading,
    error,
    jobsites,
    isCurrentlyOnsiteVisible,
  };

  const onFilterChangeHandler = (changedFilters: Partial<DashboardFilters>): void => {
    const {
      dateRange: dateRangeFilter,
      currentlyOnsite: currentlyOnsiteFilter,
      ...restChangedFilters
    } = changedFilters;
    const newCurrentlyOnsite = dateRangeFilter ? null : currentlyOnsiteFilter;
    const dateRange = dateRangeFilter === null ? { startDate: null, endDate: null } : dateRangeFilter;
    updateUrl({ page: null, currentlyOnsite: newCurrentlyOnsite, ...dateRange, ...restChangedFilters });
  };

  const headerActionsProps = React.useMemo(
    (): NewHeaderActionsProps => ({
      baseRoute,
      tabsProps: { tabs, currentTab },
      onReloadPressed: reloadReports,
    }),
    [baseRoute, tabs, currentTab, reloadReports],
  );

  if (error) {
    return <LoadingError error={error} />;
  }

  if (isFeatureLocked) {
    return (
      <div className="odin-p-8">
        <LockedFeatureAlert />
      </div>
    );
  }

  return (
    <Container className="dashboard-container">
      <NewHeader title="Dashboard" actionsProps={headerActionsProps} />
      <Filter
        items={filterItems}
        loading={loading}
        onChange={onFilterChangeHandler}
        leftContainerStyle="tablet:odin-w-9/12"
      >
        {!user.isContractor && (
          <NewButton
            theme="white"
            size={['base', 'md:xs']}
            text="Download"
            hideTextOnMobile
            icon={ArrowToBottomIcon}
            onClick={async (): Promise<void> => {
              await generateLookerJobsiteWorkerReport({
                variables: getVariables({ limit: null, offset: null }),
              });
            }}
            withSpinner={generateLookerJobsiteWorkerReportLoading}
          />
        )}
      </Filter>

      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
    </Container>
  );
}
