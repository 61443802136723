import React from 'react';
import { faUserHardHat } from '@fortawesome/pro-light-svg-icons';
import { NewButton, getFaIcon } from '@odin-labs/components';
import { JobsiteIncident } from 'apollo/generated/client-operations';
import { EditableIncidentParticipant } from 'containers/jobsiteSafetyIncident/types';
import { AlertInstance } from 'components/alertNotification';
import { JobsiteIncidentWorkerEditModal } from 'containers/jobsiteSafetyIncident/modals/JobsiteIncidentWorkerEditModal';
import { useResettableState } from 'utils';
import { Change, EditType } from 'types';
import { JobsiteIncidentWorker } from './JobsiteIncidentWorker';

export type JobsiteIncidentWorkersProps = {
  jobsiteIncident?: JobsiteIncident;
  value?: EditableIncidentParticipant[];
  onChange?: (value: EditableIncidentParticipant[]) => void;
};

const UserHardHatIcon = getFaIcon({ icon: faUserHardHat });

export const JobsiteIncidentWorkers = React.forwardRef<HTMLDivElement, JobsiteIncidentWorkersProps>(
  (props, ref): React.ReactElement => {
    const { value, onChange, jobsiteIncident } = props;
    const {
      value: edit,
      setValue: openModal,
      resetValue: closeModal,
    } = useResettableState<Change<EditableIncidentParticipant>>(null, null);

    const updateValueAndCloseModal = React.useCallback(
      (participant: EditableIncidentParticipant, editType: EditType): void => {
        const { workerId } = participant.selectedJobsiteWorker.contractorWorker.worker;
        let newValue: EditableIncidentParticipant[];
        if (editType === 'create') {
          const existingParticipant = value?.find(
            (p) => p.selectedJobsiteWorker.contractorWorker.worker.workerId === workerId,
          );
          // if participant has been previously removed then it will be updated
          if (existingParticipant?.changeType === 'removed') {
            newValue = value.map((p) =>
              // existing participant id is preserved
              p.id !== existingParticipant.id ? p : { ...p, ...participant, id: p.id, changeType: 'updated' },
            );
          } else if (existingParticipant) {
            AlertInstance.alert('tc', 'danger', '', 'Worker has already been assigned!');
          } else {
            // add new participant
            newValue = [...value, participant];
          }
        } else {
          newValue = value.map((p) => (p.id !== participant.id ? p : { ...p, ...participant }));
        }

        if (newValue) {
          onChange?.(newValue);
        }

        closeModal();
      },
      [edit, onChange, value],
    );

    const removeParticipant = (participant: EditableIncidentParticipant): void => {
      const newValue: EditableIncidentParticipant[] =
        participant.changeType === 'created'
          ? value.filter((p) => p.id !== participant.id)
          : value.map((p) => (p.id !== participant.id ? p : { ...p, changeType: 'removed' }));

      onChange?.(newValue);
    };

    const participants = value.filter((p) => p.changeType !== 'removed');

    const participantsList = (participants.length || null) && (
      <div className="odin-flex odin-flex-col odin-space-y-4">
        {participants.map((participant) => (
          <JobsiteIncidentWorker
            key={participant.selectedJobsiteWorker.contractorWorker.worker.workerId}
            participant={participant}
            onRemoveIconClick={(): void => removeParticipant(participant)}
            onClick={(): void => openModal({ type: 'update', item: participant })}
          />
        ))}
      </div>
    );

    return (
      <>
        <div ref={ref} className="odin-flex odin-flex-col odin-space-y-9">
          <NewButton
            text="Add Worker to Report"
            theme="dashed"
            className="odin-w-full odin-justify-center odin-h-20"
            icon={UserHardHatIcon}
            onClick={(): void => openModal({ type: 'create' })}
          />
          {participantsList}
        </div>
        <JobsiteIncidentWorkerEditModal
          isOpen={!!edit}
          closeModal={closeModal}
          onConfirm={updateValueAndCloseModal}
          jobsiteIncident={jobsiteIncident}
          participant={edit?.item}
        />
      </>
    );
  },
);
