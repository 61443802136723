import { faWrench } from '@fortawesome/pro-light-svg-icons';
import { Column, getFaIcon } from '@odin-labs/components';
import { ApolloError } from '@apollo/client';
import {
  GetContractorContainerContractorSubcontractorsQuery,
  GetContractorContainerContractorWorkersQuery,
  GetContractorContainerContractorUsersQuery,
  GetContractorContainerContractorJobsitesQuery,
  GetContractorContainerContractorInfoQuery,
} from 'apollo/generated/client-operations';
import cn from 'classnames';
import { PageSearchParams } from 'utils';
import { Jobsite } from 'graphql/client/useJobsitesSelection';

export type { ContractorForOption } from './helpers';
export type { Jobsite };

export const ContractorIcon = getFaIcon({ icon: faWrench, className: cn('odin-text-base odin-text-odin-primary') });

export type ContractorWithSubcontractors = GetContractorContainerContractorSubcontractorsQuery['getContractor'];
type JobsiteContractorWithSubcontractors = ContractorWithSubcontractors['jobsiteContractors']['edges'][number]['node'];
export type SubcontractedJobsiteContractor =
  JobsiteContractorWithSubcontractors['subcontractedJobsiteContractors']['edges'][number]['node'];

export type ContractorInfo = GetContractorContainerContractorInfoQuery['getContractor'];

export type JobsiteContractor =
  GetContractorContainerContractorJobsitesQuery['getContractor']['jobsiteContractors']['edges'][number]['node'];

export type ContractorWorker =
  GetContractorContainerContractorWorkersQuery['getContractor']['contractorWorkers']['edges'][number]['node'];

export type ContractorMember =
  GetContractorContainerContractorUsersQuery['getContractor']['contractorMembers']['edges'][number]['node'];

export type Subcontractor = {
  contractorId: string;
  name: string;
  jobsiteContractors: SubcontractedJobsiteContractor[];
};

export type ContractorTabApi = {
  refetchData: () => void;
};

export type ContractorTabProps = {
  userJobsites?: Jobsite[];
  contractor: ContractorWithSubcontractors;
  contractorId?: string;
  loading?: boolean;
  error?: ApolloError;
  onTabApiChange?: (api: ContractorTabApi) => void;
  refetchContractorData?: () => void;
};

export type ContractorWorkerColumn = Column<ContractorWorker>;

export type ContractorWorkersFilters = Pick<PageSearchParams, 'jobsiteIds' | 'search'>;
export type ContractorUsersFilters = Pick<PageSearchParams, 'search'>;

export type ContractorUserColumn = Column<ContractorMember>;
export type ContractorSubcontractorFilters = Record<never, unknown>;

export type ContractorSubcontractorColumn = Column<Subcontractor>;

export const contractorUrl = '/contractor/';
