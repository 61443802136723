import classNames from 'classnames';
import React from 'react';
import { CustomInput, FormGroup, Label } from 'reactstrap';

import styles from './scss/toggle.scss';

import { ToggleProps } from './types';

const cx = classNames.bind(styles);

const parseBoolean = (value: string | boolean): boolean => {
  if (typeof value === 'string') {
    switch (value.toLowerCase()) {
      case 'true':
        return true;
      case 'false':
      default:
        return false;
    }
  }
  return value;
};

export const Toggle = React.forwardRef(
  (props: ToggleProps, ref: React.ForwardedRef<HTMLInputElement>): React.ReactElement => {
    const {
      onChange,
      name,
      label,
      description,
      toggleLabel,
      checked = parseBoolean(props.value) ?? false,
      disabled,
      error,
      className,
    } = props;

    const onChangeHandler = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!disabled) {
          onChange?.(event.target.checked);
        }
      },
      [onChange],
    );

    const getLabel = (): React.ReactNode => {
      if (Array.isArray(toggleLabel) && toggleLabel.length && typeof toggleLabel[0] === 'string') {
        return toggleLabel[checked ? 1 : 0];
      }
      return toggleLabel;
    };

    return (
      <FormGroup className={cx('toggle', className)}>
        <Label className="toggle-label" for={name}>
          {label}
        </Label>
        <div className="toggle__description">{description}</div>
        <CustomInput
          name={name}
          type="switch"
          id={name}
          checked={onChange ? checked : null}
          onChange={onChangeHandler}
          // If disabled and not checked the toggle won't be displayed.
          // That's why we pass disabled prop only when is checked as well
          // and we handle the disabled behavior in onChangeHandler
          disabled={disabled && checked}
          label={getLabel()}
          innerRef={ref}
        />
        {error ? <div className={cx('mt-2', { 'invalid-feedback': error }, 'd-block')}>{error}</div> : null}
      </FormGroup>
    );
  },
);
