import React from 'react';
import cn from 'classnames';
import QRCode from 'react-qr-code';
import { useHistory } from 'react-router';
import { JobsiteIncident } from 'containers/jobsiteSafetyIncident/print/types';
import { Avatar, NewButton } from '@odin-labs/components';
import { Logo } from 'components/logo';
import { getCurrentDomain } from 'utils';
import { getIcon } from 'utils/ui';
import { BackIcon } from 'components/icons';

export const classes = {
  container: 'odin-flex odin-items-center odin-justify-between',
  leftContainer: 'odin-flex odin-items-center odin-gap-x-3',
  rightContainer: 'odin-space-x-7',
  backButton: 'print:odin-hidden odin--ml-1',
  text: cn('odin-mb-0'),
};

export type JobsiteReportTitleProps = {
  jobsiteIncident: JobsiteIncident;
};

export function JobsiteReportTitle(props: JobsiteReportTitleProps): React.ReactElement {
  const { jobsiteIncident } = props;
  const qrUrl = `${getCurrentDomain()}/site-safety/incident/${jobsiteIncident.id}`;
  const history = useHistory();

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <Avatar size="3xl" src="" icon={getIcon('fal fa-briefcase odin-text-odin-primary')} />
        <div className="">
          <NewButton
            className={classes.backButton}
            text="Back"
            icon={BackIcon}
            theme="naked"
            onClick={(): void => history.goBack()}
          />
          <h1 className={classes.text}>{jobsiteIncident.name}</h1>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Logo />
        <QRCode value={qrUrl} size={72} />
      </div>
    </div>
  );
}
