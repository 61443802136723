import React from 'react';
import { ApolloError } from '@apollo/client';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError } from 'utils/error';
import {
  JobsiteIncident,
  JobsiteWorker,
  useJobsiteIncidentSearchWorkersLazyQuery,
} from 'apollo/generated/client-operations';
import { JobsiteInfo, SearchWorkerResult } from 'components/searchBox/types';
import { getFormattedPhoneNumber } from 'utils';

type WorkerSearchData = {
  searchWorkerResults: SearchWorkerResult[];
  loading: boolean;
  searchWorkers: (searchTerm: string) => void;
};

export const useWorkerSearchData = (jobsiteIncident: JobsiteIncident): WorkerSearchData => {
  const {
    id: incidentId,
    jobsite: { jobsiteId: incidentJobsiteId },
  } = jobsiteIncident;

  const [runSearchWorkersQuery, { data, loading }] = useJobsiteIncidentSearchWorkersLazyQuery({
    fetchPolicy: 'no-cache',
    onError: (responseError: ApolloError) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(responseError));
    },
  });

  const searchWorkerResults =
    data?.jobsiteIncidentSearchWorkers?.edges.map(({ node: searchWorkerResult }): SearchWorkerResult => {
      const { jobsiteWorkerAccess } = searchWorkerResult;

      // try to get the jobsiteWorker corresponding to the incident jobsite; otherwise get the first one
      const jobsiteWorker =
        jobsiteWorkerAccess.find((jwa) => jwa.jobsiteWorker.jobsiteContractor.jobsite.jobsiteId === incidentJobsiteId)
          ?.jobsiteWorker ?? jobsiteWorkerAccess[0].jobsiteWorker;

      const { worker } = jobsiteWorker.contractorWorker;
      const { firstName, lastName, email, phoneNumber } = worker.user.identity ?? {};

      return {
        id: worker.workerId,
        searchTerm: null,
        firstName,
        lastName,
        email,
        phone: getFormattedPhoneNumber(phoneNumber),
        quickCode: worker.quickCode,
        trade: worker.trade,
        tradeClass: worker.jobTitle,
        unionAffiliation: worker.unionAffiliation,
        badgeId: jobsiteWorker.stickerNumber?.toString(),
        imageUrl: worker.profilePicture?.downloadUrl,
        jobsitesInfo: jobsiteWorkerAccess.map<JobsiteInfo>((jwAccess) => {
          const { jobsiteWorker: jw } = jwAccess;
          return {
            jobsiteWorker: jw as JobsiteWorker,
            jobsiteWorkerId: jw.jobsiteWorkerId,
            isJobsiteAccessAllowed: jwAccess?.isAllowedNow ?? false,
            jobsiteName: jw.jobsiteContractor.jobsite.name,
            contractorName: jw.jobsiteContractor.contractor?.organization?.name,
          };
        }),
      };
    }) ?? [];

  const searchWorkers = React.useCallback(
    (searchTerm: string): void => {
      if (searchTerm) {
        runSearchWorkersQuery({
          variables: {
            input: { jobsiteIncidentId: incidentId, searchQuery: searchTerm },
          },
        });
      }
    },
    [runSearchWorkersQuery, incidentId],
  );

  return {
    searchWorkers,
    searchWorkerResults,
    loading,
  };
};
