import React from 'react';
import { ChangeType } from 'apollo/generated/client-operations';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { getFaIcon } from '@odin-labs/components';
import { FormDialog } from 'dialog';
import { getDefaultValues, getFormInputs } from './AddFormSubmissionDocumentModal.forms';
import { AddFormSubmissionDocumentModalProps, EditFormSubmissionDocumentFormData } from './types';

const PlusIcon = getFaIcon({ icon: faPlus });

export function AddFormSubmissionDocumentModal(props: AddFormSubmissionDocumentModalProps): React.ReactElement {
  const { isOpen, onCancel, onConfirm } = props;
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const onSubmit = (data: EditFormSubmissionDocumentFormData): void => {
    const { file, description } = data;
    onConfirm?.({
      changeType: 'created',
      id: `new-${new Date().valueOf()}`,
      description,
      files: [Object.assign(file, { changeType: ChangeType.Created })],
    });
  };

  const formInputs = getFormInputs();
  const defaultValues = React.useMemo(() => getDefaultValues(), []);

  return (
    <FormDialog
      id="addFormSubmissionDocument"
      open={isOpen}
      setOpen={onCancel}
      onIsDirtyChange={setIsFormDirty}
      title="Add Attachment"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Add Attachment"
      actionIcon={PlusIcon}
      actionButtonEnabled={isFormDirty}
    />
  );
}
