import React from 'react';
import { useGetJobsiteIncidentsQuery } from 'apollo/generated/client-operations';
import { paginationSizePerPage as limit } from 'utils/constants';
import { JobsiteSafetyIncidentsData, JobsiteSafetyIncidentsDataArgs } from 'containers/jobsiteSafety/types';
import { AuthContext } from 'auth';

export const useJobsiteSafetyIncidentsData = (args: JobsiteSafetyIncidentsDataArgs): JobsiteSafetyIncidentsData => {
  const { page, isUrlLoading, jobsiteIds, orderBy } = args;

  const { currentUser: user } = React.useContext(AuthContext);
  const isFeatureLocked = !user.hasSiteSafetyEnabled;

  const { data, error, loading, refetch } = useGetJobsiteIncidentsQuery({
    variables: {
      input: {
        jobsiteIds,
        orderBy,
        paginationInput: {
          limit,
          offset: limit * page,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: isUrlLoading || isFeatureLocked,
  });

  return React.useMemo((): JobsiteSafetyIncidentsData => {
    const {
      edges: jobsiteEdges,
      count: jobsiteIncidentsCount,
      countByJobsite: jobsiteIncidentsCountByJobsite,
    } = data?.getJobsiteIncidents ?? {};
    const jobsiteIncidents = jobsiteEdges?.map(({ node }) => node);
    const tabsCount: Record<string, string> = {
      incidents: jobsiteIncidentsCount?.toString(),
    };

    return {
      loading,
      error,
      jobsiteIncidents,
      jobsiteIncidentsCount,
      jobsiteIncidentsCountByJobsite,
      tabsCount,
      refetchData: refetch,
    };
  }, [data, error, loading, refetch]);
};
