import React from 'react';
import { faEdit, faFileChartLine } from '@fortawesome/pro-light-svg-icons';
import {
  Column,
  AvatarCell,
  AvatarSkeleton,
  BadgesCell,
  BadgesSkeleton,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import type { AvatarCellComponentProps, BadgesCellComponentProps, FilterItems } from '@odin-labs/components';

import { camelToTitleCase, getInitialsForUser } from 'utils';
import { GetJobsiteIncidentsOrderByField, JobsiteIncidentByJobsiteCount } from 'apollo/generated/client-operations';
import { JobsiteIncident, JobsiteSafetyIncidentsFilters } from 'containers/jobsiteSafety/types';
import { incidentTypeColor } from './utils';

export enum DailyLogColumnName {
  Name = 'name',
  Jobsite = 'jobsite',
  Date = 'date',
  Reporter = 'reporter',
  Actions = 'actions',
}

export type JobsiteIncidentColumn = Column<JobsiteIncident>;

const getUserRole = (incident: JobsiteIncident): string => {
  const { jobsiteId } = incident.jobsite ?? {};
  return incident.reporter?.user?.roles?.find((roleMapping) => {
    const { objectId, objectName } = roleMapping.object ?? {};
    return objectName === 'Jobsite' && objectId === jobsiteId;
  })?.displayName;
};

const FileChartLineIcon = getFixedWidthFaIcon({ icon: faFileChartLine });
const EditIcon = getFixedWidthFaIcon({ icon: faEdit });

export const getColumns = ({
  navigateToIncidentEditPage,
  navigateToIncidentPrintPage,
}: {
  navigateToIncidentEditPage: (incidentId: string) => void;
  navigateToIncidentPrintPage: (incidentId: string) => void;
}): JobsiteIncidentColumn[] => [
  {
    id: DailyLogColumnName.Name,
    Header: 'Incident',
    accessor: 'name',
    Cell: BadgesCell,
    CellLoading: BadgesSkeleton,
    componentProps: (incident: JobsiteIncident): BadgesCellComponentProps => ({
      badges: incident.incidentType.map((incidentType) => ({
        text: camelToTitleCase(incidentType),
        color: incidentTypeColor[incidentType],
      })),
    }),
  },
  {
    id: DailyLogColumnName.Jobsite,
    Header: 'Jobsite',
    accessor: (incident: JobsiteIncident): string => incident.jobsite?.name,
  },
  {
    id: DailyLogColumnName.Date,
    Header: 'Date',
    accessor: (incident: JobsiteIncident): string =>
      incident.incidentDateTime && new Date(incident.incidentDateTime).toLocaleDateString(),
  },
  {
    id: DailyLogColumnName.Reporter,
    Header: 'Reporter',
    accessor: (incident: JobsiteIncident): string => {
      const { firstName, lastName } = incident.reporter?.user?.identity ?? {};
      return [firstName, lastName].join(' ');
    },
    Cell: AvatarCell,
    CellLoading: AvatarSkeleton,
    componentProps: (incident: JobsiteIncident): AvatarCellComponentProps => ({
      src: incident.reporter?.profilePicture?.downloadUrl,
      placeholder: getInitialsForUser(incident.reporter?.user),
      detail: getUserRole(incident),
    }),
  },
  {
    id: DailyLogColumnName.Actions,
    Header: '',
    Cell: DropdownButtonCell,
    CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
    componentProps: (incident: JobsiteIncident): DropdownButtonCellComponentProps => ({
      cellAlignment: 'right',
      menuItems: [
        [
          {
            icon: FileChartLineIcon,
            text: 'View',
            theme: 'secondary',
            onClick: (): void => navigateToIncidentPrintPage(incident.id),
          },
          {
            icon: EditIcon,
            text: 'Edit',
            theme: 'secondary',
            onClick: (): void => navigateToIncidentEditPage(incident.id),
          },
          // {
          //   icon: getMenuItemIcon('fal fa-trash-alt'),
          //   text: 'Delete',
          //   iconColor: 'danger',
          //   onClick: (): void => alert(`Perform delete for '${JSON.stringify(incident.reporter?.user?.identity)}'`),
          // },
        ],
      ],
    }),
  },
];

export const getFilterItems = ({
  jobsiteIncidentsCountByJobsite,
  jobsiteIds,
}: {
  jobsiteIncidentsCountByJobsite: JobsiteIncidentByJobsiteCount[];
  jobsiteIds: string[];
}): FilterItems<JobsiteSafetyIncidentsFilters> => ({
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options:
        jobsiteIncidentsCountByJobsite?.map(({ jobsiteId, jobsiteName, count }) => ({
          value: jobsiteId,
          label: jobsiteName,
          count,
        })) ?? [],
      isMulti: true,
    },
  },
});

export const orderByFields: Record<string, GetJobsiteIncidentsOrderByField> = {
  [DailyLogColumnName.Name]: GetJobsiteIncidentsOrderByField.Name,
  [DailyLogColumnName.Jobsite]: GetJobsiteIncidentsOrderByField.Jobsite,
  [DailyLogColumnName.Date]: GetJobsiteIncidentsOrderByField.Date,
  [DailyLogColumnName.Reporter]: GetJobsiteIncidentsOrderByField.Reporter,
};
