import cn from 'classnames';
import { FormInputTypes, getUpdateInputValueFunction, TypedFormInputs } from 'components/form';
import {
  JobsiteIncidentParticipantUpdateInput,
  JobsiteIncidentUpdateInput,
  JobsiteWorker,
} from 'apollo/generated/client-operations';
import { DeepMap } from 'react-hook-form';
import {
  JobsiteIncident,
  JobsiteIncidentParticipant,
  EditJobsiteIncidentFormData,
  EditableIncidentParticipant,
} from 'containers/jobsiteSafetyIncident/types';
import { JobsiteIncidentWorkers } from 'containers/jobsiteSafetyIncident/components/jobsiteIncidentWorkers';
import { JobsiteInfo } from 'components/searchBox/types';

export const getWorkersSectionInputs = ({
  jobsiteIncident,
}: {
  jobsiteIncident: JobsiteIncident;
}): TypedFormInputs<EditJobsiteIncidentFormData['workers']> => ({
  participants: {
    element: FormInputTypes.CustomInput,
    elementProps: {
      customInput: JobsiteIncidentWorkers,
      ...{ jobsiteIncident },
    },
    layout: cn('odin-col-span-6'),
  },
});

export const toEditJobsiteIncidentParticipantFormData = ({
  node,
}: {
  node: JobsiteIncidentParticipant;
}): EditableIncidentParticipant => {
  const { selectedJobsiteWorker, jobsiteWorkerAccess, ...restParticipantDetail } = node;
  const { worker } = selectedJobsiteWorker.contractorWorker;

  return {
    ...restParticipantDetail,
    selectedJobsiteWorker,
    jobsitesInfo: jobsiteWorkerAccess.map<JobsiteInfo>((jwAccess) => {
      const jw = jwAccess.jobsiteWorker as JobsiteWorker;
      return {
        jobsiteWorker: { ...jw, worker },
        jobsiteWorkerId: jw.jobsiteWorkerId,
        isJobsiteAccessAllowed: jwAccess?.isAllowedNow ?? false,
        jobsiteName: jw.jobsiteContractor.jobsite.name,
        contractorName: jw.jobsiteContractor.contractor?.organization?.name,
      };
    }),
  };
};

export const getWorkersSectionDefaultValues = (
  jobsiteIncident: JobsiteIncident,
): EditJobsiteIncidentFormData['workers'] => {
  return {
    participants: jobsiteIncident?.participants?.edges.map(toEditJobsiteIncidentParticipantFormData) ?? [],
  };
};

export const toUpdateInput = (participant: EditableIncidentParticipant): JobsiteIncidentParticipantUpdateInput => {
  switch (participant.changeType) {
    case 'created':
      return {
        createInput: {
          jobsiteWorkerId: participant.selectedJobsiteWorker.jobsiteWorkerId,
          participantType: participant.participantType,
        },
      };
    case 'updated':
      return {
        updateInput: {
          incidentParticipantId: participant.id,
          jobsiteWorkerId: participant.selectedJobsiteWorker.jobsiteWorkerId,
          participantType: participant.participantType,
        },
      };
    case 'removed':
      return {
        removeInput: {
          incidentParticipantId: participant.id,
        },
      };
    default:
      return null;
  }
};

type WorkersSectionUpdateInput = Required<Pick<JobsiteIncidentUpdateInput, 'participantInputs'>>;

export const getWorkersSectionUpdateInput = (
  workers: EditJobsiteIncidentFormData['workers'],
  dirtyFields: DeepMap<EditJobsiteIncidentFormData['workers'], true>,
): WorkersSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(workers, dirtyFields);

  return {
    participantInputs: getUpdateInputValue('participants')?.map(toUpdateInput).filter(Boolean),
  };
};
