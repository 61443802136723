import React from 'react';
import {
  AppErrorCode,
  UserErrorCode,
  useSelfOnboardingWorkerCreateMutation,
  useUserUpdateIdentityMutation,
  useUpdateWorkerMutation,
  DocumentKey,
  useUpsertJobsiteWorkerDocumentMutation,
} from 'apollo/generated/client-operations';
import { Form, FormOnSubmit } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { getGraphQLError, getGraphQLErrorByCode } from 'utils/error';
import { AlreadyExistingItem } from 'components/alreadyExistingItem';
import { AuthContext } from 'auth';
import { isNotEmpty } from 'utils';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import {
  AcknowledgmentStatus,
  SelfOnboardingBasicInfoFormData,
  SelfOnboardingStepKey,
  SelfOnboardingStepProps,
} from 'containers/selfOnboarding/steps/types';
import { Copy, languageOptions } from 'containers/selfOnboarding/steps/utils';
import { getPrettyFormattedUtcDate } from 'utils/dates';
import { primaryLanguageOptions as primaryLanguageOptionsValues } from 'utils/constants';
import { WorkerAlreadyExistsErrorExtensions } from 'containers/worker/modals/addBadgeModal/components';
import { StepActions, StepInfo, StepInfoTitle, StepJobsiteInfo } from 'containers/selfOnboarding/steps/components';
import { isDocumentVisible } from 'containers/selfOnboarding/helpers/utils';
import { useSelfOnboardingFormCommonProps } from 'containers/selfOnboarding/helpers/forms';
import { useGetIsSelfOnboardingClosed } from 'containers/selfOnboarding/helpers/useGetIsSelfOnboardingClosed';
import {
  getFormInputsHook,
  getDefaultValues,
  getCreateInput,
  getUpdateInputs,
  getEditableFields,
  getWorkerConsentDocumentInput,
} from './BasicInfoStep.forms';

export function BasicInfoStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, updateState, navigation, stepConfig, localize, language, jobsiteInvitation, refetchData } = props;
  const { basicInfo, worker, userAccountId } = state;
  const { workerConsentStatus } = basicInfo ?? {};
  const { workerId } = worker ?? {};
  const { getNextStep, goToNextStep, goToStep, state: navigationState } = navigation;
  const { isReview, autoFocusField } = navigationState;

  const { authState } = React.useContext(AuthContext);
  const [isSaving, setIsSaving] = React.useState(false);

  const [selfOnboardingWorkerCreate] = useSelfOnboardingWorkerCreateMutation();
  const [updateUserIdentity] = useUserUpdateIdentityMutation();
  const [updateWorker] = useUpdateWorkerMutation();
  const [upsertJobsiteWorkerDocument] = useUpsertJobsiteWorkerDocumentMutation();
  const { isSelfOnboardingClosed } = useGetIsSelfOnboardingClosed();

  const languageName = (languageOptions.find((opt) => opt.value === language) ?? languageOptions[0]).label;
  const primaryLanguage = primaryLanguageOptionsValues.find((optValue) => languageName.startsWith(optValue));

  const { modules } = jobsiteInvitation?.jobsiteContractor.jobsite ?? {};
  const isWorkerConsentAvailable = isDocumentVisible(DocumentKey.WorkerConsentDocument, modules);
  const isWorkerConsentConfirmed = !!workerConsentStatus?.includes(AcknowledgmentStatus.Confirmed);

  const initialIsContinueActionEnabled = !isWorkerConsentAvailable || isWorkerConsentConfirmed;
  const [isContinueActionEnabled, setIsContinueActionEnabled] = React.useState(initialIsContinueActionEnabled);

  const onSubmit: FormOnSubmit<SelfOnboardingBasicInfoFormData> = async (
    data,
    event,
    dirtyFields,
    formApi,
  ): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      if (workerId) {
        if (await isSelfOnboardingClosed()) {
          goToStep(SelfOnboardingStepKey.Closed);
          return;
        }

        // update existing worker
        const { userIdentityInput, workerInput } = getUpdateInputs(data, dirtyFields) ?? {};

        if (userIdentityInput) {
          await updateUserIdentity({ variables: { input: { userAccountId, userIdentityInput } } });
        }

        if (workerInput) {
          await updateWorker({ variables: { workerId, workerInput } });
        }

        if (isNotEmpty(dirtyFields)) {
          updateState({ basicInfo: data });
        }
      } else if (isNotEmpty(dirtyFields)) {
        // create a worker
        const nextStep = getNextStep();
        const input = getCreateInput({ nextStep, jobsiteInvitation, primaryLanguage, data, dirtyFields });
        const { data: resultData } = await selfOnboardingWorkerCreate({ variables: { input } });
        const { session, user } = resultData.selfOnboardingWorkerCreate.auth;
        authState.signIn(session.sessionToken, null);

        if (isWorkerConsentAvailable) {
          const { jobsiteWorkerId } = resultData.selfOnboardingWorkerCreate.jobsiteWorker;
          const documentInput = await getWorkerConsentDocumentInput({
            user,
            jobsiteInvitation,
            state,
            jobsiteWorkerId,
          });
          if (documentInput) {
            await upsertJobsiteWorkerDocument({ variables: { input: documentInput } });
          }
        }

        await refetchData();
      }

      setIsSaving(false);
      goToNextStep();
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);

      const workerAlreadyExistsError = getGraphQLErrorByCode<WorkerAlreadyExistsErrorExtensions>(
        error,
        AppErrorCode.WorkerAlreadyExists,
      );

      if (workerAlreadyExistsError) {
        const { workerInfo } = workerAlreadyExistsError.extensions ?? {};
        const [matchedWorker] = workerInfo ?? [];
        const { jobsiteWorkerId, phone, email, ssnLastFour, birthDate } = matchedWorker ?? {};

        updateState({
          matchedWorker,
          basicInfo: {
            ...data,
            phoneNumber: phone || data.phoneNumber,
            email: email || data.email,
            birthDate: getPrettyFormattedUtcDate(birthDate) || data.birthDate,
            ssnLastFour: ssnLastFour || data.ssnLastFour,
          },
          didWorkerMatch: true,
        });

        if (jobsiteWorkerId) {
          goToStep(SelfOnboardingStepKey.AlreadyCompleted);
        } else {
          goToStep(SelfOnboardingStepKey.VerifyMobileDeviceToSignIn);
        }
        return;
      }
      const emailAlreadyExistsError = getGraphQLErrorByCode(error, UserErrorCode.EmailAlreadyExists);
      if (emailAlreadyExistsError) {
        formApi.setError('email', {
          message: <AlreadyExistingItem itemType="Email" />,
          shouldFocus: true,
        });
        return;
      }
      const phoneAlreadyExistsError = getGraphQLErrorByCode(error, AppErrorCode.PhoneAlreadyExists);
      if (phoneAlreadyExistsError) {
        formApi.setError('phoneNumber', {
          message: <AlreadyExistingItem itemType="Phone" />,
          shouldFocus: true,
        });
        return;
      }
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  // `changedValues` will be applied to the form if worker has not been created yet.
  // This could happen if a worker is matched and then user gets back to this page
  // in order to change some values. In this situation, the previously changed fields
  // need to be dirty in order to be sent again to the mutation.
  const { defaultValues, changedValues, editableFields } = React.useMemo(() => {
    // `defaultValues` is initialized as empty object if worker doesn't exist
    const formDefaultValues = getDefaultValues(worker ? basicInfo : null);

    // set `changedValues` only if `basicInfo` has been previously changed
    // and worker is null, which means that the query has completed (otherwise worker would be undefined)
    const formChangedValues = basicInfo && worker === null ? getDefaultValues(basicInfo) : null;

    const values = formChangedValues ?? formDefaultValues;

    return {
      editableFields: getEditableFields({ worker, values }),
      defaultValues: formDefaultValues,
      changedValues: formChangedValues,
    };
  }, [basicInfo, worker, jobsiteInvitation]);

  const inputs = getFormInputsHook({
    jobsiteInvitation,
    navigation,
    state,
    updateState,
    localize,
    editableFields,
    isWorkerConsentAvailable,
    isWorkerConsentConfirmed,
    setIsContinueActionEnabled,
  });

  const { fieldsConfig, localization } = useSelfOnboardingFormCommonProps(stepConfig, localize);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={`${localize(Copy.self_onboarding_worker_match_header)} 👋`}
          subtitle={localize(Copy.self_onboarding_worker_match_instructions)}
          withBottomBorder
          hideOnMobile
        />
        <StepJobsiteInfo jobsiteInvitation={jobsiteInvitation} />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        changedValues={changedValues}
        onSubmit={onSubmit}
        autoFocus={(autoFocusField as keyof SelfOnboardingBasicInfoFormData) ?? true}
        renderBelow={
          <StepActions
            localize={localize}
            isReview={isReview}
            actions={isReview ? 'all' : 'continue'}
            onSkip={goToNextStep}
            continueActionWithSpinner={isSaving}
            continueActionEnabled={isContinueActionEnabled}
            showAcknowledgementText={!isReview}
          />
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        fieldsConfig={fieldsConfig}
        localization={localization}
      />
    </div>
  );
}
