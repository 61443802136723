import React from 'react';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipProps,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItemConfig,
  NewMenuItemProps,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import { ensureNonEmptyItems, getInitialsForUser } from 'utils';
import { isAfterToday } from 'utils/dates';
import {
  ContractorSubcontractorColumn,
  ContractorSubcontractorFilters,
  ContractorWorker,
  ContractorWorkerColumn,
  ContractorWorkersFilters,
  Jobsite,
  Subcontractor,
} from 'containers/contractor/types';
import { AuthUser } from 'auth/types';
import { to } from 'acl';

const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });

// ContractorWorker
export const getWorkerColumns = ({
  user,
  openRemoveContractorWorkerModal,
}: {
  user: AuthUser;
  openRemoveContractorWorkerModal: (contractorMemberId: string) => void;
}): ContractorWorkerColumn[] => {
  const canRemoveContractorWorkers = user.isAllowed(to.removeContractorWorkers);
  const isActionsButtonVisible = canRemoveContractorWorkers;

  return ensureNonEmptyItems<ContractorWorkerColumn>([
    {
      id: 'worker',
      Header: 'Worker',
      accessor: (contractorWorker: ContractorWorker): string => {
        const { firstName, lastName } = contractorWorker.worker.user.identity ?? {};
        return [firstName, lastName].join(' ');
      },
      Cell: AvatarCell,
      CellLoading: <AvatarSkeleton size="xs" hideDetails />,
      componentProps: (contractorWorker: ContractorWorker): AvatarCellComponentProps => ({
        src: contractorWorker.worker.profilePictureCropped?.downloadUrl,
        placeholder: getInitialsForUser(contractorWorker.worker.user),
        objectFit: 'cover',
        size: 'xs',
      }),
      disableSortBy: true,
    },
    {
      id: 'quickCode',
      Header: <>Quick&nbsp;Code</>,
      accessor: (contractorWorker: ContractorWorker): string => contractorWorker.worker.quickCode,
      disableSortBy: true,
    },
    {
      id: 'badgeNumber',
      Header: <>Badge&nbsp;Numbers</>,
      accessor: (contractorWorker: ContractorWorker): string =>
        contractorWorker.worker.workerCards.edges.map(({ node }) => node.cardNumber).join(', '),
      disableSortBy: true,
    },
    {
      id: 'jobsiteAssignments',
      Header: 'Jobsite Assignments',
      accessor: (): string[] => [],
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (contractorWorker: ContractorWorker): ChipsCellComponentProps => ({
        chips: contractorWorker.jobsiteWorkers.edges.map(({ node }) => ({
          text: node.jobsiteContractor.jobsite.name,
          secondaryText: node.jobsiteContractor.contractor.organization.name,
          isActive: node.currentAccess.isAllowed,
        })),
      }),
      disableSortBy: true,
    },
    isActionsButtonVisible && {
      Header: '',
      id: 'actions',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (contractorWorker: ContractorWorker): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems: ensureNonEmptyItems<NewMenuItemProps>([
          canRemoveContractorWorkers && {
            icon: TrashIcon,
            text: 'Remove Contractor',
            theme: 'danger',
            onClick: (): void => openRemoveContractorWorkerModal(contractorWorker.id),
          },
        ]),
      }),
    },
  ]);
};

export const getWorkerFilterItems = ({
  jobsites,
  jobsiteIds,
  search,
}: {
  jobsites: Jobsite[];
  jobsiteIds: string[];
  search: string;
}): { [key in keyof ContractorWorkersFilters]: FilterItemConfig } => ({
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options: jobsites?.map(({ jobsiteId: value, name: label }) => ({ value, label })) ?? [],
      isMulti: true,
    },
  },
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});

// Subcontractor
export const getSubcontractorColumns = (menuItems?: NewMenuItemProps[]): ContractorSubcontractorColumn[] =>
  ensureNonEmptyItems<ContractorSubcontractorColumn>([
    {
      id: 'contractor',
      Header: 'Contractor',
      accessor: (contractor: Subcontractor): string => contractor.name,
      disableSortBy: true,
    },

    {
      id: 'jobsiteAssignments',
      Header: 'Jobsite Assignments',
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (contractor: Subcontractor): ChipsCellComponentProps => ({
        chips: contractor.jobsiteContractors.map<ChipProps>((jobsiteContractor) => ({
          text: jobsiteContractor.jobsite.name,
          secondaryText: `${jobsiteContractor.jobsiteWorkers.count} workers `,
          isActive: !jobsiteContractor.endDate || isAfterToday(jobsiteContractor.endDate),
        })),
      }),
      disableSortBy: true,
    },
    menuItems?.length && {
      Header: '',
      id: 'actions',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems,
      }),
    },
  ]);

// Not currently used; subcontractor filters are not implemented

export const getSubcontractorFilterItems = (
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  _args: any,
): Record<keyof ContractorSubcontractorFilters, FilterItemConfig> => ({});
