import { SearchWorkerResult } from 'components/searchBox/types';
import { EditableIncidentParticipant, IncidentParticipantEdit } from 'containers/jobsiteSafetyIncident/types';
import { getFormattedPhoneNumber } from 'utils';

export const getSearchWorkerResult = (participantEdit: EditableIncidentParticipant): SearchWorkerResult => {
  const {
    selectedJobsiteWorker,
    selectedJobsiteWorker: { contractorWorker },
    jobsitesInfo,
  } = participantEdit;

  const { worker } = contractorWorker;
  const { firstName, lastName, email, phoneNumber } = worker.user.identity ?? {};

  return {
    id: worker.workerId,
    firstName,
    lastName,
    email,
    phone: getFormattedPhoneNumber(phoneNumber),
    quickCode: worker.quickCode,
    badgeId: selectedJobsiteWorker.stickerNumber?.toString(),
    imageUrl: worker.profilePicture?.downloadUrl,
    trade: worker.trade,
    tradeClass: worker.jobTitle,
    unionAffiliation: worker.unionAffiliation,
    jobsitesInfo,
    searchTerm: null,
  };
};

export const getDefaultParticipantEdit = (participant?: EditableIncidentParticipant): IncidentParticipantEdit =>
  participant ? { ...participant, editType: 'update', searchWorkerResult: getSearchWorkerResult(participant) } : null;
