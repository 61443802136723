import React, { ReactElement, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useMutation } from '@apollo/client';
import {
  JobsiteClearanceType,
  JobsiteWorkerClearanceAssignmentInput,
  useGetJobsiteDetailsQuery,
  useGetWorkerCardsQuery,
  useUpdateJobsiteWorkerV2Mutation,
  JobsiteWorker,
  MutationUpdateJobsiteWorkerArgs,
  WorkerCardType,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { AlertInstance } from 'components/alertNotification';
import { Header } from 'components/header';
import { LoadingError } from 'components/loadingError';
import { UPDATE_JOBSITE_WORKER } from 'containers/workerOnboarding/helpers/queries';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { useWorkerData } from 'containers/worker/data/useWorkerData';
import { WorkerBadges } from 'containers/worker/tabs';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { ClearanceChecklist } from 'components/clearanceChecklist/ClearanceChecklist';
import { ClearanceSelection } from 'components/clearanceChecklist/types';
import { useQueryParams } from 'utils/useQueryParams';
import { OnboardingStepProps } from 'containers/workerOnboarding/types';
import { StepLoading } from './StepLoading';

const workerCardTypes = [WorkerCardType.Proximity, WorkerCardType.QrCode, WorkerCardType.FacialRecognition];

export function BadgingStep(props: OnboardingStepProps): ReactElement {
  const { loading: parentLoading, jobsiteWorkerId, jobsiteWorker } = props;
  const { currentUser: user } = React.useContext(AuthContext);
  const [fetching, setFetching] = useState<boolean>(false);
  const [clearanceSelectionData, setClearanceSelectionData] = useState<ClearanceSelection[]>([]);

  const workerId = jobsiteWorker?.contractorWorker?.worker?.workerId;
  const jobsiteId = jobsiteWorker?.jobsiteContractor?.jobsite?.jobsiteId;

  const urlSearchParams = useQueryParams();
  const skipACSSync = !!urlSearchParams.get('skipacssync');

  const {
    data: jobsiteData,
    loading: getJobsiteDetailsLoading,
    error: getJobsiteDetailsError,
  } = useGetJobsiteDetailsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      jobsiteId,
    },
    skip: !jobsiteId,
  });

  const {
    worker,
    loading: useWorkerDataLoading,
    error: useWorkerDataError,
    jobsiteWorkers,
    jobsiteNames,
    refetch,
  } = useWorkerData(workerId);

  const {
    data: getWorkerCardsData,
    loading: getWorkerCardsLoading,
    error: getWorkerCardsError,
  } = useGetWorkerCardsQuery({
    variables: {
      workerId,
      includeArchived: true,
    },
    fetchPolicy: 'network-only',
    skip: workerId == null,
  });

  const [updateJobsiteWorkerV2] = useUpdateJobsiteWorkerV2Mutation({
    refetchQueries: ['GetJobsiteWorker'],
  });

  const hasNoWorkerCards = !getWorkerCardsData?.getWorkerCards.some(
    (wc) => wc.cardNumber && workerCardTypes.includes(wc.workerCardFormat.cardType),
  );
  const jobsite = jobsiteData?.getJobsite;
  const { clearanceAssignmentEnabled } = jobsite ?? {};

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    onError: (error) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    },
    refetchQueries: ['GetJobsiteWorker'],
  });

  const error = getWorkerCardsError || useWorkerDataError || getJobsiteDetailsError;
  if (error) {
    return <LoadingError error={error} />;
  }

  const loading =
    parentLoading || getWorkerCardsLoading || useWorkerDataLoading || getJobsiteDetailsLoading || fetching;

  const onSaveHandler = async (): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    await updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          badgingCompletedAt: getCurrentISOFormattedDateTime(),
          badgingSkipReason: null,
        },
      },
    });
    setFetching(false);
  };

  const onClearanceAssignmentChange = async (selections: ClearanceSelection[]): Promise<void> => {
    if (fetching || !selections) return;
    setFetching(true);
    setClearanceSelectionData(selections);

    const clearanceAssignments =
      selections
        ?.filter((cs) => cs.selected)
        .map((cs) => {
          return {
            id: cs.clearanceAssignment?.id ?? undefined,
            clearanceId: cs.clearance.cCureClearanceId,
            jobsiteWorkerId,
            clearanceType: JobsiteClearanceType.CCure,
          } as JobsiteWorkerClearanceAssignmentInput;
        }) ?? [];
    try {
      await updateJobsiteWorkerV2({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: { clearanceAssignments },
          skipACSSync,
        },
      });
    } catch (err) {
      AlertInstance.alert('tc', 'danger', 'Something went wrong updating clearances!', getGraphQLError(error));
    }
    setFetching(false);
  };

  React.useEffect(() => {
    if (jobsiteWorker && jobsite) {
      const clearanceAssignments = jobsiteWorker?.clearanceAssignments.edges.map(({ node }) => node) ?? [];
      const jobsiteClearances = jobsite?.cCureClearances?.edges.map(({ node }) => node) ?? [];
      const useJobsiteDefaults = clearanceAssignments.length < 1;
      const clearanceSelections = jobsiteClearances.map((clearance) => {
        const clearanceAssignment = clearanceAssignments.find(
          (ca) => ca.clearance?.cCureClearanceId === clearance.cCureClearanceId,
        );
        return {
          clearance,
          clearanceAssignment,
          ...(clearanceAssignment
            ? { selected: true }
            : { selected: useJobsiteDefaults ? clearance.isDefault : false }),
        } as ClearanceSelection;
      });
      setClearanceSelectionData(clearanceSelections);
    }
  }, [jobsiteWorker, jobsite]);

  return (
    <>
      <Col xs="12">
        <fieldset disabled={user.isContractor}>
          <Header title="Worker badging" />
          <StepLoading loading={loading} />
          <Row hidden={!clearanceAssignmentEnabled}>
            <Col xl="12" className="odin-space-y-3">
              <Card>
                <CardHeader>
                  <div className="odin-flex odin-items-center odin-justify-between">
                    <div>
                      <h3 className="card-header-title odin-mr-1 odin-inline">Clearance Assignments</h3>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <ClearanceChecklist
                    value={clearanceSelectionData}
                    onChange={onClearanceAssignmentChange}
                    clearanceEditingEnabled
                    hideLegend
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <WorkerBadges
            worker={worker}
            refetchWorkerData={refetch}
            jobsiteWorkers={jobsiteWorkers}
            jobsiteNames={jobsiteNames}
            showJobsiteAccessHistory={false}
            isLocked={false}
          />
        </fieldset>
      </Col>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={false}
        hasNoWorkerCards={hasNoWorkerCards}
        onSave={onSaveHandler}
        hideSkipOptions
        hideLockSession
        hideSave={user.isContractor}
      />
    </>
  );
}
