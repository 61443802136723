import React from 'react';
import { faFilePlus } from '@fortawesome/pro-light-svg-icons';
import { NewButton, getFaIcon } from '@odin-labs/components';
import { useModalChangeActions } from 'utils/useModalChangeActions';
import {
  DeleteIncidentDocumentModal,
  EditIncidentDocumentModal,
  EditableIncidentDocument,
  AddIncidentDocumentModal,
} from './modals';
import { JobsiteIncidentDocumentsProps } from './types';
import { JobsiteIncidentDocument } from './JobsiteIncidentDocument';

const FilePlusIcon = getFaIcon({ icon: faFilePlus });

export const JobsiteIncidentDocuments = React.forwardRef(
  (props: JobsiteIncidentDocumentsProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { value, onChange } = props;

    const { change, closeModal, openAddModal, openEditModal, openDeleteModal, updateValueAndCloseModal } =
      useModalChangeActions<EditableIncidentDocument>({ items: value, onChange, idField: 'jobsiteIncidentDocumentId' });

    const incidentDocuments = value.filter((p) => p.changeType !== 'removed');
    const incidentDocumentsList = (incidentDocuments.length || null) && (
      <div className="odin-grid odin-grid-cols-1 odin-gap-y-3 sm:odin-grid-cols-2 sm:odin-gap-x-3">
        {incidentDocuments.map((document) => (
          <JobsiteIncidentDocument
            key={document.jobsiteIncidentDocumentId}
            document={document}
            onEditClick={(): void => openEditModal(document)}
            onDeleteClick={(): void => openDeleteModal(document)}
          />
        ))}
      </div>
    );

    return (
      <>
        <div ref={ref} className="odin-flex odin-flex-col odin-space-y-9">
          <NewButton
            text="Add Attachment"
            theme="dashed"
            className="odin-w-full odin-justify-center odin-h-20"
            icon={FilePlusIcon}
            onClick={openAddModal}
          />
          {incidentDocumentsList}
        </div>
        <AddIncidentDocumentModal
          isOpen={change?.type === 'create'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
        />
        <EditIncidentDocumentModal
          isOpen={change?.type === 'update'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          document={change?.item}
        />
        <DeleteIncidentDocumentModal
          isOpen={change?.type === 'remove'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          document={change?.item}
        />
      </>
    );
  },
);
