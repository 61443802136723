import { gql } from '@apollo/client';
import { JobsiteWorker } from 'apollo/generated/client-operations';

export interface AssignContractorWorkerToJobsite {
  assignContractorWorkerToJobsite: JobsiteWorker;
}

export const ASSIGN_CONTRACTOR_WORKER_TO_JOBSITE = gql`
  mutation AssignContractorWorkerToJobsite(
    $jobsiteId: ID!
    $workerId: ID!
    $contractorId: ID!
    $jobsiteWorkerInput: JobsiteWorkerInput!
  ) {
    assignContractorWorkerToJobsite(
      jobsiteId: $jobsiteId
      workerId: $workerId
      contractorId: $contractorId
      jobsiteWorkerInput: $jobsiteWorkerInput
    ) {
      jobsiteWorkerId
      orientationStatus
      contractorWorker {
        worker {
          birthDate
          ssnLastFour
          workerId
          user {
            userAccountId
            createdAt
            identity {
              email
              firstName
              lastName
              createdAt
              updatedAt
            }
            hasAuth
            roles {
              key
              displayName
              roleCreatedAt
              roleUpdatedAt
              mappingCreatedAt
            }
          }
        }
      }
      jobsiteContractor {
        jobsite {
          description
          jobsiteId
          name
        }
      }
      startDate
      endDate
    }
  }
`;
