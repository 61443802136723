import { QueryStatus } from 'containers/jobsiteSafetyIncident/print/types';
import { JobsiteSafetyIncidentPrintContext } from 'containers/jobsiteSafetyIncident/print/JobsiteSafetyIncidentPrintContext';
import React from 'react';

export const useUpdateQueryStatus = (queryName: string, queryStatus: QueryStatus): void => {
  const { loading, error } = queryStatus;
  const { updateQueryStatus } = React.useContext(JobsiteSafetyIncidentPrintContext);

  React.useEffect(() => {
    updateQueryStatus(queryName, queryStatus);
  }, [loading, error]);
};
