import React from 'react';
import { FormSubmissionContractorWorkersCountData } from 'containers/jobsiteFormSubmission/types';
import { Field } from '@odin-labs/components';
import { useContractorsOptions } from 'containers/worker/modals/addWorkerModal/AddWorkerModal.forms';
import { ensureNonUndefinedFields } from 'utils';

export type FormSubmissionContractorWorkersCountProps = {
  jobsiteId?: string;
  value?: FormSubmissionContractorWorkersCountData;
  onChange?: (value: FormSubmissionContractorWorkersCountData) => void;
};

export const FormSubmissionContractorWorkersCount = React.forwardRef<
  HTMLDivElement,
  FormSubmissionContractorWorkersCountProps
>((props, ref): React.ReactElement => {
  const { value, onChange, jobsiteId } = props;

  const contractorsOptions = useContractorsOptions(jobsiteId);

  const onItemChange = React.useCallback(
    (contractorId: string, workersCount: string): void => {
      const newValue =
        ensureNonUndefinedFields<FormSubmissionContractorWorkersCountData>({
          ...(value ?? undefined),
          [contractorId]: workersCount ? Number(workersCount) : undefined,
        }) ?? null;
      onChange?.(newValue);
    },
    [onChange, value],
  );

  return (
    <div className="odin-flex odin-flex-col odin-gap-y-4.5">
      <div ref={ref} className="odin-gap-3 odin-grid odin-grid-cols-2">
        {contractorsOptions?.map(({ value: contractorId, label: contractorName }) => (
          <React.Fragment key={contractorId}>
            <div className="odin-flex odin-items-center">
              <label
                htmlFor={`workersCount-${contractorId}`}
                className="odin-block odin-text-sm odin-font-medium odin-text-gray-900 odin-mb-0"
              >
                {contractorName}
              </label>
            </div>
            <Field
              name={`workersCount-${contractorId}`}
              value={value?.[contractorId]?.toString() ?? ''}
              type="number"
              onChange={(workersCount): void => onItemChange(contractorId, workersCount)}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
});
