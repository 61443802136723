import React from 'react';
import { getFields } from 'types';
import { ensureNonEmptyItems } from 'utils';
import { DocumentKey } from 'containers/worker/utils';
import { FormInputTypes, FormInput, UseInputs, UseFormMethods } from 'components/form';
import { AcknowledgmentProgress } from 'components/acknowledgmentProgress';
import {
  JobsiteWorkerDocumentArgs,
  JobsiteWorkerDocumentsData,
  generateJobsiteWorkerDocument,
  getDocumentPreviewUrl,
} from 'containers/workerOnboarding/helpers/forms';
import { getVisibleDocuments, getStepFieldsConfig } from 'containers/workerOnboarding/helpers/utils';
import {
  OnboardingStepKey,
  SiteSafetyTrainingDataType,
  OnboardingStepProps,
  JobsiteWorker,
} from 'containers/workerOnboarding/types';
import { EditJobsiteConfigurationFormData } from 'containers/jobsiteConfiguration/types';
import { cleaveDateValidation } from 'utils/validation';
import { useDidUpdateEffect } from '@odin-labs/components';

type GetFormInputsArgs = Pick<
  OnboardingStepProps,
  'jobsiteWorker' | 'filteredDocumentTypeIds' | 'documents' | 'defaultFormValues'
>;

const useAcknowledgmentProgressUpdate = (
  documentKey: DocumentKey,
  form: UseFormMethods<SiteSafetyTrainingDataType>,
): void => {
  const { watch, setValue } = form;
  const frontFile = watch(`${documentKey}-front`);

  useDidUpdateEffect(() => {
    const value = frontFile ? 1 : 0;
    setValue(`${documentKey}-acknowledgment-progress-fraction`, value.toString());
  }, [frontFile]);
};

export const getFormInputsHook =
  (args: GetFormInputsArgs): UseInputs<SiteSafetyTrainingDataType> =>
  (form: UseFormMethods<SiteSafetyTrainingDataType>): FormInput<SiteSafetyTrainingDataType>[] => {
    const { jobsiteWorker, filteredDocumentTypeIds, documents, defaultFormValues } = args;
    const { dirtyFields } = form.formState ?? {};

    useAcknowledgmentProgressUpdate(DocumentKey.JobsiteSafetyVideo, form);
    useAcknowledgmentProgressUpdate(DocumentKey.JobsiteSafetyDocument, form);

    return React.useMemo(() => {
      const fieldsConfig = getStepFieldsConfig(OnboardingStepKey.SiteSpecificOrientation, jobsiteWorker);

      const jobsiteConfigWorkerInfo = getFields<EditJobsiteConfigurationFormData['siteSpecificOrientation']>();
      const isStickerNumberRequired = fieldsConfig[jobsiteConfigWorkerInfo.stickerNumber]?.isRequired ?? false;

      const visibleDocuments = getVisibleDocuments(jobsiteWorker);

      const generateJobsiteWorkerDocumentIfAssigned = (
        docArgs: JobsiteWorkerDocumentArgs<SiteSafetyTrainingDataType>,
      ): FormInput<SiteSafetyTrainingDataType> | undefined => {
        return (
          filteredDocumentTypeIds[docArgs.documentKey] &&
          visibleDocuments?.includes(docArgs.documentKey) &&
          generateJobsiteWorkerDocument({
            ...docArgs,
            suppressRequiredWhenDocIsNotDirty: true,
            dirtyFields,
          })
        );
      };

      const withFilesDocumentIds = Object.fromEntries(
        documents
          .filter(({ key: doc }) =>
            defaultFormValues.some(
              ({ name: fieldName, value }) => value && [`${doc}-front`, `${doc}-back`].includes(fieldName),
            ),
          )
          .map(({ key, id }) => [key, id]),
      );

      const getPreviewUrl = (documentKey: string): string => {
        return getDocumentPreviewUrl(withFilesDocumentIds[documentKey]);
      };

      return ensureNonEmptyItems<FormInput<SiteSafetyTrainingDataType>>([
        {
          name: 'stickerNumber',
          element: FormInputTypes.Cleave,
          elementProps: {
            placeholder: 'Helmet sticker number',
            label: 'Helmet sticker number',
            cleaveType: 'number',
          },
          validation: { required: isStickerNumberRequired },
          layout: 'col-sm-6',
        },
        generateJobsiteWorkerDocumentIfAssigned({
          documentKey: DocumentKey.SiteSpecificOrientation,
          label: 'Site Specific Orientation',
          getPreviewUrl,
        }),
        generateJobsiteWorkerDocumentIfAssigned({
          documentKey: DocumentKey.JobsiteSafetyVideo,
          label: 'Worker Safety Video Acknowledgment',
          getPreviewUrl,
          requiredFields: ['front'],
          children: {
            stickerNumber: undefined,
            [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-status`]: {
              element: FormInputTypes.ToggleField,
              label: 'Worker has watched the video',
              elementProps: {
                toggleDescription: 'Worker completed in self-onboarding',
                disabled: true,
              },
              layout: 'col-sm-4',
            },
            [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-progress-fraction`]: {
              element: FormInputTypes.CustomInput,
              label: 'Acknowledgment progress',
              elementProps: { customInput: AcknowledgmentProgress },
              layout: 'col-sm-4',
            },
            [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-date`]: {
              element: FormInputTypes.Cleave,
              label: 'Acknowledgment date',
              elementProps: {
                placeholder: 'mm/dd/yyyy',
                cleaveType: 'date',
              },
              validation: { pattern: cleaveDateValidation, required: true },
              layout: 'col-sm-4',
            },
          },
        }),
        generateJobsiteWorkerDocumentIfAssigned({
          documentKey: DocumentKey.JobsiteSafetyDocument,
          label: 'Worker Safety Document Acknowledgment',
          getPreviewUrl,
          requiredFields: ['front'],
          children: {
            stickerNumber: undefined,
            [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-status`]: {
              element: FormInputTypes.ToggleField,
              label: 'Worker has read the document',
              elementProps: {
                toggleDescription: 'Worker completed in self-onboarding',
                disabled: true,
              },
              layout: 'col-sm-6',
            },
            [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-progress-fraction`]: {
              element: FormInputTypes.CustomInput,
              label: 'Acknowledgment progress',
              elementProps: { customInput: AcknowledgmentProgress },
              layout: 'odin-hidden',
            },
            [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-date`]: {
              element: FormInputTypes.Cleave,
              label: 'Acknowledgment date',
              elementProps: {
                placeholder: 'mm/dd/yyyy',
                cleaveType: 'date',
              },
              validation: { pattern: cleaveDateValidation, required: true },
              layout: 'col-sm-6',
            },
          },
        }),
        generateJobsiteWorkerDocumentIfAssigned({
          documentKey: DocumentKey.WorkerConsentDocument,
          label: 'Worker Consent Form',
          getPreviewUrl,
          requiredFields: ['front'],
          children: {
            stickerNumber: undefined,
            [`${DocumentKey.WorkerConsentDocument}-acknowledgment-status`]: {
              element: FormInputTypes.ToggleField,
              label: 'Worker has read the document',
              elementProps: {
                toggleDescription: 'Worker completed in self-onboarding',
                disabled: true,
              },
              layout: 'col-sm-6',
            },
            [`${DocumentKey.WorkerConsentDocument}-acknowledgment-date`]: {
              element: FormInputTypes.Cleave,
              label: 'Acknowledgment date',
              elementProps: {
                placeholder: 'mm/dd/yyyy',
                cleaveType: 'date',
              },
              validation: { pattern: cleaveDateValidation, required: true },
              layout: 'col-sm-6',
            },
          },
        }),
        generateJobsiteWorkerDocumentIfAssigned({
          documentKey: DocumentKey.AdditionalCertifications,
          label: 'Additional certifications',
          getPreviewUrl,
        }),
      ]);
    }, [jobsiteWorker, filteredDocumentTypeIds, documents, JSON.stringify(dirtyFields)]);
  };

type DefaultFormValue = JobsiteWorkerDocumentsData['defaultFormValues'][number];

export const getDefaultValues = (
  jobsiteWorker: JobsiteWorker,
  defaultFormValues: DefaultFormValue[],
): DefaultFormValue[] => {
  return Object.entries({
    [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-status`]: false,
    [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-date`]: '',
    [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-progress-fraction`]: '',
    [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-status`]: false,
    [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-date`]: '',
    [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-progress-fraction`]: '',
    [`${DocumentKey.WorkerConsentDocument}-acknowledgment-status`]: false,
    [`${DocumentKey.WorkerConsentDocument}-acknowledgment-date`]: '',
    ...Object.fromEntries(defaultFormValues.map((dv) => [dv.name, dv.value])),
    stickerNumber: jobsiteWorker?.stickerNumber?.toString() ?? '',
  }).map(([name, value]) => ({ name, value }));
};
