import React, { useState, ReactElement } from 'react';
import { GetJobsiteIncidentsDocument, useJobsiteIncidentCreateMutation } from 'apollo/generated/client-operations';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertInstance } from 'components/alertNotification';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { AuthContext } from 'auth';
import { getJobsitesOptions } from 'containers/users/modals/utils';
import { getDefaultValues, getFormInputs } from 'containers/jobsiteSafety/helpers/forms';
import { AddJobsiteIncidentFormData } from 'containers/jobsiteSafety/types';
import { getDateTime } from 'utils/dates';
import { AddJobsiteIncidentModalProps } from './types';

export function AddJobsiteIncidentModal(props: AddJobsiteIncidentModalProps): ReactElement {
  const { isOpen, jobsites, onCancel, onConfirm } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [createJobsiteIncident] = useJobsiteIncidentCreateMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetJobsiteIncidentsDocument],
  });

  // const onSubmit = (data: AddJobsiteIncidentFormData): void => {
  const onSubmit: FormOnSubmit<AddJobsiteIncidentFormData> = async (data, event): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const result = await createJobsiteIncident({
        variables: {
          input: {
            name: data.name,
            incidentType: data.incidentType?.map((option) => option.value),
            summary: data.summary,
            jobsiteId: data.jobsiteId.value,
            exactLocation: data.exactLocation,
            incidentDateTime: getDateTime({ date: data.incidentDate, time: data.incidentTime }),
            reporterId: user.userAccountId,
            lightingConditions: '',
          },
        },
      });

      AlertInstance.alert('tc', 'success', 'Success', 'Jobsite incident created');
      onConfirm(result.data.jobsiteIncidentCreate.payload);
    } catch (error) {
      event.preventDefault();
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  const jobsiteOptions = getJobsitesOptions(jobsites);
  const formInputs = getFormInputs(jobsiteOptions);
  const defaultValues = getDefaultValues(jobsiteOptions);

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Add Incident Report"
      actionText="Save"
      actionButtonWithSpinner={isSaving}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
    />
  );
}
