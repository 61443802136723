import React from 'react';
import { Dialog } from 'dialog';
import { useUpdatableState } from 'utils';
import { IncidentParticipantEdit, OnParticipantConfirmed } from 'containers/jobsiteSafetyIncident/types';
import { JobsiteIncidentWorkerEditModalProps } from './types';
import { JobsiteIncidentEditWorkerForm } from './JobsiteIncidentWorkerEditForm';
import { JobsiteIncidentWorkerEditSearch } from './JobsiteIncidentWorkerEditSearch';
import { getDefaultParticipantEdit } from './helpers/utils';

export function JobsiteIncidentWorkerEditModal(props: JobsiteIncidentWorkerEditModalProps): React.ReactElement {
  const { isOpen, closeModal, onConfirm, jobsiteIncident, participant } = props;
  const defaultParticipantEdit = React.useMemo(() => getDefaultParticipantEdit(participant), [participant]);
  const [participantEdit, setParticipantEdit] = useUpdatableState<IncidentParticipantEdit | null>(
    defaultParticipantEdit,
  );

  const confirmParticipantAndResetState: OnParticipantConfirmed = React.useCallback(
    (...args) => {
      onConfirm(...args);
      setParticipantEdit(null);
    },
    [onConfirm, setParticipantEdit],
  );

  const closeModalAndResetState = React.useCallback(() => {
    closeModal();
    setParticipantEdit(null);
  }, [closeModal, setParticipantEdit]);

  const getContent = (): React.ReactElement => {
    if (participantEdit) {
      return (
        <JobsiteIncidentEditWorkerForm
          jobsiteIncident={jobsiteIncident}
          participantEdit={participantEdit}
          onConfirm={confirmParticipantAndResetState}
          onCancel={(): void => setParticipantEdit(null)}
        />
      );
    }
    return (
      <JobsiteIncidentWorkerEditSearch
        jobsiteIncident={jobsiteIncident}
        onSelected={(searchWorkerResult): void =>
          setParticipantEdit({
            id: searchWorkerResult.id,
            selectedJobsiteWorker: searchWorkerResult.jobsitesInfo[0]?.jobsiteWorker,
            participantType: null,
            jobsitesInfo: searchWorkerResult.jobsitesInfo,
            editType: 'create',
            searchWorkerResult,
          })
        }
      />
    );
  };

  return (
    <Dialog
      id="editJobsiteIncidentParticipant"
      open={isOpen}
      setOpen={closeModalAndResetState}
      title="Add Worker"
      actionsPanel={null}
    >
      {getContent()}
    </Dialog>
  );
}
