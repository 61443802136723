import { Avatar, Badge } from '@odin-labs/components';
import cn from 'classnames';
import { EditableIncidentParticipant } from 'containers/jobsiteSafetyIncident/types';
import React from 'react';
import { getInitialsForUser } from 'utils';

export const classes = {
  itemContainer: (isClickable: boolean): string =>
    cn(
      isClickable && 'odin-cursor-pointer',
      'odin-flex odin-flex-col odin-grow',
      'odin-border odin-border-gray-200 odin-shadow-sm odin-rounded-lg',
      'odin-p-6 odin-space-y-2',
      'sm:odin-flex-row sm:odin-justify-between sm:odin-space-x-4',
    ),
  workerInfoRow: cn('odin-flex odin-flex-col', 'odin-space-y-1.5', 'sm:odin-flex-row sm:odin-space-x-6'),
  workerNameContainer: cn('odin-flex'),
  workerName: cn('odin-py-1', 'odin-ml-3', 'odin-text-base', 'odin-font-medium', 'odin-text-gray-900', 'odin-truncate'),
  workerInfoContainer: cn(
    'odin-grid',
    'odin-grid-cols-2',
    'odin-gap-y-1.5',
    'odin-ml-11',
    'sm:odin-flex',
    'sm:odin-space-x-4',
  ),
  workerInfoItem: cn('worker-info-item-container', 'odin-flex', 'odin-items-center', 'odin-text-2xs', 'odin-truncate'),
  workerInfoItemIcon: (icon: string): string =>
    cn(icon, 'worker-info-item-icon', 'odin-text-odin-primary', 'odin-text-sm', 'odin-font-normal', 'odin-mr-1.5'),
  participantTypeContainer: cn('odin-space-x-5'),
  removeIcon: (icon: string): string =>
    cn(
      icon,
      'worker-info-item-icon',
      'odin-text-gray-600',
      'odin-w-5 odin-h-5',
      'odin-cursor-pointer',
      'hover:odin-text-red-600',
    ),
};

export type JobsiteIncidentWorkerProps = {
  participant: EditableIncidentParticipant;
  onRemoveIconClick?: () => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export function JobsiteIncidentWorker(props: JobsiteIncidentWorkerProps): React.ReactElement {
  const { participant, onRemoveIconClick, onClick } = props;
  const { selectedJobsiteWorker, participantType } = participant;
  const {
    contractorWorker: { worker },
    jobsiteContractor: {
      jobsite: { name: jobsiteName },
    },
    stickerNumber: badgeId,
  } = selectedJobsiteWorker ?? {};
  const { user, quickCode, profilePicture } = worker ?? {};
  const { firstName, lastName } = user?.identity ?? {};
  const { downloadUrl: imageUrl } = profilePicture ?? {};

  return (
    <div className={classes.itemContainer(!!onClick)} onClick={onClick}>
      <div className={classes.workerInfoRow}>
        <div className={classes.workerNameContainer}>
          <Avatar size="sm" src={imageUrl} placeholder={getInitialsForUser(user)} objectFit="cover" />
          <div className={classes.workerName}>
            {firstName} {lastName}
          </div>
        </div>
        <div className={classes.workerInfoContainer}>
          <div className={classes.workerInfoItem}>
            <i className={classes.workerInfoItemIcon('fal fa-shield-check')} aria-hidden="true" />
            {quickCode}
          </div>
          <div className={classes.workerInfoItem}>
            <i className={classes.workerInfoItemIcon('fal fa-id-badge')} aria-hidden="true" />
            {badgeId}
          </div>
          <div className={classes.workerInfoItem}>
            <i className={classes.workerInfoItemIcon('fal fa-briefcase')} aria-hidden="true" />
            {jobsiteName}
          </div>
        </div>
      </div>
      <div className={classes.participantTypeContainer}>
        <Badge size="lg" text={participantType} color="red" />
        <i
          className={classes.removeIcon('fal fa-times')}
          onClick={(ev): void => {
            onRemoveIconClick();
            ev.stopPropagation();
          }}
          aria-hidden="true"
        />
      </div>
    </div>
  );
}
