import React from 'react';
import classNames from 'classnames/bind';
import { pdfjs, Document, Page } from 'react-pdf';
import { FilePdfIcon } from 'components/icons';
import { ThumbnailSkeleton } from './ThumbnailSkeleton';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import styles from './scss/pdf-preview.scss';

const cx = classNames.bind(styles);

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

export type PdfPreviewProps = {
  fileURL: string;
  alt: string;
  className?: string;
  isThumbnail?: boolean;
};

export function PdfPreview(props: PdfPreviewProps): React.ReactElement {
  const { fileURL, className, isThumbnail } = props;
  const [pagesCount, setPagesCount] = React.useState(null);

  const onDocumentLoadSuccess = React.useCallback(
    ({ numPages }: { numPages: number }): void => {
      setPagesCount(numPages);
    },
    [setPagesCount],
  );

  const classes = cx(
    'pdf-preview pdf-preview--full-width',
    isThumbnail ? 'pdf-preview--full-height' : 'pdf-preview--auto-height',
    className,
  );

  return (
    <Document
      file={fileURL}
      options={options}
      className={classes}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={ThumbnailSkeleton}
      error={<FilePdfIcon className="odin-h-full odin-text-odin-primary odin-mx-auto" />}
    >
      {isThumbnail ? (
        <Page pageNumber={1} />
      ) : (
        [...new Array(pagesCount)].map((_, index) => <Page key={`page_${index + 1}`} pageNumber={index + 1} />)
      )}
    </Document>
  );
}
