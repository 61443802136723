import moment from 'moment';
import cn from 'classnames';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import { FormInputTypes, getUpdateInputValueFunction, TypedFormInputs } from 'components/form';
import { incidentTypeOptions } from 'containers/jobsiteSafety/helpers/utils';
import { getDateTime, momentISODateFormatter, momentTimeFormatter } from 'utils/dates';
import { getIcon } from 'utils/ui';
import { JobsiteIncidentUpdateInput } from 'apollo/generated/client-operations';
import { EditJobsiteIncidentFormData, JobsiteIncident } from 'containers/jobsiteSafetyIncident/types';
import { timeValidation } from 'utils/validation';

export const getSummarySectionInputs = ({
  jobsiteOptions,
}: {
  jobsiteOptions: SelectOptionElement[];
}): TypedFormInputs<EditJobsiteIncidentFormData['summary']> => ({
  name: {
    element: FormInputTypes.OdinField,
    label: 'Report Name',
    validation: {
      required: true,
    },
    layout: cn('odin-col-span-3'),
  },
  incidentType: {
    element: FormInputTypes.OdinSelect,
    label: 'Incident Type',
    elementProps: {
      options: incidentTypeOptions,
      multiple: true,
      clearToNull: true,
    },
    validation: {
      required: true,
    },
    layout: cn('odin-col-span-3'),
  },
  incidentDate: {
    element: FormInputTypes.NewDatePicker,
    label: 'Date of Incident',
    elementProps: {
      maxDate: moment(),
      // minDate: moment().add(-1, 'week'),
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    validation: {
      required: true,
    },
    layout: cn('odin-col-span-3'),
  },
  incidentTime: {
    element: FormInputTypes.OdinField,
    label: 'Time of Incident',
    elementProps: {
      placeholder: 'hh:mm',
      fieldType: 'time',
      showDefaultIcon: true,
    },
    validation: {
      required: true,
      pattern: timeValidation,
    },
    layout: cn('odin-col-span-3'),
  },
  jobsiteId: {
    element: FormInputTypes.OdinSelect,
    label: 'Jobsite',
    elementProps: {
      placeholder: 'Select jobsite',
      options: jobsiteOptions,
      icon: getIcon('odin-text-odin-primary fal fa-briefcase'),
      disabled: (jobsiteOptions?.length ?? 0) < 2,
    },
    validation: {
      required: true,
    },
    layout: cn('odin-col-span-3'),
  },
  exactLocation: {
    element: FormInputTypes.OdinField,
    label: 'Exact Location',
    validation: {
      required: true,
    },
    layout: cn('odin-col-span-3'),
  },
  summary: {
    element: FormInputTypes.OdinTextAreaField,
    label: 'Description',
    layout: cn('odin-col-span-6'),
  },
});

export const getSummarySectionDefaultValues = (
  jobsiteIncident: JobsiteIncident,
  jobsiteOptions: SelectOptionElement[],
): EditJobsiteIncidentFormData['summary'] => {
  const jobsiteIncidentMoment = (jobsiteIncident?.incidentDateTime || null) && moment(jobsiteIncident.incidentDateTime);
  return {
    name: jobsiteIncident?.name ?? '',
    incidentType: incidentTypeOptions.filter((incidentType) =>
      jobsiteIncident?.incidentType?.includes(incidentType.value),
    ),
    summary: jobsiteIncident?.summary ?? '',
    jobsiteId: jobsiteOptions?.find((jobsiteOption) => jobsiteOption.value === jobsiteIncident?.jobsite?.jobsiteId),
    exactLocation: jobsiteIncident?.exactLocation ?? '',
    incidentDate: jobsiteIncidentMoment?.format(momentISODateFormatter),
    incidentTime: jobsiteIncidentMoment?.format(momentTimeFormatter) ?? '',
  };
};

type SummarySectionUpdateInput = Required<
  Pick<
    JobsiteIncidentUpdateInput,
    'name' | 'incidentType' | 'summary' | 'jobsiteId' | 'exactLocation' | 'incidentDateTime'
  >
>;

export const getSummarySectionUpdateInput = (
  summary: EditJobsiteIncidentFormData['summary'],
  dirtyFields: DeepMap<EditJobsiteIncidentFormData['summary'], true>,
): SummarySectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(summary, dirtyFields);

  const getIncidentDateTimeInput = (): Date => {
    const incidentDate = getUpdateInputValue('incidentDate');
    const incidentTime = getUpdateInputValue('incidentTime');
    return incidentDate || incidentTime
      ? getDateTime({ date: summary.incidentDate, time: summary.incidentTime })
      : undefined;
  };

  return {
    name: getUpdateInputValue('name'),
    incidentType: getUpdateInputValue('incidentType'),
    summary: getUpdateInputValue('summary'),
    jobsiteId: getUpdateInputValue('jobsiteId'),
    exactLocation: getUpdateInputValue('exactLocation'),
    incidentDateTime: getIncidentDateTimeInput(),
  };
};
