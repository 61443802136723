import React from 'react';
import cn from 'classnames';
import { JobsiteIncidentParticipant } from 'containers/jobsiteSafetyIncident/print/types';
import { JobsiteWorkerCard } from 'components/jobsiteWorkerCard/JobsiteWorkerCard';
import { JobsiteWorker, Worker } from 'containers/worker/types';
import { ReportSectionTitle } from 'containers/jobsiteSafetyIncident/print/components/reportSectionTitle';

export const classes = {
  container: cn('odin-flex odin-flex-col odin-space-y-6'),
};

export type WorkerReportJobsitesProps = {
  jobsiteWorkerAccesses: JobsiteIncidentParticipant['jobsiteWorkerAccess'];
};

export function WorkerReportJobsites(props: WorkerReportJobsitesProps): React.ReactElement {
  const { jobsiteWorkerAccesses } = props;

  return (
    <div className={classes.container}>
      <ReportSectionTitle title="Jobsites" count={jobsiteWorkerAccesses.length} />
      {jobsiteWorkerAccesses?.map((jwa): React.ReactElement => {
        const { jobsiteContractor, contractorWorker, ...restJobsiteWorker } = jwa.jobsiteWorker;
        const { contractor, worker } = contractorWorker;
        const jobsiteWorker: JobsiteWorker = {
          ...restJobsiteWorker,
          jobsiteContractor: {
            ...jobsiteContractor,
            contractor,
          },
          currentAccess: {
            isAllowed: jwa.isAllowedNow,
            notAllowedReason: jwa.notAllowedReason,
            endDate: jwa.endDate,
          },
        };

        return (
          <JobsiteWorkerCard
            worker={worker as Worker}
            jobsiteWorker={jobsiteWorker}
            key={`${jobsiteWorker?.jobsiteWorkerId}-worker-card`}
            disableActions
          />
        );
      })}
    </div>
  );
}
