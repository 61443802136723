import React from 'react';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItemConfig,
  NewMenuItemProps,
} from '@odin-labs/components';
import { ensureNonEmptyItems, getInitialsForUser } from 'utils';

import { JobsiteWorker, JobsiteWorkerColumn, JobsiteWorkersFilters } from './types';

// Worker
export const getColumns = (menuItems?: NewMenuItemProps[]): JobsiteWorkerColumn[] =>
  ensureNonEmptyItems<JobsiteWorkerColumn>([
    {
      id: 'worker',
      Header: 'Worker',
      accessor: (jobsiteWorker: JobsiteWorker): string => {
        const { firstName, lastName } = jobsiteWorker.contractorWorker.worker.user.identity ?? {};
        return [firstName, lastName].join(' ');
      },
      Cell: AvatarCell,
      CellLoading: <AvatarSkeleton size="xs" hideDetails />,
      componentProps: (jobsiteWorker: JobsiteWorker): AvatarCellComponentProps => ({
        src: jobsiteWorker.contractorWorker.worker.profilePictureCropped?.downloadUrl,
        placeholder: getInitialsForUser(jobsiteWorker.contractorWorker.worker.user),
        objectFit: 'cover',
        size: 'xs',
      }),
      disableSortBy: true,
    },
    {
      id: 'quickCode',
      Header: <>Quick&nbsp;Code</>,
      accessor: (jobsiteWorker: JobsiteWorker): string => jobsiteWorker.contractorWorker.worker.quickCode,
      disableSortBy: true,
    },
    {
      id: 'badgeNumber',
      Header: <>Badge&nbsp;Numbers</>,
      accessor: (jobsiteWorker: JobsiteWorker): string =>
        jobsiteWorker.contractorWorker.worker.workerCards.edges.map(({ node }) => node.cardNumber).join(', '),
      disableSortBy: true,
    },
    {
      id: 'jobsiteAssignments',
      Header: 'Jobsite Assignments',
      accessor: (): string[] => [],
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (jobsiteWorker: JobsiteWorker): ChipsCellComponentProps => ({
        chips: jobsiteWorker.contractorWorker.worker.jobsiteWorkers.edges.map(({ node }) => ({
          text: node.jobsiteContractor.jobsite.name,
          secondaryText: node.jobsiteContractor.contractor.organization.name,
          isActive: node.currentAccess.isAllowed,
        })),
      }),
      disableSortBy: true,
    },
    menuItems?.length && {
      Header: '',
      id: 'actions',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems,
      }),
    },
  ]);

export const getFilterItems = ({
  search,
}: {
  search: string;
}): { [key in keyof JobsiteWorkersFilters]: FilterItemConfig } => ({
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});
