import moment from 'moment-timezone';
import React from 'react';
import { Moment } from 'moment';
import { faIdBadge } from '@fortawesome/pro-light-svg-icons';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  BadgeCell,
  BadgeCellComponentProps,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  Column,
  DropdownFilterOption,
  FilterItems,
  getFaIcon,
} from '@odin-labs/components';
import { JobsiteAccessEventRejectionReason, JobsiteAccessEventStatus } from 'apollo/generated/client-operations';
import {
  JobsiteAccessActivityFilters,
  JobsiteAccessEvent,
  JobsiteAccessEventsFiltersOptions,
} from 'containers/jobsiteAccessActivity/types';
import { camelToTitleCase, getInitialsForUser, getUserFullName } from 'utils';
import { DateTimeCell, DateTimeCellData, DirectionGatewayCell, statusColor, statusText } from './cells';

export type JobsiteAccessEventColumn = Column<JobsiteAccessEvent>;

export const rejectionReasonDescriptions = Object.fromEntries(
  Object.keys(JobsiteAccessEventRejectionReason).map((key) => [key, camelToTitleCase(key)]),
);

const IdBadgeIcon = getFaIcon({
  icon: faIdBadge,
  className: 'odin-text-odin-primary odin-mr-1 md:odin-hidden',
});

export const getColumns = (): JobsiteAccessEventColumn[] => [
  {
    id: 'worker',
    Header: 'Worker',
    accessor: (jobsiteAccessEvent: JobsiteAccessEvent): string => getUserFullName(jobsiteAccessEvent.worker?.user),
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size={['md', 'xl:xs']} hideDetails />,
    componentProps: (jobsiteAccessEvent: JobsiteAccessEvent): AvatarCellComponentProps => ({
      src: jobsiteAccessEvent.worker?.profilePictureCropped?.downloadUrl,
      placeholder: getInitialsForUser(jobsiteAccessEvent.worker?.user),
      objectFit: 'cover',
      size: ['md', 'xl:xs'],
    }),
  },
  {
    id: 'jobsiteContractor',
    Header: 'Jobsite / Contractor',
    accessor: (): string[] => [],
    Cell: ChipsCell,
    CellLoading: ChipsSkeleton,
    componentProps: (jobsiteAccessEvent: JobsiteAccessEvent): ChipsCellComponentProps => ({
      chips: [
        {
          text: jobsiteAccessEvent.jobsite?.name,
          secondaryText: jobsiteAccessEvent.jobsiteWorker?.jobsiteContractor.contractor.organization.name,
          isActive: jobsiteAccessEvent.jobsiteWorker?.currentAccess.isAllowed ?? false,
        },
      ],
    }),
  },
  {
    id: 'dateTime',
    Header: 'Date / Time',
    accessor: (jobsiteAccessEvent: JobsiteAccessEvent): DateTimeCellData => {
      const { eventOccurredAt, timeZone } = jobsiteAccessEvent ?? {};
      const timeZoneName = moment.tz(eventOccurredAt, timeZone).zoneAbbr();
      return eventOccurredAt && { date: new Date(eventOccurredAt), timeZoneName };
    },
    Cell: DateTimeCell,
  },
  {
    id: 'gateway',
    Header: 'Direction / Gateway',
    accessor: (jobsiteAccessEvent: JobsiteAccessEvent): JobsiteAccessEvent => jobsiteAccessEvent,
    Cell: DirectionGatewayCell,
  },
  {
    id: 'status',
    Header: 'Status',
    Cell: BadgeCell,
    componentProps: ({ status, rejectionReason }: JobsiteAccessEvent): BadgeCellComponentProps => ({
      text: statusText[status],
      supportingText: rejectionReasonDescriptions[rejectionReason],
      color: statusColor[status],
      size: 'lg',
    }),
  },
  {
    id: 'cardNumber',
    Header: 'Card Number',
    accessor: (jobsiteAccessEvent: JobsiteAccessEvent & { credentialId?: string }): string =>
      jobsiteAccessEvent?.credentialId,
    Cell: ({ value: credentialId }: { value: string }): React.ReactElement =>
      credentialId && (
        <span className="odin-text-sm odin-text-gray-700 md:odin-text-gray-900">
          <IdBadgeIcon />
          <span>{credentialId}</span>
        </span>
      ),
  },
];

export const eventStatusOptions: DropdownFilterOption[] = [
  {
    value: JobsiteAccessEventStatus.Admit,
    label: 'Admitted',
  },
  {
    value: JobsiteAccessEventStatus.Reject,
    label: 'Rejected',
  },
];

export const getFilterItems = ({
  filtersOptions,
  jobsiteIds,
  contractorIds,
  gatewayIds,
  rejectionReasons,
  eventStatus,
  startDate,
  startTime,
  endDate,
  endTime,
  search,
}: {
  filtersOptions: JobsiteAccessEventsFiltersOptions;
  jobsiteIds: string[];
  contractorIds: string[];
  gatewayIds: string[];
  rejectionReasons: string[];
  eventStatus: string;
  startDate: Moment | string;
  startTime: string;
  endDate: Moment | string;
  endTime: string;
  search: string;
}): FilterItems<JobsiteAccessActivityFilters> => ({
  startDate: {
    header: '',
    type: 'datePicker',
    defaultValue: startDate,
  },
  startTime: {
    header: '',
    type: 'timePicker',
    defaultValue: startTime,
  },
  endDate: {
    header: '',
    type: 'datePicker',
    defaultValue: endDate,
  },
  endTime: {
    header: '',
    type: 'timePicker',
    defaultValue: endTime,
    isLineBreaker: true,
  },
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options: filtersOptions?.jobsites ?? [],
    },
  },
  contractorIds: {
    header: 'Contractor',
    type: 'dropdown',
    defaultValue: contractorIds,
    componentProps: {
      options: filtersOptions?.contractors ?? [],
      isVirtual: true,
    },
  },
  gatewayIds: {
    header: 'Gateway',
    type: 'dropdown',
    defaultValue: gatewayIds,
    componentProps: {
      options: filtersOptions?.gateways ?? [],
    },
  },
  eventStatus: {
    header: 'Status',
    type: 'dropdown',
    defaultValue: eventStatus,
    componentProps: {
      options: eventStatusOptions,
      isMulti: false,
    },
  },
  rejectionReasons: {
    header: 'Rejection Reason',
    type: 'dropdown',
    defaultValue: rejectionReasons,
    componentProps: {
      options: filtersOptions?.rejectionReasons ?? [],
    },
  },
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});
