import React from 'react';
import { JobsiteIncident } from 'apollo/generated/client-operations';
import { SearchWorkerResult } from 'components/searchBox/types';
import { JobsiteIncidentWorkerEditSearchBox } from './JobsiteIncidentWorkerEditSearchBox';
import { JobsiteIncidentWorkerEditSearchResults } from './JobsiteIncidentWorkerEditSearchResults';
import { useWorkerSearchData } from './useWorkerSearchData';
import { JobsiteIncidentWorkerEditSearchInfo } from './JobsiteIncidentWorkerEditSearchInfo';
import { JobsiteIncidentWorkerEditSearchInProgress } from './JobsiteIncidentWorkerEditSearchInProgress';

export type JobsiteIncidentWorkerEditSearchProps = {
  jobsiteIncident: JobsiteIncident;
  onSelected: (searchWorkerResult: SearchWorkerResult) => void;
};

export function JobsiteIncidentWorkerEditSearch(props: JobsiteIncidentWorkerEditSearchProps): React.ReactElement {
  const { jobsiteIncident, onSelected } = props;
  const { searchWorkers, searchWorkerResults, loading } = useWorkerSearchData(jobsiteIncident);
  const [searchValue, setSearchValue] = React.useState<string>('');

  const onSearchTermChange = React.useCallback(
    (searchTerm: string): void => {
      searchWorkers(searchTerm);
      setSearchValue(searchTerm);
    },
    [searchWorkers, setSearchValue],
  );

  const getContent = (): React.ReactElement => {
    if (loading) {
      return <JobsiteIncidentWorkerEditSearchInProgress />;
    }

    if (searchValue) {
      return (
        <JobsiteIncidentWorkerEditSearchResults searchWorkerResults={searchWorkerResults} onSelected={onSelected} />
      );
    }

    return <JobsiteIncidentWorkerEditSearchInfo />;
  };

  return (
    <div className="odin-space-y-6">
      <JobsiteIncidentWorkerEditSearchBox onSearchTermChange={onSearchTermChange} />
      {getContent()}
    </div>
  );
}
