import React from 'react';
import classNames from 'classnames/bind';
import { usePlacesWidget } from 'react-google-autocomplete';
import { appConfig } from 'config/app';
import { Field } from '@odin-labs/components';
import { setRefFactory } from 'components/utils';
import { Place, NewPlacesAutocompleteProps } from './types';
import styles from './scss/places-autocomplete.scss';
import { getApiKeyWithCallback } from './utils';

const cx = classNames.bind(styles);

export const NewPlacesAutocomplete = React.forwardRef(
  (props: NewPlacesAutocompleteProps, ref: React.ForwardedRef<HTMLInputElement>): React.ReactElement => {
    const { onCommit, className, ...rest } = props;

    const onPlaceSelectedHandler = (place: Place): void => {
      onCommit?.(place);
    };

    const { ref: placesInputRef } = usePlacesWidget<HTMLInputElement>({
      apiKey: getApiKeyWithCallback(appConfig.googleMapsApiKey),
      onPlaceSelected: onPlaceSelectedHandler,
      options: {
        types: ['geocode', 'establishment'],
        fields: ['address_components', 'formatted_address'],
        componentRestrictions: { country: 'us' },
      },
    });

    const setRef = setRefFactory<HTMLInputElement>({ innerRef: placesInputRef, outerRef: ref });

    return <Field {...rest} ref={setRef} className={cx('places-autocomplete', className)} />;
  },
);
