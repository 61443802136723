import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Table, TableContainer } from '@odin-labs/components';
import { momentFormatter } from 'utils';
import { useUpdateQueryStatus } from 'containers/jobsiteSafetyIncident/print/helpers/useUpdateQueryStatus';
import { ReportSectionTitle } from 'containers/jobsiteSafetyIncident/print/components/reportSectionTitle';
import { getJobsiteIncidentAccessEventsInterval } from 'containers/jobsiteSafetyIncident/print/helpers/utils';
import { useGetJobsiteSafetyIncidentWorkerAccessEventsQuery } from 'apollo/generated/client-operations';
import { Worker } from 'containers/worker/types';
import { getColumns, WorkerAccessEventColumn } from './helpers/tables';

const classes = {
  container: cn('print:odin-break-inside-avoid'),
};

export type WorkerReportAccessEventsProps = {
  reportDateTime: moment.Moment;
  worker: Worker;
  jobsiteIds: string[];
};

export function WorkerReportAccessEvents(props: WorkerReportAccessEventsProps): React.ReactElement {
  const { reportDateTime, worker, jobsiteIds } = props;

  const { startMoment, endMoment } = getJobsiteIncidentAccessEventsInterval(reportDateTime);

  const { data, loading } = useGetJobsiteSafetyIncidentWorkerAccessEventsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      workerId: worker.workerId,
      includeArchived: worker.isArchived,
      workerJobsiteAccessEventsInput: {
        paginationInput: { limit: 50, offset: 0 },
        jobsiteIds,
        startDateTime: startMoment?.toDate(),
        endDateTime: endMoment?.toDate(),
      },
    },
  });

  useUpdateQueryStatus(`workerAccessEvents-${worker.workerId}`, { loading });

  const workerAccessEvents = data?.getWorker?.jobsiteAccessEvents.edges.map((w) => w.node) ?? [];

  const columns = React.useMemo<WorkerAccessEventColumn[]>(() => getColumns(), []);

  const datesText = [startMoment.format(momentFormatter), endMoment.format(momentFormatter)].join(' - ');

  return (
    <TableContainer className={classes.container}>
      <ReportSectionTitle title="Jobsite Access Events" count={workerAccessEvents.length} dates={datesText} />
      <Table loading={loading} columns={columns} data={workerAccessEvents} remote disableGlobalFilter disableSortBy />
    </TableContainer>
  );
}
