import React from 'react';
import cn from 'classnames';
import { JobsiteIncident } from 'containers/jobsiteSafetyIncident/print/types';
import { WeatherDetails } from './WeatherDetails';

export const classes = {
  container: cn('sm:odin-grid sm:odin-grid-cols-3 odin-gap-x-5'),
  detailContainer: cn('odin-space-y-3'),
  detailLabel: cn('odin-text-sm odin-font-medium odin-text-gray-900'),
  detailValue: cn('odin-text-sm odin-font-normal odin-break-words'),
};

export type JobsiteReportDetailProps = React.PropsWithChildren<{
  label: string;
  value?: string | JSX.Element | JSX.Element[];
}>;

function JobsiteReportDetail(props: JobsiteReportDetailProps): React.ReactElement {
  const { label, value, children } = props;
  return (
    <div className={classes.detailContainer}>
      <div className={classes.detailLabel}>{label}</div>
      <div className={classes.detailValue}>{value ?? children}</div>
    </div>
  );
}

export type JobsiteReportDetailsProps = {
  jobsiteIncident: JobsiteIncident;
};

export function JobsiteReportDetails(props: JobsiteReportDetailsProps): React.ReactElement {
  const { jobsiteIncident } = props;
  const { summary, exactLocation, weatherConditions } = jobsiteIncident;
  const weatherConditionsValue = <WeatherDetails weatherConditions={weatherConditions} />;

  return (
    <div className={classes.container}>
      <JobsiteReportDetail label="Incident Description" value={summary} />
      <JobsiteReportDetail label="Exact Location" value={exactLocation} />
      <JobsiteReportDetail label="Weather during Incident" value={weatherConditionsValue} />
    </div>
  );
}
