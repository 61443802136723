import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Filter, NewMenuItemProps, Table, TableContainer, getFaIcon } from '@odin-labs/components';
import { ensureNonEmptyItems, useBoolean } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { NewHeader } from 'components/header/NewHeader';
import { LoadingError } from 'components/loadingError';

import { useGetContractorsContainerCurrentSessionQuery } from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { Container } from 'components/container';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { getColumns, getFilterItems, ContractorColumn } from './helpers/tables';
import { Contractor, ContractorsFilters } from './types';
import { AddContractorModal } from './modals';

const contractorProfileUrl = '/contractor/';
const PlusIcon = getFaIcon({ icon: faPlus });

export function ContractorsContainer(): ReactElement {
  const history = useHistory();
  const { currentUser: user } = React.useContext(AuthContext);

  const {
    page,
    jobsiteIds,
    search,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams({ updateJobsiteSelection: true, includeAllJobsites: true });
  const offset = page * limit;

  const {
    value: isAddContractorModalOpen,
    setTrue: openAddContractorModal,
    setFalse: closeAddContractorModal,
  } = useBoolean(false);

  const {
    jobsites,
    loading: userJobsitesLoading,
    error: userJobsitesError,
  } = useAvailableJobsites({ includeAllJobsites: true });

  const {
    data: contractorsData,
    loading: contractorsLoading,
    error: contractorsError,
    refetch,
  } = useGetContractorsContainerCurrentSessionQuery({
    fetchPolicy: 'no-cache',
    skip: isUrlLoading,
    notifyOnNetworkStatusChange: true,
    variables: {
      userContractorsInput: {
        paginationInput: { limit, offset },
        jobsiteIds,
        search,
      },
    },
  });

  const { user: userData } = contractorsData?.getCurrentSession ?? {};
  const contractors = userData?.contractors.edges.map((c) => c.node);

  const contractorsCount = userData?.contractors.count ?? 0;
  const pageCount = Math.ceil(contractorsCount / limit);

  const columns = React.useMemo<ContractorColumn[]>(() => getColumns(), []);

  const filterItems = React.useMemo(
    () => getFilterItems({ jobsites, jobsiteIds, search }),
    [jobsites, jobsiteIds, search],
  );

  const loading = contractorsLoading || userJobsitesLoading;
  const error = contractorsError || userJobsitesError;

  if (error) {
    return <LoadingError error={error} />;
  }

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<ContractorsFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = ({ data: contractor }: { data: Contractor }): void => {
    history.push(`${contractorProfileUrl}${contractor.contractorId}`);
  };

  const menuItems = ensureNonEmptyItems<NewMenuItemProps>([
    user.isAllowed(to.addContractors) && {
      text: 'Add Contractor',
      icon: PlusIcon,
      onClick: openAddContractorModal,
    },
  ]);

  return (
    <Container className="contractors-container">
      <NewHeader
        title="Contractors"
        titleInfo={contractorsCount}
        actionsProps={{ menuItems, onReloadPressed: refetch }}
      />
      <TableContainer>
        <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={contractors}
          initialState={{ pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          disableGlobalFilter
          disableSortBy
        />
      </TableContainer>
      <AddContractorModal
        isOpen={isAddContractorModalOpen}
        onCancel={closeAddContractorModal}
        onConfirm={(newContractor): void => {
          closeAddContractorModal();
          history.push(`${contractorProfileUrl}${newContractor.contractorId}/info`);
        }}
      />
    </Container>
  );
}
