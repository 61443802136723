import { appConfig } from 'config/app';
import type { MomentInput } from 'moment';
import moment from 'moment';

export const cleaveFormatter = 'MMDDYYYY';
export const momentFormatter = 'MM/DD/YYYY';
export const momentISODateFormatter = 'YYYY-MM-DD';
export const momentISODateTimeFormatter = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const momentPrettyDateTimeFormatter = 'MM/DD/YYYY h:mm:ss A';
export const momentPrettyShortDateTimeFormatter = 'MM/DD/YYYY h:mm A';
export const momentTimeFormatter = 'hh:mm A';
export const momentDateTimeInputFormat = 'YYYY-MM-DD HH:mm:ss.SSS A';

export const getCurrentISOFormattedDate = (): string => {
  return moment().format(momentISODateFormatter);
};

export const getCurrentISOFormattedDateTime = (): string => {
  return moment().format(momentISODateTimeFormatter);
};

export const getPrettyFormattedDateTime = (date: MomentInput): string => {
  if (!date) return null;
  return moment(date).format(momentPrettyDateTimeFormatter);
};

export const getPrettyFormattedShortDateTime = (date: MomentInput): string => {
  if (!date) return null;
  return moment(date).format(momentPrettyShortDateTimeFormatter);
};

export const getPrettyFormattedDateTimeWithTz = (date: MomentInput, timeZone: string, isUTC?: boolean): string => {
  if (!date) return null;
  const createMoment = isUTC ? moment.utc : moment;
  return `${createMoment(date).tz(timeZone).format(momentPrettyDateTimeFormatter)} ${
    timeZone && moment().tz(timeZone).format('z')
  }`;
};

export const getPrettyFormattedDate = (date: MomentInput): string => {
  if (!date) return null;
  return moment(date).format(momentFormatter);
};

export const getPrettyFormattedUtcDate = (date: MomentInput, timeZone?: string): string => {
  if (!date) return null;
  const dateMoment = moment.utc(date);
  if (timeZone) dateMoment.tz(timeZone);
  return dateMoment.format(momentFormatter);
};

export const getPrettyFormattedTime = (date: MomentInput): string => {
  if (!date) return null;
  return moment(date).format(momentTimeFormatter);
};

export const getPrettyFormattedUtcTime = (date: MomentInput, timeZone?: string): string => {
  if (!date) return null;
  const dateMoment = moment.utc(date);
  if (timeZone) dateMoment.tz(timeZone);
  return dateMoment.format(momentTimeFormatter);
};

export const getISOFormattedDate = (date: MomentInput): string => {
  if (!date) return null;
  return moment(date).format(momentISODateFormatter);
};

export const getISOFormattedDateTimeFromCleave = (date: MomentInput): string => {
  if (!date) return null;
  return moment(date, cleaveFormatter).toISOString();
};

export const getISOFormattedDateFromCleave = (date: MomentInput): string => {
  if (!date) return null;
  const result = moment(date, cleaveFormatter);
  return result.isValid() ? result.format(momentISODateFormatter) : null;
};

export const getDateFromCleave = (date: MomentInput): Date => {
  if (!date) return null;
  const result = moment.utc(date, cleaveFormatter);
  return result.isValid() ? result.toDate() : null;
};

export const getCleaveDateFromISODate = (date: MomentInput): string => {
  if (!date || (typeof date === 'string' && date.length < 10)) return null;
  const result = moment(date, momentISODateFormatter);
  return result.isValid() ? result.format(cleaveFormatter) : null;
};

export const getISODateTimeToFormattedDate = (date: MomentInput): string => {
  if (!date || (typeof date === 'string' && date.length < 10)) return null;
  const result = moment(date, momentISODateFormatter);
  return result.isValid() ? result.format(momentFormatter) : null;
};

export const convertUTCtoLocalDate = (utcDate: MomentInput): string => {
  if (!utcDate) return null;

  // Local dev is already in local time so no need for the conversion
  if (appConfig?.appEnv?.includes('local') && typeof utcDate === 'string') {
    return utcDate;
  }

  const utcMoment = moment(utcDate).utc();

  return moment(utcMoment).local().toDate().toISOString();
};

export type GetDateTimeArgs = {
  date: MomentInput;
  time?: string;
  isEnd?: boolean;
  timeZone?: string;
  isUTC?: boolean;
};

export const getDateTimeMoment = (args: GetDateTimeArgs): moment.Moment => {
  const { date, time, timeZone, isEnd, isUTC } = args;
  const dateTimeInput = time ? `${getISOFormattedDate(date)} ${time}` : date;
  const createMoment = isUTC ? moment.utc : moment;
  const result = createMoment(dateTimeInput, momentDateTimeInputFormat);
  if (result.isValid()) {
    if (isEnd) result.endOf('minute');
    if (timeZone) result.tz(timeZone, !isUTC);
    return result;
  }
  return null;
};

export const getDateTime = (args: GetDateTimeArgs): Date => {
  const dateTimeMoment = getDateTimeMoment(args);
  return dateTimeMoment && dateTimeMoment.toDate();
};

type GetJobsiteDateTimeArgs = Omit<GetDateTimeArgs, 'timeZone'> & { jobsite?: Pick<GetDateTimeArgs, 'timeZone'> };

export const getJobsiteDateTime = (args: GetJobsiteDateTimeArgs): Date => {
  const { jobsite, ...restArgs } = args;
  const { timeZone = 'America/New_York' } = jobsite ?? {};
  return getDateTime({ ...restArgs, timeZone });
};

export const getJobsiteDateTimeNow = (
  jobsite: Pick<GetDateTimeArgs, 'timeZone'>,
): { date: string; time: string; dateTime: Date } => {
  const jobsiteMomentNow = moment.utc(new Date()).tz(jobsite.timeZone);
  return {
    date: jobsiteMomentNow.format(momentISODateFormatter),
    time: jobsiteMomentNow.format(momentTimeFormatter),
    dateTime: jobsiteMomentNow.toDate(),
  };
};

export const getTime = (args: Pick<GetDateTimeArgs, 'date' | 'timeZone' | 'isUTC'>): string => {
  const { date, timeZone, isUTC } = args;
  const createMoment = isUTC ? moment.utc : moment;
  const dateTimeMoment = date && createMoment(date);
  if (timeZone) dateTimeMoment?.tz(timeZone, !isUTC);
  const result = dateTimeMoment?.format(momentTimeFormatter) ?? '';
  return result;
};

export const isAfterToday = (date: MomentInput): boolean => {
  if (!date) return false;
  return moment(date).isAfter(moment().endOf('day'));
};
